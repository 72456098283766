//https://www.youtube.com/watch?v=ZgPUE68tg-U

import React from 'react';

class VideoTutorial extends React.Component {

    renderVideo = () => {
        const { videoId } = this.props; // Destructure videoId from props
    
        const styles = {
            videoContainer: {
                display: 'flex', // Add this
                justifyContent: 'center', // Add this
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                paddingTop: '178.29%', /* Aspect Ratio for your vertical video */
            },
            iframe: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                maxHeight: 'calc(100vh - 127px)',
            },
        };
    
        return (
            <div className='w-100' style={{ marginTop: '8px' }}>
    
                <div style={styles.videoContainer}>
                    <iframe
                        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0&fs=0&iv_load_policy=3&showinfo=0&controls=1`}
                        style={styles.iframe}
                        //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        //allowFullScreen
                        title="Embedded tutorial"
                    ></iframe>
                </div>
            </div>
        );
    }
    

    render() {
        return (
            <div className="w-100">
                <h2 className="m-0 p-0">Demonstração Keymaster</h2>
                {this.renderVideo()}
            </div>
        );
    }
}
export default VideoTutorial;