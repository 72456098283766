import React, { Fragment } from "react";
import { GENERAL } from "../../assets/images/images";
import LocalData from "../../core/localData";
import Labels from "../../variables/labels";
// eslint-disable-next-line no-unused-vars
import LinkButton from "../button/LinkButton/LinkButton";

/**
 * CompanyCardList Component
 *
 * @component
 * @prop {boolean} addActivated - Indicates if the add button is activated.
 * @prop {string} searchTerm - The term used for filtering the companies.
 * @prop {Array} companies - List of company data to display.
 * @prop {boolean} scheduleMode - Indicates if the component is in schedule mode.
 */
class CompanyCardList extends React.Component {
  constructor(props) {
    super(props);
    const { addActivated, searchTerm, companies, scheduleMode } = this.props;
    this.state = {
      listData: companies,
      searchTerm,
      addActivated,
      scheduleMode,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchTerm !== this.props.searchTerm || prevProps.companies !== this.props.companies) {
      const listData = this.filterList(prevProps.addActivated, this.props.searchTerm);
      this.setState({ listData });
    }
  }

  filterList(addActivated, term) {
    if (!addActivated) {
      return LocalData.companies;
    }

    return term
      ? LocalData.newCompanies.filter((c) => c.name.toLowerCase().includes(term.toLowerCase()))
      : LocalData.newCompanies;
  }

  handleSelectCompany = (/** @type {Company} */ c) => {
    const { scheduleMode } = this.state;
    if (!scheduleMode) {
      window.open(c.url);
    } else {
      this.props.onSelectedCompany(c);
    }
  };

  
  renderCard = (/** @type {Company} */ c, i) => {
    const { addActivated, scheduleMode } = this.state;
    const renderImg = () => {
      if (!c || !c.logoUrl) {
        return (<div className="company-name-logo">{c?.name}</div>);
      }
      return <img src={c.logoUrl} alt="company_logo" />;
    };
    const renderNotiIcon = () => {
      const count = LocalData.companiesCount?.warnings_by_company[c.domain];
      if (!count || scheduleMode) {
        return;
      }
  
      return (
        <div className="tab-notification-container counter-alert">
          <div className="tab-notification counter-alert" />
        </div>
      );
    };

    if (!addActivated) {
      return c.logged && !scheduleMode ? null : (
        <div
          key={i}
          className="company-card-container d-flex flex-column justify-content-center align-items-center"
          role={"button"}
          onClick={() => this.handleSelectCompany(c)}
          // href={c.url}
          // target={"_self"}
        >
          {renderNotiIcon()}
          <div className="card-company-logo-container flex-center"> {renderImg()} </div>
          <div className="card-company-name"> {c.name} </div>
        </div>
      );
    }

    return (
      <div
        key={i}
        className="company-card-container add-company d-flex flex-column justify-content-center align-items-center"
        role={"button"}
        onClick={() => this.handleSelectCompany(c)}
      >
        <div className="card-company-logo-container flex-center"> {renderImg()} </div>
        <div className="card-company-name"> {c.name} </div>
        <LinkButton
          valueText={Labels.sidebarCompanies.addCompanyLinkText}
          icon={GENERAL.plusBlue}
        />
      </div>
    );
  };

  render() {
    const { listData } = this.state;
    return (
      <Fragment>
        {listData.map((c, i) => this.renderCard(c, i))}
      </Fragment>
    );
  }
}
export default CompanyCardList;
