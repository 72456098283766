import { range } from "lodash"; 
import { getYear } from "date-fns";
export class DatePickerData {
  static datePickerYears = (type) => {
    const futureYears = type === 'birth' ? 1 : 100;
    const previousYears = type === 'schedule' ? 0 : 100;
    return range(getYear(new Date()) - previousYears, getYear(new Date()) + futureYears, 1)
  };
  static datePickerMonths = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
}
