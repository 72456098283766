import React, { useEffect, useState, Fragment } from "react";
import DatePicker from "react-datepicker";
import customLocale from "../../../../components/datePicker/customLocale";
import '../../ScheduleDatePicker.scss';
import { DatePickerCustomHeader, DatePickerCustomHeaderMobile } from "../datePicker/datePickerHeader";
import useIsMobile from "../../core/helpers/UseIsMobile";
import SelectComponent from "../selectComponent/SelectComponent";
import ScheduleService from "../../core/services/schedule.service";
import { HelperSchedule } from "../../core/helpers/helperSchedule";
import { format, addMonths, subMonths, setMonth, setYear } from "date-fns";
import { pt } from "date-fns/locale";
import Loader from '../../../../components/loader/loader';

const cLabels = {
  description: "Selecione a data e hora desejadas",
  observations: "Deixe-nos os seus comentários/observações:",
  selectTimePreference: "Preferência de horário",
  timeSlot: "Slots disponíveis para a data e horário selecionado:",
  noTimeSlots: "Não existe disponibilidade para a data e preferência de horário escolhidos. Por favor selecione um novo horário.",
  selectSpecialist: "Escolha um especialista:",
  placeholderSpecialist: "- Qualquer especialista -"
};

/**
 * @param {Object} props
 * @param {number} step
 * @param {{id: string, name: string, active: boolean}} props.services
 * @param {ScheduleData} props.scheduleData
 * @param {SchedulerConfig} props.schedulerConfig
 * @param {Function} props.onSelectedDate
 * @param {Function} props.onPreferedTime
 * @param {Function} props.onSelectedTime
 * @param {Function} props.onChangeDisplayedDate
 * @param {Function} props.onSelectedServices
 * @param {Function} props.onSelectedExpert
 */
const Step2Schedule = (props) => {
  const [stepLabel] = useState(`Passo ${props.step}: `);
  //const [schedulerConfig, setSchedulerConfig] = useState(props.schedulerConfig);
  const [selectedDate, setSelectedDate] = useState(props.scheduleData?.selectedDate);
  const [selectedTimePreference, setSelectedTimePreference] = useState(props.scheduleData?.preferedTime);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(props.scheduleData?.selectedTime);
  const [timeSlots, setTimeSlots] = useState(props.scheduleData?.timeSlots);
  const [excludedDates, setExcludedDates] = useState(undefined);
  const preferedTimeOptions = props.preferedTimeOptions;
  const [isLoading, setIsLoading] = useState(false);
  const [daysLoaded, setDaysLoaded] = useState(false);
  const [displayedDate, setDisplayedDate] = useState(props.scheduleData?.displayedDate);
  const [services, setServices] = useState(props.scheduleData?.selectedServices);
  const [expertSelected, setExpertSelected] = useState(props.scheduleData?.expertSelected);

  useEffect(() => {
    setExpertSelected(props.scheduleData?.expertSelected);
    props.onChangeDisplayedDate(displayedDate);
    if (selectedDate) getTimeSlots(selectedDate, selectedTimePreference.value, props.scheduleData?.expertSelected?.id); // fetch slots after expert change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scheduleData.expertSelected]);

  useEffect(() => {
      setServices(props.scheduleData.selectedServices);
  }, [props.scheduleData.selectedServices]);

  useEffect(() => {
    if (selectedDate) handleChangeDate(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.scheduleData.selectedDate !== selectedDate) {
      setSelectedDate(props.scheduleData.selectedDate);
    }
    if (selectedDate && selectedDate.getMonth() !== displayedDate.getMonth()){
      props.onChangeDisplayedDate(selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scheduleData.selectedDate, selectedDate]);

  useEffect(() => {
    if (props.scheduleData.preferedTime !== selectedTimePreference) {
      setSelectedTimePreference(props.scheduleData.preferedTime);
    }
  }, [props.scheduleData.preferedTime, selectedTimePreference]);

  useEffect(() => {
    if (props.scheduleData.displayedDate !== displayedDate) {
      setDisplayedDate(props.scheduleData.displayedDate);
    }
  }, [props.scheduleData.displayedDate, displayedDate]);

  useEffect(() => {
    if (props.scheduleData.selectedTime !== selectedTimeSlot) {
      setSelectedTimeSlot(props.scheduleData.selectedTime);
    }
  }, [props.scheduleData.selectedTime, selectedTimeSlot]);

  useEffect(() => {
    if (props.scheduleData.timeSlots !== timeSlots) {
      setTimeSlots(props.scheduleData.timeSlots);
    }
  }, [props.scheduleData.timeSlots, timeSlots]);

  useEffect(() => {
    //setSchedulerConfig(props.schedulerConfig);
    if (props.schedulerConfig?.blockers) {
      const dates = props.schedulerConfig?.blockers?.map(b => HelperSchedule.convertDate(b));
      setExcludedDates(dates);
      setDaysLoaded(true);
    }
  }, [props.schedulerConfig]);

  const handleChangeDateWrapper = (value) => {
    handleChangeDate(value);
  }

  const handleChangeDate = (value, timePreference = selectedTimePreference) => {

    if (isLoading) return;
    props.onSelectedDate(value, timePreference);
    
    getTimeSlots(value, timePreference.value, expertSelected?.id);
  };

  const handleTimePreferenceChanged = (choose) => {
    props.onPreferedTime(choose);
    if (selectedDate) handleChangeDate(selectedDate, choose);
  };

  const getTimeSlots = async (day, preferedTime, expertSelectedId) => {
    const symptoms = props.scheduleData?.selectedServices?.map(s => s?.id);
    let date;
    if (day) {
      date = day.toLocaleDateString('pt-PT');
    } else {
      date = selectedDate.toLocaleDateString('pt-PT');
    }    
    setIsLoading(true);
    try {
      const slotTimeList = await ScheduleService.fetchSchedulerSlots(
        symptoms,
        date,
        preferedTime,
        expertSelectedId, 
      );
      //setTimeSlots(slotTimeList);
      props.onSelectedTime(undefined, slotTimeList);
    } catch (error) {
      // Handle any errors
      console.error("Failed to fetch data:", error);
    }
    setIsLoading(false);

  }

  const handleSelectedSlot = (value) => {
    props.onSelectedTime(value, timeSlots);
  };

  const renderTimePreferenceSelect = () => {
    const options = preferedTimeOptions;
    return (
      <React.Fragment>
        <SelectComponent
          selectedOption={selectedTimePreference}
          onChange={handleTimePreferenceChanged}
          options={options}
          config={{
            isMulti: false,
            isClearable: true,
            autoFocus: false,
            isSearchable: false
          }}
          label={cLabels.selectTimePreference}
          placeholder={"Selecione o seu horário preferencial..."} />
      </React.Fragment>
    );
  }

  const renderExpert = () => {
    if (!services || !services.length ||
      !services.some(s => s.active && !s.disabled && s.users && s.users.length > 0)) {
      return <Fragment />;
    }
    const buildExpertList = (services) => {
      const expertMap = new Map();
      services.forEach(service => {
        if (service.active && !service.disabled) {
          service.users.forEach(user => {
            if (!expertMap.has(user.id)) {
              expertMap.set(user.id, { value: user.id, label: user.name });
            }
          });
        }
      });
      const experts = Array.from(expertMap.values());
      experts.unshift({ value: 'clear_selection', label: cLabels.placeholderSpecialist }); // Add at the start of the list
      return experts;
    };

    const expertsAvailable = buildExpertList(services);

    const handleChange = (selectedOption) => {
      if (selectedOption && selectedOption.value !== 'clear_selection') {
        // Set expertSelected to the selected option's corresponding user object
        props.onSelectedExpert({
          id: selectedOption.value,
          name: selectedOption.label
        });
 /*       setExpertSelected({
          id: selectedOption.value,
          name: selectedOption.label
       });
      */     } else {
        // Clear the selection when the clear option is chosen
        props.onSelectedExpert(null);
 //       setExpertSelected(null);
      }

    };

    return (
      <React.Fragment>
        <div className="mt-3">
          <SelectComponent
            selectedOption={expertSelected ? { value: expertSelected.id, label: expertSelected.name } : null}
            onChange={handleChange}
            options={expertsAvailable}
            config={{
              isMulti: false,
              isClearable: true,
              autoFocus: false,
              isSearchable: true
            }}
            label={cLabels.selectSpecialist}
            placeholder={cLabels.placeholderSpecialist} />
        </div>
      </React.Fragment>
    );
  }

  const renderTimeSlots = () => {

    const formatDateForTimeslotsLabel = (date) => {
      return format(new Date(date), "dd 'de' MMMM", { locale: pt });//.toLowerCase();
    }
    const expertName = expertSelected?.name || 'Qualquer especialista';
    const hasExpertServices = services && services.length > 0 &&
                                services.some(s => s.active && !s.disabled && s.users && s.users.length > 0);

    if (!selectedTimePreference || !selectedDate) {
      return (<React.Fragment />);
    }

    let combinedSlots = [];
    if (selectedTimePreference.value === 3) {
      combinedSlots = [...(timeSlots?.morning || []), ...(timeSlots?.afternoon || [])];
    } else if (selectedTimePreference.value === 2) {
      combinedSlots = [...(timeSlots?.afternoon || [])];
    } else {
      combinedSlots = [...(timeSlots?.morning || [])];
    }

    if (combinedSlots.length === 0 && !isLoading) {
      return (
        <>
          {hasExpertServices ? (
            <div className="d-flex justify-content-center mt-5" >
              <p className="normal-light-black mx-auto"> Não existe disponibilidade horária para <b>{expertName}</b> a <strong>{formatDateForTimeslotsLabel(props.scheduleData.selectedDate)}</strong> na preferência de horário "<b>{selectedTimePreference?.label}</b>". Por favor selecione um novo horário.</p>
            </div >
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <p className="normal-light-black mx-auto"> Não existe disponibilidade horária a <strong>{formatDateForTimeslotsLabel(props.scheduleData.selectedDate)}</strong> na preferência de horário "<b>{selectedTimePreference?.label}</b>". Por favor selecione um novo horário.</p>
            </div>
          )}
        </>
      );
    }


    return (
      <div className="schedule-time-slots-container mt-4">

        {hasExpertServices ? (
          <div className="input-label">
            Slots disponíveis para <b>{expertName}</b> a <b>{formatDateForTimeslotsLabel(props.scheduleData.selectedDate)}</b> na preferência de horário "<b>{selectedTimePreference?.label}</b>":
          </div>
        ) : (
          <div className="input-label">
            Slots disponíveis a <b>{formatDateForTimeslotsLabel(props.scheduleData.selectedDate)}</b> na preferência de horário "<b>{selectedTimePreference?.label}</b>":
          </div>
        )}

        {renderLoader()}

        <div className="slots-grid-container">
          {combinedSlots.map((l, i) => (
            <div
              key={i}
              role="button"
              className={`slot-card align-center ${l.value === selectedTimeSlot?.value ? "active" : ""}`}
              onClick={() => handleSelectedSlot(l)}
            >
              {l.value}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader message='' inverted={false} local={true} big={false}></Loader>
    }
    return;
  }

  const handleDPickerRangeChange = (navigationFunc, type, param = undefined) => {
    if (type === 'month') {
      props.onChangeDisplayedDate(setMonth(displayedDate,param));
    }
    if (type === 'year') {
      props.onChangeDisplayedDate(setYear(displayedDate,param));
    }
    if (type === 'decrease') {
      props.onChangeDisplayedDate(subMonths(displayedDate, 1));
    }
    if (type === 'increase') {
      props.onChangeDisplayedDate(addMonths(displayedDate, 1));
    }
    //Default datepicker behaviour (change the displayed date):
    (typeof param !== 'undefined') ? navigationFunc(param) : navigationFunc();
  }
  
  const CustomHeaderComponent = useIsMobile() ? DatePickerCustomHeaderMobile : DatePickerCustomHeader;
  return (
    <div className="step-action-container mt-4">
      <div className="step-description-container">
        <div className="step-label me-1"> {stepLabel} </div>
        <div className="step-description"> {cLabels.description} </div>
      </div>
      <div className="step-action-field-content mt-3">
        {renderTimePreferenceSelect()}
      </div>
      <div className="step-action-field-content mt-3">
        {renderExpert()}
      </div>
      <div className="step-action-content mt-3 mx-auto" id="step-2-action-content" style={{ width: 'auto' }}>
        <div className="date-picker-container mt-3">
          <DatePicker
            renderCustomHeader={(props) => (
              <CustomHeaderComponent
                {...props}
                customChangeMonth={(func, param) => {
                  handleDPickerRangeChange(func, 'month', param);
                }}
                customChangeYear={(func, param) => {
                  handleDPickerRangeChange(func, 'year', param);
                }}
                customDecreaseMonthHandler={(func) => {
                  handleDPickerRangeChange(func, 'decrease');
                }}
                customIncreaseMonthHandler={(func) => {
                  handleDPickerRangeChange(func, 'increase');
                }}
              />
            )}
            selected={selectedDate}
            onChange={handleChangeDateWrapper}
            dateFormat="dd/MM/yyyy"
            locale={customLocale}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 1).toUpperCase()}
            inline
            monthsShown={2}
            minDate={new Date()}
            excludeDates={excludedDates}
            filterDate={daysLoaded ? () => true : () => false}
          />
        </div>
      </div>

      <div className="step-action-field-content mt-3">
        {renderTimeSlots()}
      </div>
    </div>
  );
};

export default Step2Schedule;
