import React from "react";
import CardItemInformation from "./CardItemInformation";
import Button from "components/button/button.jsx";
import InputField from "components/inputField/inputField.jsx";
import InputDatePicker from "components/datePicker/datePicker";
import Labels from "../../../variables/labels";
import { GENERAL, SERVICE } from "../../../assets/images/images";
import ConfirmationCTA from "../../button/ConfirmationCTA";
import Helper from "../../../core/helper/helper";
import dateFormat from "date-format";
import SelectList from "../../SelectComponent/selectList/select-list";

class EquipmentDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    const { data, specs, specsEdit } = this.props;
    this.refInputFile = React.createRef();
    this.refDatePicker = React.createRef();

    this.refEditSpecs = [];
    this.state = {
      item: data,
      specs,
      specsEdit,
      isEditing: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState({
        item: this.props.data,
        specs: this.props.specs,
        specsEdit: this.props.specsEdit,
      });
    }
  };

  toggleEdit = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  saveEditChanges = () => {
    const newData = this.refEditSpecs
      .map((ref) => {
        if (!ref.state.value && !ref.state.selectedOption) {
          return null;
        }
        return {
          key: ref.props.id,
          label: ref.props.name,
          value:
            ref.props.type === "birthday" || ref.props.type === "date"
              ? dateFormat("dd/MM/yyyy", ref.state.value)
              : ref.state.value || ref.state.selectedOption?.value,
        };
      })
      .filter((s) => s);
    this.props.saveEditChanges(newData);
    this.toggleEdit();
  };

  renderDeleteItem = (isEditing) => {
    if (!isEditing) {
      return <></>;
    }
    return (
      <div
        className="link-container"
        role="button"
        onClick={this.props.deleteItem}
      >
        <img src={GENERAL.deleteIcon} alt="icon-link" className="me-1" />
        <span> Eliminar {Helper.itemLabel()} </span>
      </div>
    );
  };

  renderCTAContainer = (isEditing) => {
    if (!isEditing) {
      return (
        <React.Fragment>
          <div className="edit-button-container w-100 flex-end">
            <div
              className="edit-button flex-center"
              role={"button"}
              onClick={this.toggleEdit}
            >
              <img src={GENERAL.editBlue} alt="" />
            </div>
          </div>
          <Button
            className="cta-button-primary d-medium mt-4 px-5 w-100 w-md-auto"
            type="button"
            value={Labels.resetItemSpecs(Helper.itemLabel())}
            onClick={() => this.props.resetSpecs()}
          />
        </React.Fragment>
      );
    }
    return (
      <ConfirmationCTA
        cancelText={Labels.exitEdit}
        confirmText={Labels.confirmChanges}
        confirmationHandle={this.saveEditChanges}
        declineHandle={this.toggleEdit}
      />
    );
  };

  renderCardInformation = () => {
    const {
      item: { title, subtitle },
    } = this.state;
    return (
      <CardItemInformation
        data={{ title, subtitle }}
        styleClass="mt-3 mt-md-0 text-start details"
      />
    );
  };

  handleCameraClick = () => {
    // if (window.Android) {
    //   window.Android.uploadMedia(this.state.item?.id);
    //   return;
    // }
    this.refInputFile.current.click();
  };

  renderItemImage = (item) => {
    return (
      <div className="card-service-image-container details">
        <img
          src={item.photo}
          alt="service_image"
          className={Helper.isLiteVersion() ? "pixelized" : ""}
          onError={(e) => (e.target.src = Helper.defaultImage())}
        />
        <div className="add-image-button-container">
          <div className="custom-icon-container" onClick={() => this.handleCameraClick()}>
            <label htmlFor="upload-photo">
              <img src={SERVICE.maquina_foto_std} className="icon-button" alt="" />
            </label>
          </div>
          <input
            ref={this.refInputFile}
            type="file"
            accept="image/gif, image/jpeg, image/png, image/jpg"
            onChange={this.props.updateImage}
          />
        </div>
      </div>
    );
  };

  renderSpecsContainer = (isEditing) => {
    const { specs, specsEdit } = this.state;

    if (!specs?.length) {
      return <></>;
    }

    const renderInput = (prop, index) => {
      if (prop.type === "birthday" || prop.type === "date") {
        return (
          <div className="w-100">
            <InputDatePicker
              key={prop.key}
              type={prop.type}
              id={prop.key}
              name={prop.label}
              ref={(r) => (this.refEditSpecs[index] = r)}
              value={prop.value}
            ></InputDatePicker>
          </div>
        );
      } else if (prop.type === 'inputselect') {
        return (
          <SelectList
            ref={(r) => (this.refEditSpecs[index] = r)}
            key={prop.key}
            id={prop.key}
            name={prop.label}
            size={'small'}
            selectedOption={prop.value}
            isClearable={true}
            onChange={() => {}}
            options={prop.options}
          />
        );
      } else {
        return (
          <InputField
            key={prop.key}
            ref={(r) => (this.refEditSpecs[index] = r)}
            name={prop.label}
            id={prop.key}
            styleInput="input-small-default m-0"
            styleClass="m-0"
            type="text"
            title=""
            customValue={prop.value}
          />
        );
      }
    };

    if (isEditing) {
      return specsEdit.map((prop, index) => {
        return (
          <div
            key={index}
            className="spec-row flex-between w-100 px-2 px-md-3 py-2"
          >
            <div className="spec-text col flex-start ps-0">{prop.label}</div>
            <div className="spec-text col flex-end pe-0 ps-1 border-0">
              {renderInput(prop, index)}
            </div>
          </div>
        );
      });
    }
    return specs.map((prop, key) => {
      const { label, value } = prop;
      return (
        <div
          key={key}
          className="spec-row flex-between w-100 px-2 px-md-3 py-2"
        >
          <div className="spec-text col flex-start ps-0">{label}</div>
          <div className="spec-text col flex-end pe-0 ps-1">
            {prop.type === "birthday"
              ? Helper.buildAgeInfo(value, prop.type)
              : value}
          </div>
        </div>
      );
    });
  };

  render() {
    const { item, feedback, isEditing } = this.state;
    const styleClass = feedback ? "feedback" : "";
    if (!item) {
      return <></>;
    }
    return (
      <React.Fragment>
        <div
          className={`card-service-main-container p-3 w-100 m-0 h-auto mw-100 ${styleClass}`}
        >
          <div className="row">
            <div className="col-12 col-sm-6">{this.renderItemImage(item)}</div>
            <div className="col-12 col-sm-6">
              {this.renderCardInformation()}
            </div>
          </div>
          <div className="mt-3">{this.renderSpecsContainer(isEditing)}</div>
          <div className="mt-3 pb-2">{this.renderCTAContainer(isEditing)}</div>
        </div>
        <div className="mt-3 w-100 flex-center justify-content-md-start">
          {this.renderDeleteItem(isEditing)}
        </div>
      </React.Fragment>
    );
  }
}
export default EquipmentDetailsCard;
