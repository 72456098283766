import { pt } from 'date-fns/locale';

// Customize the week day names here
const customWeekdaysMin = [
    'S', // Monday
    'T', // Tuesday
    'Q', // Wednesday
    'Q', // Thursday
    'S', // Friday
    'S', // Saturday
    'D', // Sunday
];

const customLocale = {
  ...pt, // Start with the default English locale
  weekdaysMin: customWeekdaysMin,
  firstDayOfWeek: 1, // 1 represents Monday
};

export default customLocale;