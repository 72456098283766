import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SelectIndicator from './SelectIndicator';
import SelectClearIndicator from './SelectClearIndicator';
import { SelectComponentStyling } from './SelectComponentStyling';

/**
 * @param {Object} props
 * @param {Option[]} props.options - The list of available options.
 * @param {Option} props.selectedOption - The currently selected option.
 * @param {Object} [props.config] - Configuration options.
 * @param {boolean} [props.config.isMulti] - Whether multiple options can be selected.
 * @param {boolean} [props.config.isClearable] - Whether the select field is clearable.
 * @param {boolean} [props.config.autoFocus] - Whether the select field is autofocus.
 * @param {boolean} [props.config.isDisabled] - Whether the select field is disabled.
 * @param {string} [props.placeholder] - The placeholder text.
 * @param {string} [props.label] - The label text.
 * @param {(options: Option[]) => void} props.onChange - Callback function when options change.
 */
const SelectComponent = React.forwardRef((props, ref) => {
  const [selectLabel] = useState(props.label);
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);
  const [options, setOptions] = useState(props.options?.filter(opt => !opt?.isDisabled));
  const [isLoading, setIsLoading] = useState(props.options ? false : true);
  const [classSelectLabel] = useState(props.classSelectLabel);
  const [classSelectContainer] = useState(props.classSelectContainer);

  useEffect(() => {
    setOptions(props.options?.filter(opt => !opt?.isDisabled));
    setIsLoading(props.options ? false : true);
  }, [props.options]);

  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedOption(selectedOption);
      props.onChange(selectedOption);
    } else {
      setSelectedOption(undefined);
      props.onChange(undefined);
    }
  };

  const renderLabel = () => {
    if (!selectLabel) {
      return <></>;
    }
    return <p className={`input-label ${classSelectLabel ? classSelectLabel : 'regular'}`}>{selectLabel}</p>;
  }

  return (
    <div className={`${classSelectContainer ? classSelectContainer : 'select-list-container w-100'}`}>
      {renderLabel()}
      <div className="select-list-row-container">
        <div className={`select-list`}>
          <Select
            ref={ref}
            value={selectedOption}
            onChange={handleChange}
            components={{ DropdownIndicator: SelectIndicator, ClearIndicator: SelectClearIndicator }}
            placeholder={props.placeholder || "Selecione..."}
            options={options}
            isLoading={isLoading}
            isDisabled={isLoading}
            menuIsOpen={props.menuIsOpen}
            styles={SelectComponentStyling}
            {...props.config}
            menuPosition="fixed"
          />
        </div>
      </div>
    </div>
  );
});

export default SelectComponent;
