import React from 'react';
import Labels from '../../../variables/labels';
import Button from 'components/button/button.jsx';
import { GENERAL } from '../../../assets/images/images';
import Helper from '../../../core/helper/helper';

class CallToAppSample extends React.Component {
  renderCTAContainer = (text) => {
    return (
      <Button className="cta-button-primary d-medium w-100 w-md-auto px-4"  type="button"
        value={text}
        onClick={() => (window.open(Helper.playstoreLink, 'mozillaTab'))} />
    );
  }

  renderImage = () => {
    return (
      <div className='w-100' style={{margin: '24px -24px -24px -8px'}}>
        <img className='w-100 d-none d-sm-block' src={GENERAL.callAppStoreBig} alt=""/>
        <img className='w-100 d-sm-none' src={GENERAL.callAppStore} alt=""/>
      </div>
    );
  }
  render() {
    return (
    <div className="w-100">
      <h2 className="m-0 p-0">{Labels.AppStoreModal.title}</h2>
      <p className="light mt-4">{Labels.AppStoreModal.message}</p>
      {this.renderCTAContainer(Labels.AppStoreModal.buttonLabel)}
      {this.renderImage()}
    </div>
  );
  }
}
export default CallToAppSample;