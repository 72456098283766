import React, { useState, useEffect } from "react";
import ScheduleServiceDetailsCard from "../scheduleServiceDetailsCard/ScheduleServiceDetailsCard";
import ScheduleServiceResumeCard from "../scheduleServiceDetailsCard/ScheduleServiceResumeCard";
//import ScheduleServiceClientData from "../scheduleServiceDetailsCard/ScheduleServiceClientData";

const cLabels = {
  description: "Verificação e confirmação de dados",
  observations: "Deixe-nos os seus comentários/observações:",
};

/**
 * @param {Object} props
 * @param {number} props.step
 * @param {string} props.location
 * @param {scheduleData} props.scheduleData
 * @param {Function} props.onNewObservations
 * @param {Function} props.onSelectedExpert
 */
const Step3Schedule = (props) => {
  const [location] = useState(props.location);
  const [stepLabel] = useState(`Passo ${props.step}: `);
  const [scheduleData, setScheduleData] = useState(props.scheduleData);
  const [expertSelected, setExpertSelected] = useState(props.scheduleData?.expertSelected);
  const [expertsBySlot, setExpertsBySlot] = useState(props.scheduleData?.expertsBySlot);

  useEffect(() => {
    setExpertSelected(props.scheduleData?.expertSelected);
  }, [props.scheduleData.expertSelected]);

  useEffect(() => {
    setExpertsBySlot(props.scheduleData?.expertsBySlot);
  }, [props.scheduleData.expertsBySlot]);

  useEffect(() => {
    setScheduleData(props.scheduleData);
  }, [props.scheduleData]);

  return (
    <React.Fragment>
      <div className="step-action-container mt-4">
        <div className="step-description-container">
          <div className="step-label me-1"> {stepLabel} </div>
          <div className="step-description"> {cLabels.description} </div>
        </div>
        <div className="step-action-content mt-2 mx-auto" id="step-3-action-content">
          <div className="step-summary-container d-flex flex-column">
            <ScheduleServiceDetailsCard scheduleData={scheduleData} location={location} />
            <ScheduleServiceResumeCard scheduleData={scheduleData} 
                                        onNewObservations={props.onNewObservations}
                                        expertSelected={expertSelected}
                                        onSelectedExpert={props.onSelectedExpert}
                                        expertsBySlot={expertsBySlot}
                                        />
            {/*<ScheduleServiceClientData clientDataUpdated={(newData) => {props.clientDataUpdated(newData)}}/>*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Step3Schedule;
