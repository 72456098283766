import React, { Component } from 'react';

import { GENERAL } from '../../assets/images/images.js';
import Helper from '../../core/helper/helper';

const cLabels = {
    passwordPatternError:'A password deve ter pelo menos 8 caracteres e ser uma combinação de letras em maiúsculas, letras minúsculas, números e símbolos.',
    emailPatternError:'Por favor insira um formato de endereço de e-mail correto. Ex: utilizador@mail.com',  
  }

  class PasswordField extends Component {
    constructor(props) {
      super(props);
      this.state = {
        password: props.password,
        inputType: 'password',
        isInactive: true,
      };
      this.passwordInputRef = React.createRef();
      this.handleChangePassword = this.handleChangePassword.bind(this);
      this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
      this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    }

  componentDidUpdate(prevProps) {
    if (this.props.validateNow && this.props.validateNow !== prevProps.validateNow) {
      this.handlePasswordValidation();
    }
  }

  renderShowPassword() {
    return (
      <div
        className="inside-button-container password"
        onClick={this.togglePasswordVisibility}
      >
        <img
          className={`icon-container ${this.state.isInactive ? "icon-inactive" : ""}`}
          src={this.state.inputType === "password" ? GENERAL.eyeButton : GENERAL.eyeHide}
          alt="Toggle password visibility"
        />
      </div>
    );
  }

  handlePasswordValidation() {
    const isValid = Helper.testPasswordRegex(this.state.password);

    if (this.passwordInputRef.current) {
      if (isValid) {
        this.passwordInputRef.current.setCustomValidity('');
        if (this.props.onValidationComplete) {
          this.props.onValidationComplete(true);
        }
      } else {
        this.passwordInputRef.current.setCustomValidity(cLabels.passwordPatternError);
        if (this.props.onValidationComplete) {
          this.props.onValidationComplete(false);
        }
      }
      this.passwordInputRef.current.reportValidity();
    }
  }

  handleChangePassword(e) {
    e.stopPropagation();
    if (this.passwordInputRef.current) this.passwordInputRef.current.setCustomValidity('');
    const value = e.target.value;
    this.setState({ password: value });
    this.props.onChange(value);
  }

  togglePasswordVisibility() {
    this.setState(prevState => ({
      inputType: prevState.inputType === 'password' ? 'text' : 'password',
    }));
  }

  render() {
    return (
      <div className={`input-container position-relative mt-2 ${this.props.styleClass || ""}`}>
        <p className="input-label">{this.props.label || 'Password'}</p>
        <input
          ref={this.passwordInputRef}
          className="input-medium-default password"
          type={this.state.inputType}
          title={this.props.label || 'Password'}
          value={this.state.password}
          onChange={this.handleChangePassword}
          required={true}
          autoComplete={this.props.autoComplete || ''}
        />
        {this.renderShowPassword()}
      </div>
    );
  }
}

export default PasswordField;
