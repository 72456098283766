// React Imports
import React from 'react';
import { withRouter } from 'react-router';
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
// Components
import Grid from 'components/list/grid/grid.jsx';
import CardService from 'components/card/service/serviceCard.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Confirmation, Information, ChangeData } from 'components/modal/modal.jsx';
// Variables
import { SIDEBAR, GENERAL } from 'assets/images/images.js';
// API Imports
import {
  getItemInfo, getItemList, getInProgressServices, getCompletedServices, getScheduleServices,
  removeItemFromUser, changeItemImage,
  alterItemDetails as changeItemDetails, resetItemDetails
} from '../../api/routes/api.route.js';
import Labels from '../../variables/labels';
import Helper from '../../core/helper/helper';
import { Helmet } from 'react-helmet';
import LocalData from '../../core/localData';
import EquipmentDetailsCard from '../../components/card/equipment/EquipmentDetailsCard';

class EquipmentDetails extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.timer = null;
    this.state = {
      data: {},
      specs: [],
      specsEdit: [],
      scheduleWorks: [],
      progressWorks: [],
      completeWorks: [],
      scheduleWorksState: false,
      completedWorksState: false,
      progressWorksState: false,
      showSpecs: true,
    }
    this.baseState = {...this.state};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
//    ReactGA.pageview(window.location.pathname + window.location.search);
    if (this.timer) { clearTimeout(this.timer); }
    this.getItemDetails();
    this.refreshPage();

    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.saveLastUrl.postMessage( {url: window.location.href} );
      } catch (error) {
        console.error(error);
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.saveLastUrl(window.location.href);
      } catch (error) {
        console.error(error);
      }
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
      this.getItemDetails();
      
      if (!this.state.showSpecs) { this.setState({ showSpecs: true}); }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  
  checkInternetConnection = () => {
    // Return a promise that resolves to true or false based on internet connectivity
    const url = `https://www.google.com/favicon.ico?${new Date().getTime()}`;
    return fetch(url, { mode: 'no-cors', method: 'HEAD' })
      .then(() => true)  // Internet is working
      .catch(() => false);  // No internet connection detected
  }

  refreshPage = () => {
    this.timer = setTimeout(() => {
      this.resetState();
      this.getItemDetails();
      this.refreshPage(); // Reschedule after processing the current check's result
    }, 300000);
  }

  resetState = () => {
    this.setState(this.baseState);
  }

  /**
  * Pedido para toda a informaçao da equipamento incluindo os serviços
  */
  getItemDetails() {
    getInProgressServices(this.cookies.get('sessionToken'), this.props.match.params.id).then(value => {
      if (value && value.works) {      
        this.setState({
          progressWorks: value.works,
          progressWorksState: value.works.length
         })
      }
    });
    
    getScheduleServices(this.cookies.get('sessionToken')).then(value => {
      if (value && value.works) {
        const filteredValue = {
          works: value.works.filter(work => work.item && String(work.item.id) === this.props.match.params.id)
      };
        this.setState({
          scheduleWorks: filteredValue.works,
          scheduleWorksState: filteredValue.works.length
        })
      }
    });
    
    getCompletedServices(this.cookies.get('sessionToken'), this.props.match.params.id).then(value => {
      if (value && value.works) {
        this.setState({
          completeWorks: value.works,
          completedWorksState: value.works.length
        });
      }
    });

    getItemInfo(this.props.match.params.id, this.cookies.get('sessionToken')).then(value => {
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.setState({
            data: {
              ...value,
              photo: Helper.urlItemImage(value.photo),
              id: this.props.match.params.id,
            },
            specs: Helper.mergeItemFieldsConfig(value.read_specs),
            specsEdit: Helper.mergeItemFieldsConfig(value.edit_specs),
          });
        }
      } else {
        this.handleGenericRequestError();
      }
    });

    getItemList(this.cookies.get('sessionToken')).then(value => {
      if (value && !value.code) {
        LocalData.equipmentsData = value.items;
      }
    })
  }

  /**
  * Apresentar os details do serviço selecionado
  */
  handlePressEvent = (id, info) => {
    //sessionStorage.setItem('current_service', JSON.stringify(info));
    this.props.history.push(`/service-details/${id}?s=I&t=0`);
  }

  /**
  * Confirmação para apagar item
  */
  removeItemPressEvent = () => {
    this.refModal.current.renderView(
      <Confirmation
        title='Atenção'
        text={Labels.confirmRemoveItem}
        confirmationHandle={() => this.removerItem()}
        declineHandle={() => this.refModal.current.closeModal()}
      />);
    this.refModal.current.openModal();
  }

  /**
  * Confirmação para repor dados da item
  */
  resetDetailsPressEvent = () => {
    this.refModal.current.renderView(
      <Confirmation
        title='Atenção'
        text={Labels.resetItemDetailsText}
        confirmationHandle={() => this.resetDetails()}
        declineHandle={() => this.refModal.current.closeModal()}
      />);
    this.refModal.current.openModal();
  }

  /**
  * Lida com a resposta a um request quando existe value code
  */
  handleValueCodeResponse = (value) => {
    if (value.code === 401) {
      return;
    } else if (value.code === 404) {
      this.refModal.current.renderView(
        <Information
          title={`${Labels.server.item_label} Indisponivel`}
          text={value.message}
          onClick={() => this.refModal.current.closeModal()} />);
      this.refModal.current.openModal();
    } else if (value.code === 409) {
      this.refModal.current.renderView(
        <Information
          title='Acesso Não Autorizado'
          text={value.message}
          onClick={() => this.refModal.current.closeModal()} />);
      this.refModal.current.openModal();
    } else if (value.code === 406) {
      this.refModal.current.renderView(
        <Information
          title='Formato Inválido'
          text={value.message}
          onClick={() => this.refModal.current.closeModal()} />);
      this.refModal.current.openModal();
    } else {
      this.refModal.current.renderView(
        <Information
          title='Pedido Sem Sucesso'
          text={Labels.genericErrorMessage}
          onClick={() => this.refModal.current.closeModal()} />);
      this.refModal.current.openModal();
    }
  }

  /**
  * Lida com um problema genérico a uma chamada de endpoint
  */
  handleGenericRequestError = () => {
    this.refModal.current.renderView(
      <Information
        title='Pedido Sem Sucesso'
        text={Labels.genericErrorMessage}
        onClick={() => this.refModal.current.closeModal()} />);
    this.refModal.current.openModal();
  }

  /**
  * Pedido para remover uma equipment
  */
  removerItem = () => {
    this.refModal.current.closeModal();
    removeItemFromUser(this.props.match.params.id, this.cookies.get('sessionToken')).then(value => {
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.refModal.current.renderView(
            <Information
              title='Concluído'
              text={value.message}
              onClick={() => {
                this.refModal.current.closeModal()
                this.props.history.push(`/services`);
              }} />);
          this.refModal.current.openModal();
        }
      } else {
        this.handleGenericRequestError();
      }
    });
  }

  /**
  * Pedido para repor details da equipment
  */
  resetDetails = () => {
    resetItemDetails(this.props.match.params.id, this.cookies.get('sessionToken')).then(value => {
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.refModal.current.renderView(
            <Information
              title='Concluído'
              text={value.message ? value.message : 'Detalhes originais repostos com sucesso.'}
              onClick={() => {
                this.getItemDetails();
                this.refModal.current.closeModal()
              }} />);
          this.refModal.current.openModal();
        }
      } else {
        this.handleGenericRequestError();
      }
    });
  }


  /**
  * Apresentação modal para editar informação da equipment
  */
  renderDetailModal = () => {
    this.refModal.current.renderView(
      <ChangeData
        title={`Editar Detalhes de ${Labels.server.item_label}`}
        data={this.state.specsEdit}
        onClick={(data) => {
          this.changeItemData(data);
          this.refModal.current.closeModal();
        }
        }
      />
    );
    this.refModal.current.openModal();
  }

  /**
    * Pedido para alterar informação da equipment
    * 
    * @param {any} DATA - objecto com a nova informação da equipment
    */
  changeItemData = (data) => {
    const NEW_DATA = { specs: data }
    changeItemDetails(this.cookies.get('sessionToken'), this.props.match.params.id, NEW_DATA).then(value => {
      if (value) {
        if (value.code) {
          if (value.code === 401) {
            return;
          } else if (value.code === 404) {
            this.refModal.current.renderView(
              <Information
                title='Item Indisponivel'
                text={value.message}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          } else if (value.code === 409) {
            this.refModal.current.renderView(
              <Information
                title='Acesso Não Autorizado'
                text={value.message}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          } else if (value.code === 400) {
            this.refModal.current.renderView(
              <Information
                title='Formato Inválido'
                text={value.message}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          } else {
            this.refModal.current.renderView(
              <Information
                title='Pedido Sem Sucesso'
                text={Labels.genericErrorMessage}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          }
        } else {
          this.getItemDetails();
          this.refModal.current.renderView(
            <Information
              title='Dados Alterados'
              text={value.message}
              onClick={() => {
                this.refModal.current.closeModal();
              }} />);
          this.refModal.current.openModal();
        }
      } else {
        this.refModal.current.renderView(
          <Information
            title='Pedido Sem Sucesso'
            text={Labels.genericErrorMessage}
            onClick={() => this.refModal.current.closeModal()} />);
        this.refModal.current.openModal();
      }
    });
  }

  /**
  * Pedido para alterar a foto da equipment
  * 
  * @param {any} file - ficheiro a fazer upload
  */
  handleUpdateImage = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.changeItemImage(file)
    };
    reader.readAsDataURL(file);
  }

  changeItemImage(file) {
    // const body = JSON.parse(file);
    let formData = new FormData();
    formData.append('image', file, file.name);
    changeItemImage(this.cookies.get('sessionToken'), this.props.match.params.id, formData).then(value => {
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.refModal.current.renderView(
            <Information
              title='Concluído'
              text={value.message ? value.message : Labels.updateItemImage(Helper.itemLabel())}
              onClick={() => {
                this.refModal.current.closeModal()
                this.setState({
                  data: {
                    ...this.state.data,
                    photo: Helper.urlItemImage(value.url)
                  }
                });
              }} />);
          this.refModal.current.openModal();
        }
      } else {
        this.handleGenericRequestError();
      }
    });
  }


  renderItemTitles = () => {
    const { data: { title, subtitle } } = this.state;
    return (
      <div className='cover-info-container-detail'>
        <div className='cover-Info-container-text1 '>{ Helper.printValue(title) }</div>
        <div className='converInfoContainerText2'>{ Helper.printValue(subtitle) }</div>
      </div>
    );
  }

  toggleSpecs = () => {
    this.setState({
      showSpecs: !this.state.showSpecs,
    });
  }


  renderEditSpecsBtn = () => {
    if (this.state.showSpecs) {
      return (
        <div className="detail-info-container" onClick={() => this.renderDetailModal()}>
          <img src={SIDEBAR.editProfileWhite} className="icon-button" alt="" />
        </div>
      );
    }
  }

  renderSubmitBtns = () => {
    const { scheduleWorks, progressWorks, showSpecs } = this.state;
    const showDelete = showSpecs && !progressWorks.length && !scheduleWorks.length;
    return (
      <div className="submit-btns-container" style={{paddingBottom:'20px'}}>
        <Button className={this.state.showSpecs ? 'reset-details-button' : 'hidden'} type='button'
          value='Repor detalhes do Item' onClick={() => this.resetDetailsPressEvent()} />
        <Button className={showDelete ? 'submitDeclineContainer' : 'hidden'} type='button' value='Remover Item'
          onClick={() => this.removeItemPressEvent()} />
      </div>
    );
  }

  renderInfoAreaContainer = () => {
    return (
      <div className='information-area-container'>
        <div className='information-area-header' onClick={() => this.toggleSpecs()}>
          {Labels.equipmentDetailsTitle(Helper.itemLabel())}
          <img className="open-grid-button small"
            style={{ transform: this.state.showSpecs ? 'rotate(0deg)' : 'rotate(180deg)' }}
            src={GENERAL.arrowDown} alt="" />
        </div>
        {this.renderSpecsContent()}
        {this.renderEditSpecsBtn()}
      </div>
    );
  }

  renderServiceDetailsCard = () => {
    const { data, specs, specsEdit } = this.state;
    return (
      <EquipmentDetailsCard
        data={data}
        specs={specs}
        specsEdit={specsEdit}
        resetSpecs={this.resetDetailsPressEvent}
        updateImage={this.handleUpdateImage}
        deleteItem={this.removeItemPressEvent}
        saveEditChanges={this.changeItemData}
      />
    );
  }

  renderCardList= (works, worksType) => {
    if (Helper.listIsEmpty(works)) {
      switch (worksType){
        case 'progress':
          return (<div className="empty-services-container">{Labels.noServices.active}</div>);
        case 'schedule':
          return (<div className="empty-services-container">{Labels.noServices.scheduled}</div>);
        case 'completed':
          return (<div className="empty-services-container">{Labels.noServices.completed}</div>);
        default:
        }
    }
    return works.map((prop, index) => {
      return (
        <CardService key={index} data={prop}
          isCompact={true}
          isAppointment={(worksType==="schedule")}
          onClick={() => {worksType==="schedule"? this.handlePressEvent("S-"+prop.id, prop): this.handlePressEvent(prop.id, prop)}}
        />
      );
    });
  }

  renderServicesList = () => {
    const { progressWorks, scheduleWorks, completeWorks,
      scheduleWorksState, completedWorksState, progressWorksState } = this.state;
    return (
      <React.Fragment>
        <Grid title="Obras em Curso" status={progressWorksState} type="progress" styleClass="mt-5 mt-lg-0">
          {this.renderCardList(progressWorks, "progress")}
        </Grid>
        <Grid title="Agendamentos" status={scheduleWorksState} type="schedule">
          {this.renderCardList(scheduleWorks, "schedule")}
        </Grid> 
        <Grid title="Histórico" status={completedWorksState} type="completed">
          {this.renderCardList(completeWorks, "completed")}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const { data } = this.state;
    if (Helper.objectHasData(data)) {
      return (
        <div className="main-details-container px-3 px-md-4 pt-3">
          <div className="center-container-left">
            {this.renderServiceDetailsCard()}
          </div>
          <div className="center-container-right">
            {this.renderServicesList()}
          </div>
          <Modal ref={this.refModal} />
          <Helmet> <title> { Helper.titleItemDetailsPage() } </title> </Helmet>
        </div>
      );
    }
    return (
      <div className="main-details-container">
        <Modal ref={this.refModal} />
        <Helmet> <title> { Helper.titleItemDetailsPage() } </title> </Helmet>
      </div>
    );
  }
}

export default withRouter(EquipmentDetails)
