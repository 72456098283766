import { PICTO_CATEGORIES } from '../images/images';

const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const CATEGORIES = [
  { id: 1, icon: PICTO_CATEGORIES.revisao_ipo },
  { id: 2, icon: PICTO_CATEGORIES.moto_embraiagem },
  { id: 3, icon: PICTO_CATEGORIES.chassis_Rodas },
  { id: 4, icon: PICTO_CATEGORIES.carrocaria },
  { id: 5, icon: PICTO_CATEGORIES.quadrante },
  { id: 6, icon: PICTO_CATEGORIES.habitaculo },
  { id: 7, icon: PICTO_CATEGORIES.pintura },
  { id: 8, icon: PICTO_CATEGORIES.outros },
];

const GARAGEM = [
  {
    id: 1, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'BMW', matricula: '12-TX-34', service: true,
  },
  {
    id: 2, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Peugeot', matricula: '18-10-TZ', service: false,
  },
  {
    id: 3, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Mercedes-Benz', matricula: '23-AC-11', service: true,
  },
  {
    id: 4, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'BMW', matricula: '12-TX-34', service: true,
  },
  {
    id: 5, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Peugeot', matricula: '18-10-TZ', service: false,
  },
  {
    id: 6, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Mercedes-Benz', matricula: '23-AC-11', service: true,
  },
  {
    id: 7, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'BMW', matricula: '12-TX-34', service: true,
  },
  {
    id: 8, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Peugeot', matricula: '18-10-TZ', service: false,
  },
  {
    id: 9, photoURL: 'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg', marca: 'Mercedes-Benz', matricula: '23-AC-11', service: true,
  },
];

const SERVICE_DETALHE = {
  total: '360,90€',
  details: [
    {
      id: '1', status: true, detail: 'Revisão LL Premium Plus', price: '190,90€',
    },
    {
      id: '2', status: true, detail: 'Óleo', price: '56,34€',
    },
    {
      id: '3', status: true, detail: 'Filtro Óleo', price: '13,75€',
    },
    {
      id: '4', status: false, detail: 'Filtro Ar', price: '27,44€',
    },
    {
      id: '5', status: false, detail: 'Filtro Combustível', price: '13,67€',
    },
    {
      id: '6', status: false, detail: 'Pastilhas Frente ', price: '120,27€',
    },
    {
      id: '7', status: false, detail: 'Subs. Pastilhas Travão Frente', price: '41,80€',
    },
  ],
  photos: [
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
    'https://www.wallpapersrc.com/img/b8729af5bb0a4142aefdb81b880d3087/bmw-concept-8-series-1920x1080.jpg',
  ],

};
const SERVICE_LOCATION = [
  {
    store: 'CASCAIS', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '0.53',
  },
  {
    store: 'ALGÉS', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '8.40',
  },
  {
    store: 'PENHA DE FRANÇA', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '11.60',
  },
  {
    store: 'SANTARÉM', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '14.57',
  },
  {
    store: 'LOURES', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '18.22',
  },
  {
    store: 'SANTARÉM', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '14.57',
  },
  {
    store: 'LOURES', adress1: 'Rua Dr. Marques Mendes N10', adress2: '1234-567 - Cascais', distance: '18.22',
  },
];

const VIATURA_DETALHES = [
  { label: 'Chassi', text: 'VF1CB080F26817380' },
  { label: 'Tipo Veículo', text: 'Renault Clio II (BB0/1/2_,CB0/1/2_) 1.5 dCi (B/CB08)' },
  { label: 'Ano Construção', text: '2002' },
  { label: 'Primeiro Registo:', text: '20020704' },
  { label: 'Cilindrada', text: '1461' },
  { label: 'Potência', text: '60kW / 82CV' },
  { label: 'Combustivel', text: 'Diesel' },
  { label: 'Códigos motor', text: 'K9K 702<' },
  { label: 'Cilindros', text: '(Sem Dados)' },
];

const SYMPTOMS = [
  { id: 'btnRevIpo', label: 'Revisão e IPO', symptoms: ['symptom 1', 'symptom 2', 'symptom 3', 'symptom 4', 'symptom 5'] },
  { id: 'btMotoEmb', label: 'Motor e Embraiagem', symptoms: ['symptom 1', 'symptom 2', 'symptom 3', 'symptom 4'] },
  { id: 'btnChaRod', label: 'Chassis e Rodas', symptoms: ['symptom 1', 'symptom 2'] },
  { id: 'btnCarr', label: 'Carroçaria', symptoms: ['symptom 1', 'symptom 2', 'symptom 3'] },
  { id: 'btnQuad', label: 'Quadrante', symptoms: ['symptom 1', 'symptom 2', 'symptom 3', 'symptom 4', 'symptom 5'] },
  { id: 'btnHab', label: 'Habitáculo', symptoms: ['symptom 1', 'symptom 2', 'symptom 3', 'symptom 4'] },
];

const DAY_HOUR = [
  {
    id: '', day: '23', mes: 'Janeiro', hours: ['9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '12:00', '12:30', '12:00', '12:30'],
  },
  {
    id: '', day: '24', mes: 'Janeiro', hours: ['9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
  },
];


export {
  CATEGORIES, GARAGEM, VIATURA_DETALHES, SYMPTOMS, SERVICE_DETALHE, SERVICE_LOCATION, DAY_HOUR, MONTHS,
};
