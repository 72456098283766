// React Imports
import React from 'react';
import ReactPlayer from 'react-player';
import { GENERAL } from '../../../assets/images/images';
import Helper from '../../../core/helper/helper';

class MediaSample extends React.Component {
  constructor(props) {
    super(props);
    const { title, text, src, item: { type }, data, items, index, hasDeleteIcon } = this.props;
    this.state = {
      title: title,
      text: text,
      src: src,
      isVideo: Helper.isVideo(type),
      selectedIndex: index,
      nbrOfItems: data ? data.length : 0,
      nextArrow: index + 1 < items.length,
      prevArrow: index > 0,
      playVideo: false,
      hasDeleteIcon: hasDeleteIcon
    };
  }

  componentDidMount() {
    console.clear();
  }

  // isReady = () => {
  // }
  // onError = (error) => {
  // }
  // onBufferEnd = () => {
  // }

  onPlayClicked = () => {
    this.setState({ playVideo: !this.state.playVideo}); 
  }

  renderMedia = () => {
    const { isVideo, src, playVideo } = this.state;
    
    if (isVideo) {
      // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      return (
          <ReactPlayer
            onClick={() => this.onPlayClicked()}
            onTouchStart = {() => this.onPlayClicked()}
            controls={true}
            url={src}
            width='100%'
            height='95vh'
            playing={playVideo}
            // onReady={this.isReady}
            // onError={(e) => this.onError(e)}
            // onBufferEnd={this.onBufferEnd}
            // config={{
            //   file: {
            //     // forceHLS: isSafari,
            //     forceVideo: true, 
            //     // hlsVersion: '1.11.0',
            //   }
            // }}
            />
      );
    } else {
      return <img onClick={() => this.onPlayClicked()} className="modal-media-photo-container" src={src} alt="" />
    }
  };

  onClickNext = () => {
    const { selectedIndex } = this.state;
    const { items } = this.props;
    
    if (selectedIndex + 1 < items.length) {
      const newIndex = selectedIndex + 1;
      const nextItem = items[newIndex];
      const url = Helper.urlFullWorkImage(nextItem.url);
      this.setState({
        src: url, isVideo: Helper.isVideo(nextItem.type),
        selectedIndex: newIndex,
        nextArrow: newIndex + 1 < items.length,
        prevArrow: newIndex > 0
      });
    }
  }

  onClickPrev = () => {
    const { selectedIndex } = this.state;
    const { items } = this.props;

    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      const prevItem = items[newIndex];
      const url = Helper.urlFullWorkImage(prevItem.url);
      this.setState({
        src: url, isVideo: Helper.isVideo(prevItem.type),
        selectedIndex: newIndex,
        nextArrow: newIndex + 1 < items.length,
        prevArrow: newIndex > 0
      });
    }
  }

  deleteItem = () => {
    const { selectedIndex } = this.state;
    const { items } = this.props;
    const mediaId = items[selectedIndex]?.id;
    this.props.onDeleteMediaFile(mediaId);
  }

  renderRemoveIcon = () => {
    const { hasDeleteIcon } = this.state;
    if (!hasDeleteIcon) {
      return;
    }

    return (
      <div onClick={this.deleteItem}>
        <img src={GENERAL.delete} className="icon-media-remove" alt="" />
      </div>
    );
  }

  render() {
    const { nextArrow, prevArrow, playVideo } = this.state;

    return (
      <React.Fragment>
      {this.renderRemoveIcon()}
      <div className="modal-area-container">
        <div className='arrow-section'>
          <i className={ prevArrow ? 'chevron left huge icon' : 'disabled chevron left huge icon'}
            onClick={() => this.onClickPrev()}></i>
          <i className={ nextArrow ? 'chevron right huge icon' : 'disabled chevron right huge icon'}
            onClick={() => this.onClickNext()}></i>
        </div>
        { this.renderMedia(playVideo) }
      </div>
      </React.Fragment>
    );
  }
}

export default MediaSample;
