// React Imports
import React, { Component } from "react";
import Emitter from "../../core/services";
import "./notifications.style.scss";
import LocalData from "../../core/localData";
import { GENERAL } from "../../assets/images/images";

class NotificationsButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: LocalData.notificationsData?.notificationsList || [],
		};
	}

	componentDidMount() {
		Emitter.on("NOTIFICATIONS_UPDATE", this.updateNotifications);
		this.updateNotifications();
	}

	componentWillUnmount() {
		Emitter.off("NOTIFICATIONS_UPDATE", this.updateNotifications);
	}

	updateNotifications = () => {
		this.setState({
			notifications: LocalData.notificationsData?.notificationsList || [],
		});
	};

	render() {
		const { notifications } = this.state;

		const unreadCount = notifications
			? notifications.filter((n) => !n.read).length
			: 0;

		return (
			<div
				className="notifications-button"
				onClick={() => Emitter.emit("NOTIFICATIONS_SIDEBAR_OPEN")}
			>
				<div className="oval" />

				<img src={GENERAL.notification} alt="Notification" />
				{unreadCount > 0 && (
					<div className="notifications-alert">
						<div className="number">
							{unreadCount > 99 ? "+99" : unreadCount}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default NotificationsButton;
