import React from "react";
import { components } from "react-select";
import { GENERAL } from "../../../../assets/images/images";

/**
 * @param {Object} props - The props for the ClearIndicator component.
 * @param {React.FC<ClearIndicatorProps>} props.children - The children components.
 */
const SelectClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={GENERAL.closeIcon} alt='icon-close'></img>
    </components.ClearIndicator>
  );
};

export default SelectClearIndicator;
