class BVService {
  static swiperLoaded = false;
  static swiperSlide = null;

  static setSwiperSlide = (slide) => {
    this.swiperSlide = slide;
  };

  static scheduleScrollPos = 0;
  static progressScrollPos = 0;
  static completedScrollPos = 0;

  static getScrollPos = (slide) => {
    switch (slide) {
      case 0:
        return this.progressScrollPos;
      case 1:
        return this.scheduleScrollPos;
      case 2:
        return this.completedScrollPos;

      default:
        return this.progressScrollPos;
    }
  };

  static getInitialSlide = (progress, schedule, completed) => {
    return this.swiperSlide || this.swiperSlide === 0
      ? this.swiperSlide
      : progress?.length
      ? 0
      : schedule?.length
      ? 1
      : completed?.length
      ? 2
      : 0;
  };

  static getInitialLiteSlide = (progress, schedule, completed) => {
    return this.swiperSlide || this.swiperSlide === 0
      ? this.swiperSlide
      : progress
      ? 0
      : schedule
      ? 1
      : completed
      ? 2
      : 0;
  };

  static setScrollPosition = (pos) => {
    switch (this.swiperSlide) {
      case 0:
        this.progressScrollPos = pos;
        break;
      case 1:
        this.scheduleScrollPos = pos;
        break;
      case 2:
        this.completedScrollPos = pos;
        break;

      default:
        break;
    }
  };

  static setSwiperClasses = (pos) => {
    const container = document.getElementById("swiper-services-container");
    const tabsContainer = document.getElementById("swiper-tabs-services");

    if (container) {
      const swiperContainer = container.getElementsByClassName("swiper-container")[0];
      const swiperScrollbar = container.getElementsByClassName("swiper-scrollbar")[0];
      
      if (pos >= 60) {
        if (tabsContainer && swiperContainer && swiperScrollbar) {
          tabsContainer.classList.add("fixed");
          swiperContainer.classList.add("fixed");
          swiperScrollbar.classList.add("fixed");
        }
      } else {
        if (tabsContainer && swiperContainer && swiperScrollbar) {
          tabsContainer.classList.remove("fixed");
          swiperContainer.classList.remove("fixed");
          swiperScrollbar.classList.remove("fixed");
        }
      }
    }
  };
}

export default BVService;