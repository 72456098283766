// React Imports
import Button from 'components/button/button.jsx';
import React from 'react';
//import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { GENERAL } from '../../assets/images/images';
import Labels from '../../variables/labels';

class SendEmailPassword extends React.Component {
//  constructor(props) {
//    super(props);
//    ReactGA.pageview(window.location.pathname + window.location.search);
//  }

  render() {
    return (
      <div className='grey-box-container mt-5'>
        <h3 className='text-center w-100 mt-3'> {Labels.sentEmail.header}</h3>
        <div className="message-container mt-4"> {Labels.sentEmail.message}</div>
        <div className="check-icon-container mt-5 mb-2">
          <img src={GENERAL.checkGreen} alt=''/>
        </div>
        <div className='box-form-container mt-6'>
          <Button to={`/`} className='link-container mt-4' type='linkNav' value={Labels.sentEmail.linkMessage}/>
        </div>
      </div>
    );
  }
}
export default withRouter(SendEmailPassword)