import React from 'react';
import { withRouter  } from 'react-router-dom';
import { DELETEACCOUNT } from "../../assets/images/images.js";


class HelpDeleteAccount extends React.Component  {
  constructor(props) {
    super(props)
    this.imageStyle = {
      maxWidth: '100%',
      height: 'auto',
      border: '2px solid #ddd',
      boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px'
    };
  }

  render() {

    return(
    <React.Fragment>

      <div className="main-container w-100 h-100">
        <div className="help-container">
          <h2>Como apagar a sua conta cliente associada a um prestador de serviços:</h2>

          <h3 className="w-100 mt-4 help-step-container mb-3 mt-4">1) Inicie sessão.</h3>
          <div className="step-image-container mt-2">
            <img
              src={DELETEACCOUNT.help_step_1}
              alt="help-step-1"
              style={this.imageStyle}
            />
          </div>

          <h3 className="w-100 mt-4 help-step-container mb-3 mt-4">2) Abra o menu dados de cliente e escolha a opção "Apagar a minha Conta"</h3>
          <div className="step-image-container mt-2">
            <img
              src={DELETEACCOUNT.help_step_2}
              alt="help-step-2"
              style={this.imageStyle}
            />
          </div>

          <h3 className="w-100 mt-4 help-step-container mb-3 mt-4">3) Confirme a ação de apagar conta</h3>
          <div className="step-image-container mt-2">
            <img
              src={DELETEACCOUNT.help_step_3}
              alt="help-step-3"
              style={this.imageStyle}
            />
          </div>

          <h3 className="w-100 mt-4 help-step-container mb-3 mt-4">4) A sua conta será removida</h3>
          <div className="step-image-container mt-2">

          </div>

        </div>
      </div>

    </React.Fragment>
    )
  };
};

export default withRouter(HelpDeleteAccount);
