import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { CookiesLabels } from '../../variables/cookies.labels';
import CookieDetails from './CookiesDetails.jsx';
import './ModalCookieBanner.scss';
import { GENERAL } from '../../assets/images/images';
import Helper from '../../core/helper/helper';

const ModalCookieBanner = ({ updateAnalyticCookies }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
    const [analyticsCookies, setAnalyticsCookies] = useState(true);
    const cookies = new Cookies();

    const adjustDetailsContainerHeight = useCallback(() => {
        if (!detailsModalIsOpen) {
            return;
        }
        const contentElement = document.querySelector('#detailsModalResume');
        const detailsContainer = document.querySelector('#detailsModalDetails');
        if (!contentElement || !detailsContainer) {
            return;
        }
        const contentHeight = contentElement.offsetHeight;
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - contentHeight - 72;
        detailsContainer.style.setProperty('height', `${calculatedHeight}px`, 'important');
    }, [detailsModalIsOpen]);

    useEffect(() => {
        const acceptedCookies = cookies.get('acceptedNecessaryCookies');
        if (!acceptedCookies) {
            setModalIsOpen(true);
        }
    }, [cookies]);

    // Adjust height on resize and detailsModalIsOpen changes
    useEffect(() => {
        if (detailsModalIsOpen) {
            setTimeout(() => adjustDetailsContainerHeight(), 50);

            const handleAdjustHeight = () => {
                adjustDetailsContainerHeight();
            };
            window.addEventListener('resize', handleAdjustHeight);
            return () => {
                window.removeEventListener('resize', handleAdjustHeight);
            };
        }
    }, [detailsModalIsOpen, adjustDetailsContainerHeight]);

    const handleAcceptCookies = () => {
        cookies.set('acceptedNecessaryCookies', 'true', Helper.cookiesConfig);
        if (analyticsCookies) {
            cookies.set('acceptedAnalyticsCookies', 'true', Helper.cookiesConfig);
            updateAnalyticCookies(true);
        }
        setModalIsOpen(false);
    };

    const handleLearnMore = () => {
        setDetailsModalIsOpen(true);
    };

    const removeTabIndex = (contentElement) => {
        if (contentElement) {
            contentElement.removeAttribute('tabindex');
        }
    };

    const handleAnalyticsSwitch = () => {
        setAnalyticsCookies(!analyticsCookies);
    };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleAcceptCookies}
                shouldCloseOnOverlayClick={false}
                className="modalBanner-modal"
                overlayClassName="modalBanner-overlay"
                contentRef={removeTabIndex}
            >
                {detailsModalIsOpen && (
                    <>
                        <div id="detailsModalDetails" className="modalBanner-detailsContainer mt-3">
                            <div className="modalBanner-closeButton" onClick={() => setDetailsModalIsOpen(false)}>
                                <img src={GENERAL.iconClose} alt="icon-modal-close" />
                            </div>
                            <div className="mb-0">
                                <CookieDetails />
                            </div>
                        </div>
                        <div id="detailsModalResume" className="modalBanner-content topShadow">
                            <div className="modalBanner-switches mt-1">
                                <div className="switch-option">
                                    <label className="switch-label p-2">Cookies Analíticos</label>
                                    <div className="switch-container" onClick={handleAnalyticsSwitch}>
                                        <div className={`switch ${analyticsCookies ? 'on' : 'off'}`} />
                                    </div>
                                </div>
                                <div className="switch-option">
                                    <label className="switch-label disabled p-2">Cookies de Funcionamento</label>
                                    <div className="switch-container disabled">
                                        <div className="switch on disabled" />
                                    </div>
                                </div>
                            </div>
                            <div className="modalBanner-buttons">
                                <button className="cta-button-primary w-75 mt-3" type="button" onClick={handleAcceptCookies}>
                                    Gravar Seleção
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {!detailsModalIsOpen && (
                    <div className="modalBanner-content">
                        <p className="mb-1 px-5">
                            {Helper.getCookiesMessage()}
                        </p>
                        <div className="link-container mb-3" role="button" onClick={handleLearnMore}>
                            {CookiesLabels.learnMoreLink}
                        </div>
                        <div className="modalBanner-buttons">
                            <button className="cta-button-primary w-100" type="button" onClick={handleAcceptCookies}>
                                OK
                            </button>
                            <button className="cta-button-secondary w-100" type="button" onClick={handleLearnMore}>
                                Mais opções
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ModalCookieBanner;
