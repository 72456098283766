import React, { Fragment } from "react";
import LinkButton from "../../../button/LinkButton/LinkButton";
import { GENERAL } from "../../../../assets/images/images";

/**
 * @param {Object} props - The component props.
 * @param {Company | undefined} props.company - The company data.
 */
const CompanyPhoneInfo = (props) => {
  const { company, isDark } = props;

  if (!company?.phone) {
    return <Fragment />;
  }

  return (
    <div className="company-info d-inline-flex">
      <div className="info-icon me-2">
        <img
          src={GENERAL.iconPhone}
          alt="phone-icon"
          className={isDark ? 'filter-white' : ''}
        />
      </div>
      <div className="info-text">
        <LinkButton
          onClick={(e) => e?.stopPropagation()}
          styleClass="link-container"
          textClass="small"
          isPhone={true}
          valueLink={company?.phone}
          valueText={company?.phone}
        />
      </div>
    </div>
  );
};

export default CompanyPhoneInfo;
