import React, { Component } from 'react';
import Labels from '../../../variables/labels';
import CompanyService from '../../../core/services/company.service';
import Helper from '../../../core/helper/helper';

class ModalIAgreeSample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: props.modalIsOpen,
      title: props.title || Labels.termsLabels.privacy.generalTitle,
      termsTitle: Labels.termsLabels.privacy.termsTitle,
      message: props.message || Labels.termsLabels.privacy.message1,
      subMessage: props.subMessage,
      confirmText: props.confirmText,
      showClose: props.showClose ?? true,
      replaceCompanyName: props.replaceCompanyName || Helper.companyDomain(),
      replaceVat: props.replaceVat,
      replaceClient: props.replaceClient,
      replaceItem: props.replaceItem,
      replaceWork: props.replaceWork,
      replaceCompanies: props.replaceCompanies,
      companyTerms: props.companyTerms
    };
  }

  async componentDidMount() {
    try {
      const companiesData = await CompanyService.fetchNewCompanies();//"";//await CompanyService.fetchCompanies();
      const companiesString = companiesData
        .map(company => company.name ? company.name : company.domain)
        .join(", ");
      this.setState({ replaceCompanies: companiesString });
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }

    this.setState({
      message: this.replaceVariables(this.props.message || Labels.termsLabels.privacy.message1,
        this.props.replaceCompanyName ?? "",
        this.props.replaceVat ?? "",
        this.props.replaceClient ?? "",
        this.props.replaceItem ?? "",
        this.props.replaceWork ?? "",
        this.state.replaceCompanies ?? "")
    });
  }

  renderCTAContainer = () => {
    return (
      <div className="cta-button-primary d-medium mt-1 mb-3 d-flex justify-content-center align-content-center flex-wrap" role="button" onClick={this.props.onClose}>
        Continuar
      </div>
    );
  }

  replaceVariables = (
    message,
    replaceCompanyName,
    replaceVat,
    replaceClient,
    replaceItem,
    replaceWork,
    replaceCompanies
  ) => {
    if (replaceVat === "") {
      message = message.replace(
        " (NIPC *vat*)",
        ""
      );
    }
    return message
      .replace(/\*companyname\*/g, replaceCompanyName)
      .replace(/\*vat\*/g, replaceVat)
      .replace(/\*client\*/g, replaceClient)
      .replace(/\*item\*/g, replaceItem)
      .replace(/\*work\*/g, replaceWork + '.')
      .replace(/\*companies\*/g, replaceCompanies);
  };

  renderTextWithBold = (text) => {
    const segments = text.split(/(\*\*.*?\*\*)/).filter(Boolean);
    return segments.map((segment, index) => {
      if (/^\*\*(.*?)\*\*$/.test(segment)) {
        return <strong key={index}>{segment.slice(2, -2)}</strong>;
      } else {
        return <span key={index}>{segment}</span>;
      }
    });
  };

  render() {
    const { title, termsTitle, companyTerms, message, subMessage } = this.state;
    return (
      <>
        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 80px)' }}

        >
          <div style={{
            //content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingRight: '0px',
            padding: '0px',
            top: '0px',
            position: 'relative',
            maxHeight: 'calc(100vh-100px)'
            //   },
          }}
          >
            <div className="modal-content-container d-flex flex-column align-center" style={{ position: 'relative' }}>
              <h2 className="m-0 p-0 text-center mb-3 mt-5">{title}</h2>
              <div className="w-100 d-column-flex flex-column align-center">
                <p className="light mt-1 mx-6 mx-sm-6" style={{ textAlign: 'justify' }}>
                  {message?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {this.renderTextWithBold(line)}
                      {index !== message.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
                <p className="light text-center mt-1 mx-6 mx-sm-6">
                  {subMessage?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {this.renderTextWithBold(line)}
                      {index !== subMessage.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>
              {companyTerms && <>
                <h2 className="m-0 p-0 text-center mb-3">{termsTitle}</h2>
                <p className="light mt-1 mx-6 mx-sm-6 mb-7" style={{ textAlign: 'justify' }}>
                  {companyTerms}
                </p>
              </>}
            </div>
          </div>
          {this.renderCTAContainer()}
        </div>
      </>
    );
  }
}

export default ModalIAgreeSample;
