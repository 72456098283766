// React Imports
import React from 'react';
// Components
import Button from 'components/button/button.jsx';
import Loader from '../../loader/loader';

class MultiOptionsSample extends React.Component {
  /**
  * @typedef {Object} MultiOptionsProps
  * @property {string} title - The title for the confirmation.
  * @property {string} text - The main text for the confirmation.
  * @property {string} subText - Additional subtext for the confirmation.
  * 
  * @property {string} [option1Text] - Text for the action button. (Optional)
  * @property {string} [option2Text] - Text for the action button. (Optional)
  * @property {string} [option3Text] - Text for the action button. (Optional)
  * @property {string} [option4Text] - Text for the action button. (Optional)
  * @property {string} [cancelText='Cancelar'] - Text for the cancel action button. (Optional)
  * @property {string} [icon] - Icon to display in the confirmation. (Optional)
  */

  /**
   * @param {MultiOptionsProps} props - Props for the MultiOptions component.
   */
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      text: this.props.text,
      subText: this.props.subText,
      option1Text: props.option1Text,
      option2Text: props.option2Text,
      option3Text: props.option3Text,
      option4Text: props.option4Text,
      icon1: props.icon1,
      icon2: props.icon2,
      icon3: props.icon3,
      icon4: props.icon4,
      cancelText: props.cancelText,
      icon: props.icon,
      isLoading: false,
    };
  }

  handleConfirmation = () => {
    this.setState({ isLoading: true }, this.props.confirmationHandle());
  }

  renderCTAContainer = (option1Text, option2Text, option3Text, option4Text, icon1, icon2, icon3, icon4, cancelText) => {
    if (this.state.isLoading) {
      return this.renderLoader();
    }
    return (
      <React.Fragment>
        <div className="d-flex flex-column">
          {option1Text && <Button className="cta-multibutton-secondary w-100 mt-4" type="iconAndText" icon={icon1}
            value={option1Text} onClick={() => this.props.handleOption1()} />}
          {option2Text && <Button className="cta-multibutton-secondary w-100 mt-4" type="iconAndText" icon={icon2}
            value={option2Text} onClick={() => this.props.handleOption2()} />}
          {option3Text && <Button className="cta-multibutton-secondary w-100 mt-4" type="iconAndText" icon={icon3}
            value={option3Text} onClick={() => this.props.handleOption3()} />}
          {option4Text && <Button className="cta-multibutton-secondary w-100 mt-4" type="iconAndText" icon={icon4}
            value={option4Text} onClick={() => this.props.handleOption4()} />}
          {cancelText && <Button className="cta-multibutton-secondary w-100 mt-4" type="button"
            value={cancelText} onClick={() => this.props.declineHandle()} />}
        </div>
      </React.Fragment>
    );
  }

  renderLoader = () => {
    return <Loader message='O seu pedido está a ser confirmado...' inverted={false} local={true} big={false}></Loader>;
  }

  renderIcon = () => {
    if (!this.state.icon) {
      return <React.Fragment />;
    }

    return (
      <div className="modal-icon-container d-flex align-center justify-content-center mb-4">
        <img src={this.state.icon} alt="modal-icon" />
      </div>
    );
  };

  render() {
    const { title, text, subText, option1Text, option2Text, option3Text, option4Text, icon1, icon2, icon3, icon4, cancelText } = this.state;
    return (
      <div className="w-100">
        {this.renderIcon()}
        <h2 className="m-0 p-0">{title}</h2>
        {text && <p className="light">{text}</p>}
        {subText && <p className="light">{subText}</p>}
        {this.renderCTAContainer(option1Text, option2Text, option3Text, option4Text, icon1, icon2, icon3, icon4, cancelText)}
      </div>
    );
  }
}

export default MultiOptionsSample;
