import Helper from "../helper/helper";
export class CompanyMapper {
 /**
  * @param {CompanyDto} dto
  * @returns {Company}
  */
 static fromCompanyDto(dto) {
   const company = {
     name: dto.name,
     domain: dto.domain,
     logoUrl: Helper.urlCompanyImage(dto.logo_url),
     geo: dto.geo_coords,
     address: dto.address,
     town: dto.town_district,
     distance: dto.distance,
     website: dto.website,
     phone: dto.whatsapp_number || dto.phone,
     email: dto.email,
     url: dto.domain ? this.setUrl(dto.domain) : undefined,
     keywords: dto.keywords,
     registered: dto.registered,
     logged: dto.logged,
     schedulerModule: dto.scheduler_module

   };
   return company;
 }

 /**
  * @param {Company} data
  * @returns {CompanyDto}
  */
 static toCompanyDto(data) {
   const dto = {
     name: data.name,
     domain: data.domain,
     logo_url: data.logoUrl,
     geo_coords: data.geo,
     address: data.address,
     town_district: data.town,
     distance: data.distance,
     website: data.website,
     phone: data.phone,
     email: data.email,
     keywords: data.keywords,
   };
   return dto;
 }

 /**
  * 
  * @param {string} domain 
  * @returns {string | undefined}
  */
 static setUrl = (domain) => {
  if (!domain) {
    return undefined;
  }
   return `https://${domain}.keymaster.pt`
 }
}

