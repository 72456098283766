import React from 'react';

// import Row from 'components/list/input/row.js';
import { FaTimes } from 'react-icons/fa'
import Modal, { Confirmation } from 'components/modal/modal.jsx';
import Button from 'components/button/button.jsx';
import { GENERAL } from 'assets/images/images.js';
import Emitter from 'core/services.js';

import 'components/list/input/row.style.scss';
import 'components/list/input/list.style.scss';
import Helper from '../../../core/helper/helper';

class List extends React.Component {

  constructor(props) {
    super(props);
    this.refModal = React.createRef();
    this.state = {
      selection: false,
      list: [],
    };
  }

  getListValues() {
    return this.state.list;
  }

  _addItem = (row, state) => {
    if (this.props.selection) {
      let newRow = {};
      if (this.props.type === 'email') {
        newRow['email'] = row;
        newRow['invoice'] = true;
      } else {
        newRow['number'] = row;
      }
      if (!this.state.list.length) {
        newRow['primary'] = true;
      } else {
        newRow['primary'] = false;
      }
      this.setState({ list: [...this.state.list, newRow] });
      state.resetFieldState();
      state.setState({ value: '' });
    } else {
      const newRow = {
        code: row,
      }
      this.setState({ list: [...this.state.list, newRow] });
      state.resetFieldState();
      state.setState({ value: '' });
    }
    setTimeout(() => {Emitter.emit('LIST_SELECT_ELEMENT_CHANGED', true);}, 500);
  };

  _addArray = (row, state) => {
    this.setState({ selection: row.selection, list: row.values });
    state.resetFieldState();
    state.setState({ value: '' });
  };

  /**
  * Confirmação para apagar contacto
  */
  _removeItemEvent = (value, row) => {
    const element = this.props.isContact ? 'contacto' : 'equipamento';
    const msg = `Tem a certeza que pretende eliminar este ${element} (${Helper.getItemRowValue(value)}) da lista?`;
    this.refModal.current.renderView(
      <Confirmation
        title ='Atenção'
        text ={msg}
        confirmationHandle = {() => {
          this._removeItem(value, row);
          this.props.onChange();
          setTimeout(() => {Emitter.emit('LIST_SELECT_ELEMENT_CHANGED', true);}, 500);
          this.refModal.current.closeModal();
        }}
        declineHandle = {() => this.refModal.current.closeModal()}
      />);
    this.refModal.current.openModal();
  }

  _removeItem = (value, row) => {
    this.setState({
      list: this.state.list.filter((_, i) => i !== row)
    }, () => {
      if (this.props.type !== 'email') {
        if (value.primary) {
          this.primaryNumberCheck(true, 0);
        }
      }
      this.props.onChange();
    });
  };

  changeItemValue = (index, status) => {
    const tempList = [...this.state.list];
    tempList[index].primary = status;
    this.setState({list: [...tempList]});
  }

  primaryNumberCheck = (check, index) => {
    let newList = [...this.state.list];
    newList = newList.map((el, i) => {
      if (i === index) {
        el.primary = check;
        return el;
      } else {
        el.primary = check ? false : el.primary;
        return el;
      }
    });
    this.setState({ list: newList});
    Emitter.emit('LIST_SELECT_ELEMENT_CHANGED', true);
  }

  invoiceNumberCheck = (index) => {
    let newList = [...this.state.list];
    newList = newList.map((el, i) => {
      if (i === index) {
        el.invoice = !el.invoice;
        return el;
      }
      return el;
    });
    this.setState({ list: newList});
    Emitter.emit('LIST_SELECT_ELEMENT_CHANGED', true);
  }

  renderCheckButton = (value, index) => {
    if (this.props.selection) {
      if (this.props.type === 'email') {
        if (value.invoice) {
          return (
            <Button type='icon' icon={<img className='d-flex rowImg small' src={GENERAL.invoiceOn} alt="" />}
              onClick={() => this.invoiceNumberCheck(index)} />
          );
        } else {
          return (
            <Button type='icon' icon={<img className='d-flex rowImg small' src={GENERAL.invoiceOff} alt="" />}
              onClick={() => this.invoiceNumberCheck(index)} />
          );
        }
      } else {
        if (value.primary) {
          return (
            <Button type='icon' icon={<img className='d-flex rowImg' src={GENERAL.defaultOn} alt="" />}
              />
          );
        } else {
          return (
            <Button type='icon' icon={<img className='d-flex rowImg' src={GENERAL.defaultOff} alt="" />}
             onClick={() => this.primaryNumberCheck(true, index)} />
          );
        }
      }
    }
  }

  renderRemoveIcon = (value, index) => {
    if (this.props.type === 'tech') {
      if (value.id) { return; }
    }
    return <Button type='icon' icon={<FaTimes />} className='rowButton d-flex mt-0' onClick={() => {
      this._removeItemEvent(value, index);
    }} />
  }

  renderRow(row, index) {
    const value = Helper.getItemRowValue(row);
    if (!value) { return; }
    return (
      <li key={this.props.key} className='row-container d-flex mb-2'>
        {this.renderCheckButton(row, index)}
        <div className='rowText' title={value}>{value}</div>
        {this.renderRemoveIcon(row, index)}
      </li>
    );
  }

  renderItem = () => {
    return (this.state.list.map((row, index) => (
      <React.Fragment key={index}>
        {this.renderRow(row, index)}
      </React.Fragment>
    )));
  };

  render() {
    return (
      <React.Fragment>
        <ul className='list-ul'>{this.renderItem()}</ul>
        <Modal ref={this.refModal}/>
      </React.Fragment>
    );
  }
}

export default List
