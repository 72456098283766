import axios from "axios";
import appConfig from "../appConfig";
import LocalData from "../localData";

/**
 * CategoryService class for fetching and managing categories.
 */
class CategoryService {
  /**
   * Fetches a list of categories.
   * @returns {Promise<Category[]>} A promise that resolves to an array of categories.
   */
  static async fetchCategories() {
    try {
      const response = await axios.get(`${appConfig.BASE_URL}/config/vertical`);
      /**@type {Category[]} */
      const categories = response?.data?.data || [];
      LocalData.categories = categories;
      return response?.data?.data;
    } catch (error) {
      // Handle the error, e.g., logging it or returning an empty array
      console.error('Error fetching categories:', error);
      return [];
    }
  }

  /**
   * Finds a category in an array of options based on category name.
   * @param {Option[]} options - An array of options.
   * @param {Category} category - The category to find.
   * @returns {boolean} true if the category is found in options, false otherwise.
   */
  static findCategoryInOptions(options, category) {
    return options?.some(opt => opt.label === category?.name);
  }
}

// Example usage:
// CategoryService.fetchCategories().then(categories => {
//   console.log(categories);
// });

export { CategoryService };
