import React from "react";
import { GENERAL } from "../../assets/images/images";

class RightArrowButton extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={`service-equipment-button-container flex-center ${this.props.class || ""}`}
          onClick={this.props.onClick}>
          <div className='service-equipment-button'>
            <div className={'button-oval'}>
              <img src={GENERAL.arrowBigRight} alt=""/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RightArrowButton;