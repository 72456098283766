// React Imports
import React from 'react';
// Components
import Button from 'components/button/button.jsx';
import Loader from '../../loader/loader';

class ConfirmationSample extends React.Component {
   /**
   * @typedef {Object} ConfirmationSampleProps
   * @property {string} title - The title for the confirmation.
   * @property {string} text - The main text for the confirmation.
   * @property {string} subText - Additional subtext for the confirmation.
   * @property {string} [confirmText='Confirmar'] - Text for the confirm action button. (Optional)
   * @property {string} [cancelText='Cancelar'] - Text for the cancel action button. (Optional)
   * @property {string} [icon] - Icon to display in the confirmation. (Optional)
   */

  /**
   * @param {ConfirmationSampleProps} props - Props for the ConfirmationSample component.
   */
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      text: this.props.text,
      subText: this.props.subText,
      confirmText: props.confirmText ? props.confirmText : 'Confirmar',
      cancelText: props.cancelText ? props.cancelText : 'Cancelar',
      icon: props.icon,
      isLoading: false,
    };
  }

  handleConfirmation = () => {
    this.setState({ isLoading: true },  this.props.confirmationHandle());
  }

  renderCTAContainer = (confirmText, cancelText) => {
    if (this.state.isLoading) {
      return this.renderLoader();
    }
    return (
      <React.Fragment>
          <div className="d-none d-md-flex justify-content-center mt-4">
          <Button className="cta-button-secondary d-medium px-3 me-2" type="button"
            value={cancelText} onClick={() => this.props.declineHandle()} />
          <Button className="cta-button-primary d-medium px-5 ms-2" type="button"
            value={confirmText} onClick={() => this.handleConfirmation()}/>
        </div>
        <div className="d-flex flex-column d-md-none justify-content-center mt-4">
          <Button className="cta-button-primary d-medium px-3 w-100" type="button"
            value={confirmText} onClick={() => this.handleConfirmation()} />
          <Button className="cta-button-secondary d-medium px-3 w-100 mt-4" type="button"
            value={cancelText} onClick={() => this.props.declineHandle()} />
        </div>
      </React.Fragment>
    );
  }

  renderLoader = () => {
    return <Loader message='O seu pedido está a ser confirmado...' inverted={false} local={true} big={false}></Loader>;
  }

  renderIcon = () => {
    if (!this.state.icon) {
      return <React.Fragment />;
    }

    return (
      <div className="modal-icon-container d-flex align-center justify-content-center mb-4">
        <img src={this.state.icon} alt="modal-icon" />
      </div>
    );
  };

  render() {
    const { title, text, subText, confirmText, cancelText } = this.state;
    return (
      <div className="w-100">
        {this.renderIcon()}
        <h2 className="m-0 p-0">{title}</h2>
        <p className="light mt-4">{text}</p>
        <p className="light">{subText}</p>
        {this.renderCTAContainer(confirmText, cancelText)}
      </div>
    );
  }
}

export default ConfirmationSample;
