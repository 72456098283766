import React from "react";
import Cookies from "universal-cookie";
import LocalData from "../localData";
import Emitter from "../services";
import {
  fetchHeadersWithAuth,
  getCompaniesCountData,
  getCompaniesListData,
  getNewCompaniesListData,
  preUserSignUp,
} from "../../api/routes/api.route";
import { CompanyMapper } from "../models/company.model";
import axios from "axios";
import appConfig from "../appConfig";
import Helper from "../helper/helper";

class CompanyService extends React.Component {
  static cookies = new Cookies();

  static getCompanyList() {
    if (this.cookies.get("sessionToken")) {
      this.getUserCompaniesList();
    }
  }
  static getUserCompaniesList() {
    getCompaniesListData(this.cookies.get("sessionToken")).then((res) => {
      if (res) {
        if (res.code === 401) {
          LocalData.companies = [];
          Emitter.emit("COMPANIES_LOADED");
          return;
        }
        if (res.code) {
          LocalData.errorCompanies = true;
          LocalData.companies = [];
          // if (res.code === 409 && res.error_code === 2) {
          //   //this.callPreSignup();
          // }
          Emitter.emit("COMPANIES_LOADED");
        } else {
          LocalData.userRegistered = true;
          LocalData.errorCompanies = false;
          LocalData.companies = res?.data?.map((/** @type {CompanyDto} */ c) =>
            CompanyMapper.fromCompanyDto(c)
          );
          Emitter.emit("COMPANIES_LOADED");
        }
      }
    });
  }
  static getNewCompaniesList() {
    // if (LocalData.terms_accepted) {
    getNewCompaniesListData(this.cookies.get("sessionToken")).then((res) => {
      if (res) {
        if (res.code === 401) {
          return;
        }
        if (res.code) {
          LocalData.errorNewCompanies = true;
          LocalData.newCompanies = [];
        } else {
          //console.log('getNewCompaniesListData', res);
          LocalData.userRegistered = true;
          LocalData.errorNewCompanies = false;
          LocalData.newCompanies = res?.data?.map((/** @type {CompanyDto} */ c) =>
            CompanyMapper.fromCompanyDto(c)
          );
        }
      }
    });
    // }
  }

  static getCompaniesCount() {
    if (LocalData.terms_accepted) {
      getCompaniesCountData(this.cookies.get("sessionToken")).then((res) => {
        if (res) {
          if (res.http_code || res.code) {
            if (res.http_code === 401) {
              Helper.logoutFromLite();
              return;
            }
            if (res.code === 409 && res.error_code === 2) {
              LocalData.companiesCount = undefined;
              //this.callPreSignup();
            }
          } else {
            LocalData.companiesCount = res.data;
            Emitter.emit("COMPANIES_COUNT", res.data?.warnings);
            Emitter.emit("APPOINTMENTS_COUNT", res.data?.scheduled_appointments);
          }
        }
      });
    }
  }

  static callPreSignup = () => {
    preUserSignUp(null, this.cookies.get("sessionToken")).then((value) => {
      if (value) {
        Emitter.emit("REGISTER_STATE_CHANGED", value);
      }
    });
  };

  /**
   * Fetches a list of companies.
   * @param {Category} [category] - An optional category object.
   * @param {string} [geoCoords] - Optional geographical coordinates.
   * @returns {Promise<Company[]>} A Promise that resolves to an array of company objects.
   */
  static fetchNewCompanies = async (category, geoCoords) => {
    let params = {};
    let response = null;
    if (category) {
      params.verticalName = category.key;
    }
    if (geoCoords) {
      params.geoCoords = geoCoords;
    }
    if (!category && LocalData.newCompanies?.length) {
      return LocalData.newCompanies;
    }

    try {
      if (!Helper.isLiteVersion()) {
         response = await axios.get(
          `${appConfig.BASE_URL}/client/company/new`,
          { params, headers: fetchHeadersWithAuth(this.cookies.get("sessionToken"))},
        );
      } else {
        response = await axios.get(`${appConfig.BASE_URL}/company/v2`, {
          params,
        });
      }
      /** @type {Company[]} */
      const companies = (response?.data?.data || []).map((/** @type {CompanyDto} */ dto) =>
        CompanyMapper.fromCompanyDto(dto)
      );
      if (!category) {
        LocalData.newCompanies = companies;
      }
      return companies;
    } catch (error) {
      console.error("Error fetching companies:", error);
      return [];
    }
  };

  /**
   * Filter companies based on selected options.
   * @param {Company[]} companies - An array of company objects.
   * @param {Option[]} options - An array of selected options.
   * @returns {Company[]} - An array of filtered company objects.
   */
  static filterCompanies(companies, options) {
    return companies?.filter((company) => {
      const companyName = company?.name?.toLowerCase();
      return options?.some((option) => companyName?.includes(option?.label?.toLowerCase()));
    });
  }

  /**
   * Sets suggestions for companies.
   * @param {Company[]} companies - An array of company objects.
   * @param {Category|undefined} selectedCategory - The selected category (or undefined).
   * @returns {Option[]} An array of option objects.
   */
  static setSuggestions = (companies, selectedCategory) => {
    return companies?.map((c) => {
        return {
          value: c?.name,
          label: c?.name,
          isDisabled: c.name === selectedCategory?.name,
        };
      })
      .filter((c) => !c.isDisabled);
  };
}

export default CompanyService;
