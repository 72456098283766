// React Imports
import React from "react";
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
// Components
import Modal, { Information } from "components/modal/modal.jsx";
// API Imports
import { confirmEmail } from "api/routes/api.route.js";
import Labels from "../../variables/labels";
import Helper from "../../core/helper/helper";
import Cookies from "universal-cookie";
class EmailConfirmacao extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.state = {
      message: "",
      token: "",
    };
  }

  /**
   *  Validação do token recebido por email, após o resgisto do utilziador
   */
  componentDidMount = () => {
    //    ReactGA.pageview(window.location.pathname + window.location.search);
    const search = this.props.location.search;
    const email = new URLSearchParams(search).get("email") || "";
    const userID = new URLSearchParams(search).get("userid");
    const liteToken = new URLSearchParams(search).get("worktoken");
    this.setState({ email });

    if (userID) {
      this.cookies.set("userId", userID, Helper.cookiesLiteConfig);
    }

    if (liteToken) {
      this.cookies.set("liteToken", liteToken, Helper.cookiesLiteConfig);
    }

    if (this.props.match.params.token) {
      const token = this.props.match.params.token;
      const params = token;

      confirmEmail(params).then((res) => {
        if (res) {
          if (res.http_code) {
            this.setState({ message: res.message });
            setTimeout(() => {
              this.props.history.push("/registo");
            }, 1500);
          } else {
            this.setState({ message: res.data?.message });
            setTimeout(() => {
              this.props.history.push({
                pathname: `/pre-signup`,
                state: { email: this.state.email },
              });
            }, 2000);
          }
        } else {
          this.renderModal(
            <Information
              title="Pedido Sem Sucesso"
              text="Foi encontrado um problema durante o pedido, por favor tente mais tarde"
              onClick={this.props.history.push("/login")}
            />
          );
        }
      });
    } else {
      this.renderModal(
        <Information
          title="Token Inválido"
          text="O token recebido não é válido"
          onClick={this.props.history.push("/login")}
        />
      );
    }
  };

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  };

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}
      />
    );
  };

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  };

  render() {
    return (
      <div className="grey-box-container mt-5">
        <h3 className="text-center w-100 mt-3"> {Labels.emailConfirmation.header}</h3>
        <div className="message-container mt-4">{this.state.message}</div>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}
export default withRouter(EmailConfirmacao);
