// React Imports
import React from 'react';
import { CookiesLabels } from '../../variables/cookies.labels';

class CookiesDetails extends React.Component {
  renderPolicy = () => {
    return (
      <div className="modal-section">
        <div className="modal-title-text">{CookiesLabels.page.policy.title}</div>
        <div className="modal-sub-title-text" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.policy.subTitle }}></div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.policy.content }}></div>
      </div>
    );
  }

  renderInterpretationDefinitions = () => {
    const definitionsContentB = CookiesLabels.page.definitions.contentB.replaceAll('{0}', 'https://asuaempresa.keymaster.pt');
    return (
      <div className="modal-section">
        <div className="modal-title-text">{CookiesLabels.page.definitions.title}</div>
        <div className="modal-sub-title-text">{CookiesLabels.page.definitions.subTitleA}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.definitions.contentA }}></div>
        <div className="modal-sub-title-text">{CookiesLabels.page.definitions.subTitleB}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: definitionsContentB }}></div>
      </div>
    );
  }

  renderUsage = () => {
    return (
      <div className="modal-section">
        <div className="modal-title-text">{CookiesLabels.page.usage.title}</div>
        <div className="modal-sub-title-text">{CookiesLabels.page.usage.subTitleA}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.usage.contentA }}></div>
        <div className="modal-sub-title-text">{CookiesLabels.page.usage.subTitleB}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.usage.contentB }}></div>
        {/*<div className="modal-sub-title-text">{CookiesLabels.page.usage.subTitleC}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.usage.contentC}}></div>*/}
        <div className="modal-sub-title-text">{CookiesLabels.page.usage.subTitleD}</div>
        <div className="modal-message-container" style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: CookiesLabels.page.usage.contentD }}></div>
      </div>
    );
  }

  render() {
    return (
      <div >
        {this.renderPolicy()}
        {this.renderInterpretationDefinitions()}
        {this.renderUsage()}
      </div>
    );
  }
}

export default CookiesDetails;
