// React Imports
import React from 'react';
import { withRouter } from 'react-router-dom';
//import ReactGA from 'react-ga';
// Components Imports
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Confirmation, Information } from 'components/modal/modal.jsx';
// API Imports
import { userSignUp, sendNewEmailConfirmation } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import LocalData from '../../core/localData';
import Helper from '../../core/helper/helper';
import Cookies from 'universal-cookie';

class Registo extends React.Component {

  constructor(props){
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.state = {
      email: props.location?.state?.email || '',
      password: '',
      checkPassword: '',
    };
  }

  componentDidMount() {
//    ReactGA.pageview(window.location.pathname + window.location.search);
    const search = this.props.location.search;
    let email = new URLSearchParams(search).get('email') || '';
    email = this.props.location?.state?.email || email;
    this.setState({ email: email });

    if (!(new URLSearchParams(search).get('fromLite'))) {
      this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
      this.cookies.remove('userId', Helper.cookiesLiteConfig);
    }
  }

  /**
  * Extrai o valor do campo do email.
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangeEmail = (event) => {
    this.setState({email: event.target.value});
  }
  /**
  * Extrai o valor do campo da password
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangePassword = (event) =>  {
    this.setState({password: event.target.value});
  }

  /**
  * Extrai o valor do campo da confirmação da password
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangeCheckPassword = (event) =>  {
    this.setState({checkPassword: event.target.value});
  }

  /**
  * Verifica se as password são iguais, em caso de sucesso será
  * returnado @true caso contrartio será returnado @false com uma
  * respectiva mensagem através de um modal
  *
  * @param {string} password - valor da password
  * @param {string} password - valor da password de confirmação
  */
  checkPasswordValidation = (password, passwordConfirm) =>  {
    if (password === passwordConfirm) { return true; }
    this.refModal.current.renderView(
      <Information
        title='Dados Incorrectos'
        text="As passwords inseridas são diferentes"
        onClick={() => this.refModal.current.closeModal()} />
    );
    this.refModal.current.openModal();
    return false;
  }

/**
 *  Função OnClick do formulário de Registo, que irá fazer o pedido a API 
 *  para inicio do registo do novo cliente, em caso de dados incorrectos 
 *  ou dados já registados o utilizar será notificado com messagem atraves
 *  de um modal.
 * 
 *  @param {event} event - callback do evento OnSubmit()
*/
  handleSubmit = (event) =>  {
    event.preventDefault();
    const USER_AUTH = {
      email: this.state.email,
      password: this.state.password,
    }

    const checked = this.checkPasswordValidation(this.state.password, this.state.checkPassword);
    if(checked){
      this.handleUserSignup(USER_AUTH);
    }
  }

  /**
 *  Função que irá reenviar um novo de email de confirmação do registo realizado
 *  caso este ja tenha feito o registo com o seu email mas não tenha 
 *  recebido nenhuma mensagem de confirmação.
*/
  confirmationHandle = () => {

    this.refModal.current.closeModal();

    const USER_AUTH = {
      email: this.state.email,
      password: this.state.password,
    }

    let body = { ...USER_AUTH };
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }

    sendNewEmailConfirmation(body).then(res => {
      try {
        if (res.http_code === undefined || res.http_code === 200){
          let state = { email: this.state.email, password: this.state.password };
          if (res.data?.user_id) {
            this.cookies.set('liteToken', res.data?.work_token, Helper.cookieresLiteConfig);
            this.cookies.set('userId', res.data?.user_id, Helper.cookiesLiteConfig);
            state = {
              ...state,
              userId: this.cookies.get('userId'),
              liteToken: this.cookies.get('liteToken')
            }
          }
          this.props.history.push({
              pathname: `/send-confirm-email`,
              state
            });
        } else if (res.http_code === 404) {
          if (res.code === 3) {
            this.renderInfoModal(Labels.genericErrorTitle, res.message);
          }
        } else {
          this.renderInfoModal('Dados Incorrectos', res.message);
        }
      } catch(error){
        this.renderGenericError();
      }
    });
  } 

  handleUserSignup(USER_AUTH) {
    let body = { ...USER_AUTH };
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }
    userSignUp(body).then(response => {
      if (response) {
        if (response.http_code) {
          if (response.http_code === 401) {
            return;
          } else if (response.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', response.message);
          } else if (response.http_code === 409) {
            if (response.code === 1) {
              this.renderModal(
                <Confirmation
                  title='E-mail por Confirmar'
                  text={response.message}
                  confirmationHandle={() => this.confirmationHandle()}
                  declineHandle={() => this.refModal.current.closeModal()} />);
            } else if (response.code === 2) {
              LocalData.userRegistered = false;
              this.props.history.push('/dados-cliente');
            } else if (response.code === 3) {
              this.renderInfoModal('Utilizador já Existente', response.message);
            } else {
              this.renderModal(
                <Confirmation
                  title='E-mail já Associado'
                  text={Labels.emailAssociatedMesssage}
                  confirmationHandle={() => this.confirmationHandle()}
                  declineHandle={() => this.refModal.current.closeModal()} />);
            }
          } else if (response.http_code === 404) {
            if (response.code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, response.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          let state = {
            email: this.state.email,
            password: this.state.password,
          }
          if (response.data?.user_id) {
            this.cookies.set('liteToken', response.data?.work_token, Helper.cookiesLiteConfig);
            this.cookies.set('userId', response.data?.user_id, Helper.cookiesLiteConfig);
            state = {
              ...state,
              userId: response.data?.user_id,
              liteToken: response.data?.work_token
            }
          }
          this.props.history.push({
            pathname: `/send-confirm-email`,
            state
          });
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  handleClickLogin = () => {
    this.props.history.push({
      pathname: '/login',
      state: { email: this.state.email }
    });
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}/>
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <React.Fragment>
        <h3 className="text-center w-100 mb-4"> {Labels.register.header}</h3>
        <div className='grey-box-container verybig'>
          <div className='box-form-container'>
            <form onSubmit={this.handleSubmit}>
              <InputField
                styleInput="input-medium-default mt-0"
                type='email' title='Utilizador'
                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                value={this.state.email} 
                placeholder='exemplo@mail.com' 
                onChange={this.handleChangeEmail}
                required={true} />
              <InputField
                styleInput="input-medium-default password"
                type='password' title='Password'
                forgotPassword={false} value={this.state.password} 
                onChange={this.handleChangePassword}
                pattern={Helper.passwordRegex}
                required={true}/>
              <InputField
                styleInput="input-medium-default password"
                type='password' title='Confirmar Password'
                forgotPassword={false}
                value={this.state.checkPassword}
                onChange={this.handleChangeCheckPassword}
                pattern={Helper.passwordRegex}
                required={true}/>
              <Button className='cta-button-primary d-medium w-100 mt-4' type='submit' value='Registar'/>
              <div onClick={this.handleClickLogin} className='link-container mt-4'>{Labels.register.linkMessage}</div>
            </form>
          </div>
        </div>
{/* 
        <ButtonDivider />
        <LoginProvider provider="google" />
        <LoginProvider provider="apple" />         */}
        
        <Modal ref={this.refModal}/>
      </React.Fragment>  
    );
  }
}
export default withRouter(Registo);