// React Imports
import React from 'react';
import { withRouter } from 'react-router-dom';
//import ReactGA from 'react-ga';
// Components Imports
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { sendNewEmailConfirmation } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import { GENERAL } from '../../assets/images/images';
import Cookies from 'universal-cookie';
import Helper from '../../core/helper/helper';

class SendEmailRegisto extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
//    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
   *  Verifica se o email ainda se encontra disponivel, caso não esteja, é
   *  retornado para a pagina de registo.
  */
  checkEmailValue = () => {
    if (this.props.location.state?.email) {
      var email = this.props.location.state.email;
      return email;
    } else {
      this.props.history.push('/registo');
    }
  }

  /**
   *  Função OnClick caso o utilizador pretenda reenviar um 
   *  novo e-mail.
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */
  handleSubmit = (event) => {

    event.preventDefault();

    let body = {
      email: this.props.location.state.email,
      password: this.props.location.state.password,
    };

    if (this.props.location.state.userId || this.cookies.get('userId')) {
       body = {
         ...body,
         user_id: this.props.location.state.userId || this.cookies.get('userId'),
         work_token: this.props.location.state.liteToken || this.cookies.get('liteToken'),
       };
    }

    sendNewEmailConfirmation(body).then(res => {
      if (res) {
        if (res.http_code) {
          if (res.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', res.message);
          } else if (res.http_code === 404) {
            if (res.code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, res.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          this.renderInfoModal('E-mail Reenviado', res.data?.message);
          if (res.data?.user_id) {
            this.cookies.set('liteToken', res.data?.work_token, Helper.cookiesLiteConfig);
            this.cookies.set('userId', res.data?.user_id, Helper.cookiesLiteConfig);
          }
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <div className='grey-box-container mt-5'>
        <h3 className='text-center w-100 mt-3'> {Labels.sendConfirmEmailInfo.title}</h3>
        <div className="check-icon-container mt-5 mb-2">
          <img src={GENERAL.checkGreen} alt=''/>
        </div>
        <div className="message-container mt-4">
          (<b>{Labels.sendConfirmEmailInfo.text_1} </b> {Labels.sendConfirmEmailInfo.text_2} <b> {this.checkEmailValue()}</b>)
        </div>
        <div className="message-container mt-4"> {Labels.sendConfirmEmailInfo.link_1}
          <a className='link-container' href={process.env.PUBLIC_URL + '/login'}>  <br/> Inicie sessão aqui!</a>
        </div>
        <div className="message-container mt-4">
          <div onClick={this.handleSubmit} className='link-container'> {Labels.sendConfirmEmailInfo.link_2} </div>
        </div>
        <div><b>({Labels.sendConfirmEmailInfo.text_1})</b></div>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default withRouter(SendEmailRegisto)