import React, { Fragment } from "react";
import LinkButton from "../../../button/LinkButton/LinkButton";
/**
 * @param {Object} props - The props object.
 * @param {Company} props.company - The company object (or undefined).
 */
const CompanyEmailInfo = (props) => {
  const { company } = props;

  if (!company?.email) {
    return <Fragment />;
  }

  const tab = () => {
    if (company?.website && company?.email)
    return (<div className="mx-1">|</div>);
  }

  return (
    <div className="info-text d-inline-flex">
      {tab()}
      <LinkButton
        onClick={() => {}}
        styleClass="link-container"
        textClass="small"
        isMailTo={true}
        valueLink={company?.email}
        valueText={company?.email}
      />
    </div>
  );
}
export default CompanyEmailInfo;