import React from 'react';
import Button from 'components/button/button.jsx';

const ConfirmationCTA = (props) => {
  const { cancelText, confirmText, propStyle } = props;

  const renderCancelButton = (styleClass) => {
    if (!cancelText) {
      return;
    }

    return (
      <Button className={`cta-button-secondary d-medium ${styleClass}`} type="button"
          value={cancelText} onClick={() => props.declineHandle()} />
    );
  }

  return (
    <React.Fragment>
      <div className={`d-none d-md-flex justify-content-center mt-4 ${propStyle ? propStyle : ''}`}>
        { renderCancelButton('px-3 me-2')}
        <Button className="cta-button-primary d-medium px-5 ms-2" type="button"
          value={confirmText} onClick={() => props.confirmationHandle()} />
      </div>
      <div className={`d-flex flex-column d-md-none justify-content-center mt-4 ${propStyle ? propStyle : ''}`}>
        <Button className="cta-button-primary d-medium px-3 w-100" type="button"
          value={confirmText} onClick={() => props.confirmationHandle()} />
        { renderCancelButton('px-3 w-100 mt-4')}
      </div>
    </React.Fragment>
  );
}
export default ConfirmationCTA;