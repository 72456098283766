  import React, { Component } from 'react';
import 'components/gallery/gallery.style.scss';
import Helper from '../../core/helper/helper';

class GalleryImageCard extends Component {

  constructor(props) {
    super(props);
    this.state = { spans: 0, prevHeight: 0 };
    this.refImage = React.createRef();
  }

  componentDidMount() {
    this.refImage.current.addEventListener('load', this.setSpans);
    window.addEventListener('resize', this.setSpans);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.setSpans);
  }

  setSpans = () => {
    const height = this.refImage.current.clientHeight;
    if (height !== this.state.prevHeight) {
      const spans = Math.ceil(height / 12);
      this.setState({ spans, prevHeight: height });
    }
  }

/**
* Render do icon de play para caso de ser um video
*
* @param {string} item - item representativo da informação da photo
*/
  renderPlayIcon = (item) => {
    if (!Helper.isVideo(item.type)) {
      return;
    }

    return (
      <React.Fragment>
        <div className='overlay-image'></div>
        <div className="background-fa"></div>
        <i className="fa fa-play-circle"></i>
      </React.Fragment>
    );
  }

  setSrcImage = (item) => {
    return item.thumbnail_url && item.thumbnail_url !== '' ? Helper.urlWorkImage(item.thumbnail_url)
    : (item.thumbnail && item.thumbnail !== '' ? `data:image/png;base64, ${item.thumbnail}` : '');
  }

  render() {
    const { item } = this.props;
    const { spans } = this.state;
    const srcImage = this.setSrcImage(item);
    return (
      <div
        className="gallery-photo"
        style={{ gridRowEnd: `span ${spans}` }}
        onClick={this.props.onClick}
      >
        <img
          ref={this.refImage}
          src={srcImage}
          alt=""
          className={(Helper.isLiteVersion() && !this.props.onCreateAppointment) ? "pixelized" : ""}
        />
        {this.renderPlayIcon(item)}
      </div>
    );
  }
}
export default GalleryImageCard;