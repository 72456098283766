import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import Button from 'components/button/button.jsx';
import List from 'components/list/input/list.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// Assets
import { GENERAL } from 'assets/images/images.js';

import 'components/inputField/inputField.style.scss';
import Labels from '../../variables/labels';
import Helper from '../../core/helper/helper';

class InputField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.customValue ? this.props.customValue : this.props.value,
      prevValue: null,
      addIcon: GENERAL.add,
      isPassword: this.props.type === 'password',
      type: this.props.type,
      inactive: true,
      showInputField: true,
      inputFieldListClass: '',
    };

    this.listRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.listRef.current) {
        this.setAddIcon();
      }
    }, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const listCount = this.listRef?.current?.getListValues()?.length;
    let shouldShowInputField = true;
    let inputFieldListClass = '';
    if ((this.props.type === 'text' && this.props.valueFormat === 'phoneNumber' && listCount === 3)
      || (this.props.type === 'email' && listCount === 2)) {
      shouldShowInputField = false;
    }
    if ((this.props.type === 'text' && this.props.valueFormat === 'phoneNumber')
      || (this.props.type === 'email')) {
      inputFieldListClass = 'mb-2';
    }

    if (shouldShowInputField !== this.state.showInputField) {
      this.setState({ showInputField: shouldShowInputField });
    }
    if (inputFieldListClass !== this.state.inputFieldListClass) {
      this.setState({ inputFieldListClass: inputFieldListClass });
    }
  }

  getValues() {
    return this.state.value;
  }

  setValue(value) {
    this.setState({ value: value });
  }

  resetFieldState = () => {
    if (this.inputRef.current) this.inputRef.current.value = '';
  }

  handleChangeValue = (event) => {
    this.handleInputError(event);
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  handleOnBlur = (event) => {
    if (this.props.addButton) {
      if (event.target.value && this.state.prevValue !== event.target.value && event.target.value !== '') {
        this._inputListValidation(event.target.value);
        this.setState({ prevValue: event.target.value });
      }
    }

    this.setState({ inactive: true });
    if (this.props.type === 'password') {
      this.toggleShowPassword('password');
    }
  }

  handleOnFocus = () => {
    this.setState({ inactive: false });
  }

  handleValueFormat = (value) => {
    if (value) {
      if (this.props.valueFormat === 'default')
        return (value);
      if (this.props.valueFormat === 'phoneNumber')
        return value;
      if (this.props.valueFormat === 'licensePlate')
        return (value);
    }
  }

  listValues = () => {
    return this.listRef.current.getListValues();
  }

  addArrayToList = (value, type) => {
    const ARRAY = {
      selection: this.props.selection,
      values: []
    };
    value.forEach(row => {
      row[type] = this.handleValueFormat(row[type]);
      ARRAY.values.push(row);
    });
    this.listRef.current._addArray(ARRAY, this);
  }

  addToList = (value) => {
    this.listRef.current._addItem(this.handleValueFormat(value), this);
  }

  _inputList(trigger) {
    if (trigger) {
      return (<List ref={this.listRef} selection={this.props.selection} type={this.props.type} isContact={!this.props.noLimit} onChange={() => {
        this.setAddIcon();
      }} />);
    }
  }

  _inputListButton(trigger, text) {
    if (trigger) {
      return (
        <Button className='button-container' type='button' value={text}
          onClick={() => this._inputListValidation(this.state.value)} />
      );
    }
  }

  _sideButton(trigger) {
    if (trigger) {
      return (<Button type='smallSideButton' icon={this.props.icon} onClick={this.props.onClick} />);
    }
  }

  setAddIcon() {
    const { type, noLimit } = this.props;
    setTimeout(() => {
      if (this.props.forceRerender) {
        this.props.forceRerender();
      }
      if (this.listRef.current) {
        if ((type === 'email' && this.listRef.current.getListValues().length < 2) ||
          (type === 'text' && this.listRef.current.getListValues().length < 3) || noLimit) {
          this.setState({ addIcon: GENERAL.addBlue });
        }
        if ((type === 'email' && this.listRef.current.getListValues().length === 2) ||
          (type === 'text' && !noLimit && this.listRef.current.getListValues().length === 3)) {
          this.setState({ addIcon: GENERAL.add });
        }
      }
    }, 250);
  }

  _addButton = (trigger) => {
    if (trigger) {
      return (<Button type='addSideButton' icon={this.state.addIcon} onClick={() => this._inputListValidation(this.state.value)} />);
    }
  }

  renderShowPassword = () => {
    const { type, isPassword, inactive } = this.state;
    if (isPassword) {
      if (type === 'password') {
        return (<Button type='showPassword' className={inactive ? 'icon-inactive' : ''}
          icon={GENERAL.eyeHide} onClick={() => this.toggleShowPassword('text')} />);
      } else {
        return (<Button type='showPassword' className={inactive ? 'icon-inactive' : ''}
          icon={GENERAL.eyeButton} onClick={() => this.toggleShowPassword('password')} />);
      }
    }
  }

  toggleShowPassword = (inputType) => {
    this.setState({ type: inputType});
  }

  _inputListValidation(value) {
    if (value) {
      this.emailValidation();
      this.contactValidation(value);
    }
  }

  contactValidation(value) {
    const contacts = this.listRef.current.getListValues();
    if (this.props.type === 'text') {
      if (this.props.valueFormat === 'phoneNumber') {
        if (contacts.length < 3) {
          if (value.length >= this.props.minLength && value.length <= this.props.maxLength) {
            const exists = contacts.find(el => el.number === value);
            if (!exists) {
              if (Helper.testContactRegex(value)) {
                this.listRef.current._addItem(this.state.value.toUpperCase(), this);
                this.setAddIcon();
              } else {
                Modal.create(<Information title="Aviso" text={Labels.profileInvalidContactNumber} onClick={() => Modal.close()} />);
              }
            } else {
              Modal.create(<Information title="Aviso" text={Labels.profileContactNumberError} onClick={() => Modal.close()} />); 
            }
          }
          else {
            Modal.create(<Information title="Aviso" text={Labels.profileInvalidContactNumber} onClick={() => Modal.close()} />);
          }
        }
      } else {
        if (this.listRef.current.getListValues().length < 3 && !this.props.noLimit) {
          if (value.length >= this.props.minLength && value.length <= this.props.maxLength) {
            this.listRef.current._addItem(this.state.value.toUpperCase(), this);
            this.setAddIcon();
          } else {
            Modal.create(<Information title="Aviso" text={Labels.profileInvalidLicenseNumber} onClick={() => Modal.close()} />);
          }
        } else {
          this.listRef.current._addItem(this.state.value.toUpperCase(), this);
          this.setAddIcon();
        }
      }
    }
  }

  emailValidation() {
    if (this.props.type === 'email' && this.listRef.current.getListValues().length < 2) {
      if (Helper.testEmailRegex(this.state.value)) {
        this.listRef.current._addItem(this.state.value, this);
        this.setAddIcon();
      }
      else {
        Modal.create(<Information title="Aviso" text={Labels.profileInvalidEmail} onClick={() => Modal.close()} />);
      }
    }
  }

  handleKeyDown = (e) => {
    if (this.props.enterKeyPress && e.key === 'Enter') {
      if (this.props.addButton) {
        e.preventDefault();
        this._inputListValidation(this.state.value)
      } else {
        this.props.onClick();
      }
    }
  }

  handleInputError = (e) => {
    const value = this.inputRef.current.value;
    if (e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        if (e.target.validity.patternMismatch) {
          if (!Helper.testPasswordRegex(value) && this.props.type === 'password') {
            e.target.setCustomValidity(Labels.passwordPatternError);
          } else if (!Helper.testEmailRegex(value) && this.props.type === 'email') {
            e.target.setCustomValidity(Labels.emailPatternError);
          }
        }
      }
    }
  }

  renderLabel(title) {
    if (!title) {
      return <></>;
    }
    return <p className="input-label">{title}</p>;
  }

  render() {
    const { title, addButton, styleAddInput, styleInput, styleClass, placeholder,
      maxLength, minLength, min, required, pattern, sideButton, list } = this.props;
    const showInputField = this.state.showInputField;
    const inputClass = `${this.state.inactive && this.inputRef?.current?.value ? 'filled' : ''} ${addButton ? styleAddInput : styleInput} ${this.state.inputFieldListClass}`;

    return (
      <div className={`input-container ${styleClass || ''}`}>
        {this.renderLabel(title)}
        {showInputField && <div className="input-row-container">
          <input
            ref={this.inputRef}
            className={inputClass}
            type={this.state.type}
            onKeyDown={this.handleKeyDown}
            value={this.handleValueFormat(this.state.value) || ''}
            onChange={this.handleChangeValue}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            min={min}
            required={required}
            onInvalid={this.handleInputError}
            onClick={this.props.onClick}
            pattern={pattern} />
          {this._sideButton(sideButton)}
          {this._addButton(addButton)}
          {this.renderShowPassword()}
        </div>}
        {this._inputList(list)}
        <Modal />
      </div>
    );
  }
}

export default InputField;

InputField.defaultProps = {
  type: 'text',
  title: '',
  valueFormat: 'default',

  forgotPassword: true,
  linkTo: `/`,
  maxLength: '',
  minLength: '',

  styleInput: 'input-medium-style',
  styleAddInput: 'input-add-style',
  styleLabel: 'input-label',
}


