// React Imports
import React from 'react';
// Styles
import 'components/progressBar/progressBar.style.scss';

class ProgressBar extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      small: this.props.small,
      levels: this.props.levels,
      state: this.props.state,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        small: this.props.small,
        levels: this.props.levels,
        state: this.props.state,
      })
    }
  }

  /**
  * Preenchimento da Progress Bar
  *
  * @param {string} size - Tamanho da Progess Bar (small = '', big = 'Extra')
  */
  fillProgressBar = (size) => {
    const { state: { bars }, levels } = this.state;
    const progress = []
    const completed = bars === 5 ? ' completed' : '';
    for(let i = 0; i < levels; i++){
      if(i < bars) {
        if (i === 0) {
          progress.push(<div key={'start' + i} className={'progress-bar start'+size + `${completed}`}/>);
        } else if (i === levels - 1) {
          progress.push(<div key={'end' + i} className={'progress-bar end'+size + `${completed}`}/>);
        } else if (bars === 2.5 && i === 2) {
          progress.push(<div key={'middle-half-left' + i} className={'progress-bar middle' + size + ' half-left'}/>);
          progress.push(<div key={'middle-half-right' + i} className={'progress-bar middle' + size + ' half-right'}/>);
        } else {
          progress.push(<div key={'middle' + i} className={'progress-bar middle'+size + `${completed}`}/>);
        }
      } else {
        if (i === 0) {
          progress.push(<div key={'start-disable-left' + i} className={'progress-bar start-disable' + size + ' half-left'}/>);
          progress.push(<div key={'start-disable-right' + i} className={'progress-bar start-disable' + size + ' half-right'}/>);
        } else if (i === levels - 1) {
          progress.push(<div key={'end-disable' + i} className={'progress-bar end-disable'+size + `${completed}`}/>);
        } else {
          progress.push(<div key={'middle-disable' + i} className={'progress-bar middle-disable'+size + `${completed}`}/>);
        }
      }
    }
    return progress
  }

  /**
  * Apresentação do Progress Bar, tamanho pequeno
  */
  renderSmallBar = (nameClass, name) => {
    return (
      <div className='progress-bar-info mt-3'>
        <div className='progress-bar-container'>
          {this.fillProgressBar('')}
        </div>
        <div className={`progress-bar-info-text mt-2 ${nameClass}`}>{name}</div>
      </div>
    );
  }

  /**
  * Apresentação do Progress Bar, tamanho grande
  */
  renderBigBar = (nameClass, name) => {
    return (
      <div className='progress-bar-info extra mt-2'>
      <div className='progress-bar-container'>
        {this.fillProgressBar('Extra')}
      </div>
      <div className={`progress-bar-info-text mt-2 ${nameClass}`}>{name}</div>
    </div>
    );
  }

  render() {
    const missedAppointment = this.props.missedAppointment;
    const appointmentCancelled = this.props.is_cancelled && this.props.isAppointment;
    const is_history = this.props.is_history;
    const { state: { bars, name } } = this.state;
    const nameClass = missedAppointment
                      ? 'alert' //'final-state'
                      : appointmentCancelled
                        ? 'alert'
                        : (bars === 0 && !is_history
                          ? 'initial-state'
                          : (bars === 0 && is_history)
                            ? ''
                            : (bars === 5
                              ? 'final-state'
                              : ''));

    if(this.state.small){
      return(this.renderSmallBar(nameClass, name));
    }
    else{
      return(this.renderBigBar(nameClass, name));
    }
  }
}

export default ProgressBar