import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// React Imports
import React from 'react';
import ReactDOM from 'react-dom';
//import ReactGA from 'react-ga';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
// Main Container Class
import Modal from "react-modal";
import App from './containers/App/App.jsx';
// Assets Imports
import './assets/css/index.scss';
import './assets/css/app.style.scss';
import './assets/fonts/custom.font.css';
import 'font-awesome/css/font-awesome.min.css';

//ReactGA.initialize('UA-188439054', { debug: false });
//ReactGA.pageview(window.location.pathname + window.location.search);

/**
 * ReactDOM Starter
 */

Modal.setAppElement("#root");
ReactDOM.render((
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route path='/' name="app" component={App}/>
    </Switch>
    </BrowserRouter >
), document.getElementById('root'));
