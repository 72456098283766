import React from "react";
import Helper from "../../core/helper/helper";
import { GENERAL } from "../../assets/images/images";

class LoginModal extends React.Component {

    url = Helper.getLiteRegisterUrl(true);

    render() {
        const iframeStyle = {
            width: '100%',
            height: '100%',
            border: 'none',
        };

        const containerStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // semi-transparent background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000, // Ensure it's on top of other elements
        };

        const iframeContainerStyle = {
            width: '90%',
            height: '90%',
            backgroundColor: '#fff',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        };

        return (
            <div style={containerStyle}>
                <div style={iframeContainerStyle}>
                    <div className='modal-header' style={{ position: 'relative' }}>
                        <div onClick={this.props.onClose}>
                            <img src={GENERAL.closeIcon} className="icon-close" alt="" />
                        </div>
                    </div>
                    <iframe
                        src={this.url}
                        style={iframeStyle}
                        title="Login"
                    ></iframe>
                </div>
            </div>
        );
    }
}
export default LoginModal;  