import React, { useState, useEffect, Fragment } from "react";
//import ScheduleServiceCard from "../ScheduleServiceCard";
import { HelperSchedule } from "../../core/helpers/helperSchedule";
//import SelectComponent from "../selectComponent/SelectComponent";
import SymptomRow from "../listComponent/symptom_row";
// Style
import '../listComponent/symptoms.style.scss';

const cLabels = {
  description: "Selecione os serviços que necessita",
  descriptionWithExperts: "Selecione o serviço pretendido",
  observations: "Deixe-nos os seus comentários/observações:",
  placeholderObservations: "Serviços aqui adicionados não serão considerados para efeitos de agendamento",
  noServicesObs: "Deixe-nos os serviços que pretende:",
  noServices: "Sem serviços disponíveis para selecionar",
  loading: "A carregar os serviços disponíveis...",
  selectSpecialist: "Escolha um especialista:",
  placeholderSpecialist: "- Qualquer especialista -"
};

/** @typedef scheduleData */

/**
 * @param {Object} props
 * @param {{id: string, name: string, active: boolean}} props.services
 * @param {scheduleData} props.scheduleData
 * @param {Function} props.onSelectedServices
 * @param {Function} props.onNewObservations
 */
const Step1Schedule = (props) => {
  const [stepLabel] = useState(`Passo ${props.step}: `);
  const [services, setServices] = useState(props.scheduleData?.selectedServices);
  const [observations, setObservations] = useState(props.scheduleData?.serviceObservations);
  const [favoriteExperts, setFavoriteExperts] = useState(props.scheduleData?.itemDetails?.users);
  const [expertSelected, setExpertSelected] = useState(props.scheduleData?.expertSelected);
  const [expertsAvailable, setExpertsAvailable] = useState(undefined);

  useEffect(() => {
    setFavoriteExperts(props.scheduleData?.itemDetails?.users);
    // eslint-disable-next-line
  }, [props.scheduleData.itemDetails?.users]);

  useEffect(() => {
    setExpertSelected(props.scheduleData?.expertSelected);
  }, [props.scheduleData.expertSelected]);


  useEffect(() => {
    setObservations(props.scheduleData?.serviceObservations);
  }, [props.scheduleData]);

  useEffect(() => {
    if (props.services) {
      let updatedServices = HelperSchedule.updatedServicesList(props.services, props.scheduleData?.selectedServices);
      //updatedServices = HelperSchedule.disableOtherServicesIfExpertSelected(updatedServices);
      setServices(updatedServices);

      const available = calculateExperts(updatedServices);
      if (available) {
        setExpertsAvailable(available);
        if (props.considerFavoriteExpert) {
          props.onDisableFavoriteExpert();
          checkForFavorite(available);
        }
      }

    }
    // eslint-disable-next-line
  }, [props.scheduleData, props.services, props.scheduleData.expertSelected]);

  const handleSelectedSymptoms = (service) => {
    const selectedServices = services.map((s) => {
      if (s.id === service.id) {
        return { ...s, active: !s.active };
      } else {
        // Other services' active status depends on the conditions
        if (service.users && service.users.length > 0) {
          // If the selected service has users, all others become inactive
          return { ...s, active: false };
        } else {
          // If the selected service doesn't have users, only deactivate services that have users
          return { ...s, active: s.users && s.users.length > 0 ? false : s.active };
        }
      }
    });

    /** @param { {id: string, name: string, active: boolean}} selectedServices */
    props.onSelectedServices(selectedServices);
    props.onSelectedExpert(null);
    setExpertSelected(null);
  };

  const handleSymptomObsChange = (service, value) => {
    const updatedServices = services.map(s =>
      s.id === service.id ? { ...s, obs_value: value } : s
    );
    /** @param { {id: string, name: string, active: boolean}} selectedServices */
    props.onSelectedServices(updatedServices);
  };

  const handleObsChange = (event) => {
    setObservations(event.target.value);
    props.onNewObservations(event.target.value)
  }

  const renderNoServicesAvailable = () => {
    if (!services) {
      return (
        <div className="d-flex justify-content-center mt-5">
        </div>
      );
    }
    if (services?.length) {
      return <Fragment />;
    }
    return (
      <div className="d-flex justify-content-center mt-5">
        <p className="normal-light-black mx-auto">{cLabels.noServices}</p>
      </div>
    );
  };
/*
  const renderSymptomsListOld = () => {
    if (!services?.length) {
      return <Fragment />;
    }
    return services?.map((s, index) => {
      return (
        <ScheduleServiceCard service={s} onClick={() => handleSelectedSymptoms(s)} key={index} />
      );
    });
  };
*/
  const renderSymptomsList = () => {
    if (!services?.length) {
      return <Fragment />;
    }
    return (
      <div className="symptoms-section-container mt-4 mb-3" id="symptoms-section-container">
        <div className='symptoms-main-container'>
          {services?.map((s, index) => {
            return (
              <SymptomRow service={s} onSymptomObsChange={handleSymptomObsChange} onClick={() => handleSelectedSymptoms(s)} key={index} idx={index}
              options={expertsAvailable} // Assuming expertsAvailable applies to all rows, adjust if per-row basis is needed
              selectedOption={expertSelected} // If this is per-row, you'll need to adjust your state to handle this
              onExpertChange={handleExpertChange} // Adjust this handler to be applicable per row, if necessary
              />
            );
          }
          )}
        </div>
      </div>
    );
  };

  /*
  const renderSymptomsObservations = () => {
    if (!services || !services.some(s => s.active && s.obs_active)) {
      return <Fragment />;
    }
  
    return (
      <Fragment>
        {services.map((service, index) => {
          if (service.active && service.obs_active) {
            return (
              <div key={index} className="observations-container mt-2">
                <div className="observations-symptoms-label normal-regular-black">
                  {service.obs_label}
                </div>
                <input
                  type="text"
                  maxLength="255"
                  defaultValue={service.obs_value || ''}
                  onChange={(e) => handleSymptomObsChange(service, e.target.value)}
                  className={`km-text-area smallwide`}
                  placeholder=""
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </Fragment>
    );
  };
*/
  const renderObservations = () => {
    if ((services?.length && !services.some(s => s.active)) || !services) {
      return <Fragment />;
    }

    return (
      <Fragment>
        <div className="observations-label normal-regular-black">
          {services?.length ? cLabels.observations : cLabels.noServicesObs}
        </div>
        <div className="observations-container mt-2">
          <textarea
            defaultValue={observations}
            maxLength="2000"
            onChange={handleObsChange}
            rows={4}
            className={`km-text-area medium noresize`}
            placeholder={cLabels.placeholderObservations}
          />
        </div>
      </Fragment>
    );
  };

  const handleExpertChange = (selectedOption) => {
    if (selectedOption && selectedOption.value !== 'clear_selection') {
      // Set expertSelected to the selected option's corresponding user object
      props.onSelectedExpert({
        id: selectedOption.value,
        name: selectedOption.label
      });
    } else {
      // Clear the selection when the clear option is chosen
      props.onSelectedExpert(null);
    }
  };

  const calculateExperts = (updatedServices) => {
    if (!updatedServices || !updatedServices.length ||
      !updatedServices.some(s => s.active && !s.disabled && s.users && s.users.length > 0)) {
      return null;
    }

    const expertMap = new Map();

    updatedServices.forEach(service => {
      if (service.active) {
        service.users.forEach(user => {
          if (!expertMap.has(user.id)) {
            expertMap.set(user.id, { value: user.id, label: user.name });
          }
        });
      }
    });

    const experts = Array.from(expertMap.values());
    experts.unshift({ value: 'clear_selection', label: cLabels.placeholderSpecialist }); // Add at the start of the list
    return experts;
  }
    
  const checkForFavorite = (availableExperts) => {
    if (!expertSelected && favoriteExperts) {
      const firstMatch = favoriteExperts.find(favorite =>
        availableExperts.some(expert => expert.value.toString() === favorite.toString())
      );
      if (firstMatch) {
        const matchedExpert = availableExperts.find(expert => expert.value.toString() === firstMatch.toString());
        if (matchedExpert) {
          props.onSelectedExpert({
            id: matchedExpert.value,
            name: matchedExpert.label
          });
        }
      }
    }
  }
  
  /*
  const renderExpert = () => {
    if (!services || !services.length ||
      !services.some(s => s.active && !s.disabled && s.users && s.users.length > 0)) {
      return <Fragment />;
    }
    return (
      <React.Fragment>
        <div>
          <SelectComponent
            selectedOption={expertSelected ? { value: expertSelected.id, label: expertSelected.name } : null}
            onChange={handleExpertChange}
            options={expertsAvailable}
            config={{
              isMulti: false,
              isClearable: true,
              autoFocus: false,
              isSearchable: true
            }}
            label={cLabels.selectSpecialist}
            placeholder={cLabels.placeholderSpecialist} />
        </div>
      </React.Fragment>
    );
  }
  */

  return (
    <div className="step-action-container mt-4">
      <div className="step-description-container">
        <div className="step-label me-1"> {stepLabel} </div>
        <div className="step-description"> {HelperSchedule.symptomsWithExperts(services)? cLabels.descriptionWithExperts : cLabels.description} </div>
      </div>
      {renderNoServicesAvailable()}
      <div className="step-action-content mt-2">
        {renderSymptomsList()}
      </div>
      <div className="step-action-observations-container mt-3">
        {renderObservations()}
      </div>
    </div>
  );
};

export default Step1Schedule;
