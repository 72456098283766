import React from 'react';
import Helper from '../../core/helper/helper';
import Labels from '../../variables/labels';
import {linkKeymasterAnalytics} from '../../core/services/analytics.service';

const PoweredBy = (props) => {
  const { styleClass } = props;

  const renderText = () => {
    if (Helper.isIOSWebview()) {
      return (
       <span> Powered by <span className='product-name'> K<span className='special-character'>e</span>ymaster </span> </span>
      );
    }

    return (<span>{Labels.poweredByText}</span>);
  }

  if (Helper.isIOSWebview()) {
    return (
      <div className={`app-logo-text m-0 ${styleClass ? styleClass : ''}`}
        onClick={linkKeymasterAnalytics} role={'button'}>
          {renderText()}
      </div>
    );
  }

  return (
    <a className={`app-logo-text m-0 ${styleClass ? styleClass : ''}`}
      href="https://www.keymaster.pt/" onClick={linkKeymasterAnalytics}
      target={"_blanK"} rel="noopener noreferrer" role={'button'}>
        {renderText()} 
        <span className='product-name'> www.K<span className='special-character'>e</span>ymaster.pt </span>
    </a>
  );
}

export default PoweredBy;
