import React, { Fragment } from "react";
import CompanyEmailInfo from "./CompanyEmailInfo";
import CompanyWebsiteInfo from "./CompanyWebsiteInfo";
import { GENERAL } from "../../../../assets/images/images";

/**
 * @param {Object} props - The props object.
 * @param {Company} props.company - The company object (or undefined).
 */

const CompanyWebLinkInfo = (props) => {
  const { company, isDark } = props;

  if (!company?.website && !company?.email) {
    return <Fragment />;
  }

  return (
    <div className="company-info d-inline-flex">
      <div className="info-icon me-2">
        <img
          src={GENERAL.iconWebsite}
          alt="website-icon"
          className={isDark ? 'filter-white' : ''}
        />
      </div>

      <CompanyWebsiteInfo company={company} />
      <CompanyEmailInfo company={company} />
    </div>
  );
};

export default CompanyWebLinkInfo;
