// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
// Components Imports
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { preUserSignUp } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import LocalData from '../../core/localData';
import Helper from '../../core/helper/helper';
import Cookies from 'universal-cookie';
import LinkRecoveryPassword from '../../components/button/LinkRecoveryPassword';

class PreSignUp extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
//    ReactGA.pageview(window.location.pathname + window.location.search);
    const email = this.props.location?.state?.email || '';
    this.setState({ email });
  }

  /**
  * Extrai o valor do campo do email
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }
  /**
  * Extrai o valor do campo da password
  * 
  * @param {event} event - callback do evento OnChange()
  */
  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  /**
   *  Função OnClick do formulário para realização de login após de confirmação do 
   *  email, se o login tiver sucesso o utilizador será redirecionado para a pagina
   *  registo de dados.
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */

  handleSubmit = (event) => {
    event.preventDefault();

    let body = {
      email: this.state.email,
      password: this.state.password,
    }

    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }

    preUserSignUp(body).then(response => {
      if (response) {
        if (response.code) {
          if (response.code === 409) {
            if (response.error_code === 2) {
              LocalData.userRegistered = false;
              this.props.history.push(`/dados-cliente`);
            }
            this.renderInfoModal('E-mail já Associado', response.message);
          } else if (response.code === 404) {
            if (response.error_code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, response.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          if (response.type === 'BasicClientDetails') {
            if (response.user_id) {
              this.cookies.set('userId', response.user_id, Helper.cookiesLiteConfig);
            }
            if (response.work_token) {
              this.cookies.set('liteToken', response.work_token, Helper.cookiesLiteConfig);
            }
            LocalData.userData = response;
            LocalData.userRegistered = false;
            this.props.history.push('/dados-cliente');
          } else {
            this.cookies.set('sessionToken', response.token, Helper.cookiesConfig);
            this.cookies.remove('userId', Helper.cookiesLiteConfig);
            this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
            LocalData.userData = response;
            LocalData.userRegistered = true;
            window.location.href = `${process.env.PUBLIC_URL}/services`;
          }
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <div className='grey-box-container mt-5'>
        <h3 className='text-center w-100 mt-3'> {Labels.login.header}</h3>
        <div className='box-form-container mt-4'>
          <form onSubmit={this.handleSubmit}>
            <InputField
              styleInput="input-medium-default"
              type='email' title='Utilizador'
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              value={this.state.email}
              placeholder='exemplo@mail.com'
              onChange={this.handleChangeEmail}
              required={true} />
            <InputField
              styleInput="input-medium-default password"
              type='password' title='Password'
              linkTo='recuperar-password'
              value={this.state.password}
              onChange={this.handleChangePassword}
              required={true}
            />
            <LinkRecoveryPassword email={this.state.email}/>
            <Button className='cta-button-primary d-medium w-100 mt-4' type='submit' value='Entrar' />
          </form>
        </div>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default withRouter(PreSignUp);