import React from "react";
import Cookies from "universal-cookie";
import LocalData from "../localData";
import Emitter from "../services";
import { readNotification } from "../../api/routes/api.route";

class NotificationsService extends React.Component {
  static cookies = new Cookies();

  static read = (id) => {
    readNotification(this.cookies.get("sessionToken"), id).then(
			(response) => {
				if (response) {
					if (!response.code && !response.http_code) {
            let newData = [];
            if (LocalData.notificationsData.notificationsList?.length) {
              newData = LocalData.notificationsData.notificationsList;
            }
						newData = newData?.map((n) => {
							return n.id === Number(id)
								? Object.assign({}, n, { read: true })
								: n;
						});
						LocalData.notificationsData["notificationsList"] = newData;
						Emitter.emit("NOTIFICATIONS_UPDATE", newData);
					}
				}
			}
		);
  };
}

export default NotificationsService;