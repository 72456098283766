
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import Labels from '../../variables/labels';
import Loader from '../loader/loader';

const WelcomeLogo = () => {
  const [animationData, setAnimationData] = useState();
  const [showAnimation, setShowAnimation] = useState();
  const [shouldPlay, setPlay] = useState(true);

  useEffect(() => {
    import('assets/images/animations/welcome-logo-animation.json').then(setAnimationData);
  }, []);

  setTimeout(() => {
    setShowAnimation(true);
  }, 1500);

  if (!animationData) return <Loader />;

  const renderLottieAnimation = () => {
    if (showAnimation) {
      setTimeout(() => {
        setPlay(false);
      }, 925);
      return (
        <Lottie
          animationData={animationData}
          style={{ width: 200, height: 50 }}
          play={shouldPlay}
        />
      );
    }
    return <div style={{ height: 50 }}></div>;
  };
  return (
    <div className="d-flex w-100 vh-100 align-center justify-content-center flex-column">
      <div className="lottie-container w-100 d-flex justify-content-center">
        {renderLottieAnimation()}
      </div>
      <div className="w-100 text-center normal-regular-black">
        <p className='m-0 normal-regular-black'>{Labels.liteWelcomeMessage.text1}</p>
        <p className='normal-regular-black'>{Labels.liteWelcomeMessage.text2}</p>
        {shouldPlay}
      </div>
    </div>
  );
}
export default WelcomeLogo;