import { parse, differenceInDays } from 'date-fns';

export default class AgeCalculator {
  static calculateYears(birthDate) {
    if (!birthDate) return;
    const today = new Date();
    const date =
      typeof birthDate === 'string'
        ? parse(birthDate, 'dd/MM/yyyy', new Date())
        : birthDate;

    let years = today.getFullYear() - date.getFullYear();

    if (
      today.getMonth() < date.getMonth() ||
      (today.getMonth() === date.getMonth() && today.getDate() < date.getDate())
    ) {
      years--;
    }

    return years;
  }

  static calculateMonths(birthDate) {
    if (!birthDate) return;
    const daysInMonth = 30.44;
    const today = new Date();
    const date =
      typeof birthDate === 'string'
        ? parse(birthDate, 'dd/MM/yyyy', new Date())
        : birthDate;
        
    let days = differenceInDays(today, date);

    return Math.floor(days / daysInMonth);
  }

  static calculateDays(birthDate) {
    if (!birthDate) return;
    const today = new Date();
    const date =
      typeof birthDate === 'string'
        ? parse(birthDate, 'dd/MM/yyyy', new Date())
        : birthDate;

    let days = differenceInDays(today, date);

    if (today.getDate() < date.getDate()) {
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, date.getDate());
      days = differenceInDays(today, lastMonth);
    }

    return days;
  }
}
