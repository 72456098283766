import React, { Component } from "react";
import { getMonth, getYear, parse } from "date-fns";

// Components
import DatePicker from "react-datepicker";
import InputField from "../inputField/inputField";
import customLocale from "./customLocale";

// Styling
import "react-datepicker/dist/react-datepicker.css"; 
import "./DatePicker.scss";

// Assets
import { GENERAL } from "../../assets/images/images";
import LocalData from "../../core/localData";
import Helper from "../../core/helper/helper";

class InputDatePicker extends Component {
  constructor(props) {
    super(props);

    this.refDatePicker = React.createRef();

    this.state = {
      value: props.value ? parse(props.value, 'dd/MM/yyyy', new Date()) : undefined,
      renderKey: 0
    };

    this.years = LocalData.datePickerYears(props.type === "birthday");
    this.months = LocalData.datePickerMonths;
  }

  // Function to trigger the re-render
  forceInputFieldRerender = () => {
    this.setState((prevState) => ({ renderKey: prevState.renderKey + 1 }));
  };

  renderCustomInput = (value) => {
    const { type } = this.props
    const customValue = Helper.buildAgeInfo(value, type);
    return (
      <InputField
        onClick={this.props.onClick}
        styleInput="input-small-default m-0"
        styleClass="m-0"
        type="text"
        title=""
        customValue={customValue}
        sideButton={true}
        icon={GENERAL.calendarEdit}
        forceRerender={this.forceInputFieldRerender}
        key={this.state.renderKey}
      />
    );
  };

  renderCustomHeader = ({
    decreaseMonth,
    prevMonthButtonDisabled,
    date,
    changeYear,
    changeMonth,
    increaseMonth,
    nextMonthButtonDisabled,
  }) => (
    <div
      style={{
        margin: 0,
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div
        className={`calendar-arrow-left ${prevMonthButtonDisabled ? 'disabled' : ''}`}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        role="button"
      >
        <img src={GENERAL.arrowLeft} alt="" />
      </div>
      

      <select
        role="button"
        value={this.months[getMonth(date)]}
        onChange={({ target: { value } }) =>
          changeMonth(this.months.indexOf(value))
        }
      >
        {this.months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        role="button"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {this.years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      {/* Next Month Arrow */}
      <div
        className={`calendar-arrow-right ${nextMonthButtonDisabled ? 'disabled': ''}`}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        role="button"
      >
        <img src={GENERAL.arrowRight} alt="" />
      </div>
    </div>
  );

  handleChangeDate = (value) => {
    this.setState({ value }, () => this.forceInputFieldRerender());
  };

  render() {
    const { value } = this.state;

    return (
      <DatePicker
        ref={this.refDatePicker}
        renderCustomHeader={this.renderCustomHeader}
        selected={value}
        onChange={this.handleChangeDate}
        customInput={this.renderCustomInput(value)}
        maxDate={this.props.type === "birthday" ? new Date() : undefined}
        dateFormat="dd/MM/yyyy"
        locale={customLocale}
        formatWeekDay={nameOfDay => nameOfDay.substring(0,1).toUpperCase()}
      />
    );
  }
}
export default InputDatePicker;
