import React from 'react';
import './CategoryCard.scss';
import { ASSETS } from '../../../assets/images/images';
import { CategoryCardPropTypes } from '../../../core/models/props-interface.d';

/**
 * @param {Object} props
 * @param {Category} props.category - The category object.
 * @param {Function} props.onClick - A function to handle the click event.
 */
const CategoryCard = (props) => {
  const { category } = props;
  return (
    <div className="category-card align-center" onClick={props.onClick} role="button">
      <div className="category-icon">
        <img src={ASSETS.vertical.icon(category?.icon)} alt="category-icon" />
      </div>
      <div className="category-name mt-1">{category?.name}</div>
    </div>
  );  
}

CategoryCard.propTypes = CategoryCardPropTypes;
export default CategoryCard;
