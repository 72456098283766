// React Imports
import React from 'react';
// Style
import 'components/sidebar/toast/toast.style.scss';

class Toast extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        message: 'Messagem Dummy',
        hidden: true,
        type: 'sucess',
    }
  }

  /**
  * Activa o systema de notificaçãos do menu 
  *
  * @param {string} text - mesagem de apresentação do toast
  * @param {string} type - tipo de toast a apresentar
  */
  triggerToast = (text, type) => {
    this.setState({message: text, hidden: false, type: type, fadeout: 'v1-in'});
      setTimeout(() => {
        this.setState({message: '', hidden: true, type: '', fadeout: 'v1-out'});
      }, 3000);
  }

  render() {
    return (
        <div className="toast-container">
            <div className={"toast " + this.state.type} hidden={this.state.hidden}>
                <p className="toast-text">{this.state.message}</p>
            </div>
        </div>
    );
  }
}
export default Toast;
