import React, { useCallback, useEffect, useState } from "react";
import { GENERAL } from "../../assets/images/images";
import Button from "../button/button";
import { getCompanyIAgreeData } from "../../api/routes/api.route";
import Emitter from "../../core/services";
import '../../assets/css/iagree.scss'

const colorBlack = "#22272a";
const IAgree = ({onChange, textColor = colorBlack, company}) => {

  const [iAgree, setIAgree] = useState(false);
  const [companyData, setCompanyData] = useState();

  const [modalIAgreeConfirmText, setModalIAgreeConfirmText] = useState("");
  const [replaceCompanyName, setReplaceCompanyName] = useState("");
  const [replaceVat, setReplaceVat] = useState("");
  const [replaceClient, setReplaceClient] = useState("");
  const [replaceItem, setReplaceItem] = useState("");
  const [replaceWork, setReplaceWork] = useState("");
  const [replaceCompanies] = useState("");
  const [companyTerms, setCompanyTerms] = useState("");

  useEffect(() => {
    setModalIAgreeConfirmText("Continuar");
  }, []);

  useEffect(() => {
    setCompanyData(company)
  }, [company]);

  const setCompanyIAgreeData = useCallback((domain) => {
    if (!domain) return;
    getCompanyIAgreeData(domain.replace('.qa', '')).then((res) => {
      if (res) {
        if (res.client && res.client.length > 0) {
          const clientString = res.client.join(', ');
          setReplaceClient(clientString);
        }
        if (res.item && res.item.length > 0) {
          let itemString = res.item.join(', ');
          if (res.client) itemString = ', ' + itemString;
          setReplaceItem(itemString);
        }
        if (res.work && res.work.length > 0) {
          let workString = res.work.join(', ');
          if (res.item || res.client) workString = ', ' + workString;
          setReplaceWork(workString);
        }
        if (res.vat) {
          setReplaceVat(res.vat);
        }
        if (res.name) {
          setReplaceCompanyName(res.name);
        }
        if (res.terms) {
          setCompanyTerms(res.terms);
        }
      }
    }).catch((error) => {
      console.error("Error fetching company data: ", error.message);
    });
  }, [setReplaceClient, setReplaceItem, setReplaceWork, setReplaceVat, setReplaceCompanyName]);

  useEffect(() => {
    if (companyData?.domain) setCompanyIAgreeData(companyData?.domain);
  }, [setCompanyIAgreeData, companyData]);

  const iAgreeModal = () => {
    const details = {
      type:'iagree',
      confirmText: modalIAgreeConfirmText,
      replaceCompanyName,
      replaceVat,
      replaceClient,
      replaceItem,
      replaceWork,
      replaceCompanies,
      companyTerms
    };
    Emitter.emit('TOP_MODAL', details);
  }

  const handleCheckboxChange = (event) => {
    setIAgree(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <>
      <div className="input-label mt-4 i-agree-container justify-content-center">
        <label htmlFor="agreeCheckbox" className="i-agree-label">
          <input
            type="checkbox"
            checked={iAgree}
            onChange={handleCheckboxChange}
            id="agreeCheckbox"
            className="agree-checkbox"
            style={{ display: iAgree ? 'none' : 'inline-block' }}  // Hide the default checkbox
          />
          <span
            className="custom-checkbox"
            style={{
              width: '20px',
              height: '20px',
              display: iAgree ? 'inline-block' : 'none',
              backgroundImage: iAgree ? `url(${GENERAL.iconSquareChecked})` : `url(${GENERAL.iconSquareUnchecked})`,
              backgroundSize: 'cover',
              marginRight: '0px',
              marginLeft: '-6px',
              cursor: 'pointer',
            }}
          />
          <div className="d-flex align-items-end">  
              <span style={{ 
                  paddingRight: '0px', 
                  paddingLeft: '8px', 
                  cursor: 'pointer', 
                  fontSize: '15px',
                  color: textColor
              }}>Li e concordo </span>
              <span style={{ 
                  paddingRight: '4px', 
                  paddingLeft: '3px', 
                  cursor: 'pointer', 
                  fontSize: '15px',
                  color: textColor
              }}> com a</span>
          </div>
        </label>
        <div className="i-agree-link">
          <Button
            type="link"
            onClick={() => iAgreeModal(false, true)}
            className='link-container'
            //textClass="small light"
            valueText={companyTerms? "Política de Privacidade e Condições Gerais" : "Política de Privacidade"}
          />
        </div>
      </div>
    </>
  );
};

export default IAgree;