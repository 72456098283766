import React from 'react';
import { GENERAL } from '../../assets/images/images';
import LocalData from '../../core/localData';

const PhoneCallButton = () => {
  return !LocalData.configData?.whatsapp_number ? null : (
    <a className="whatsapp-button-container" role={'button'}
      href={`tel:${LocalData.configData?.whatsapp_number}`} target='_blank' rel="noopener noreferrer">
      <img src={GENERAL.phonecall} alt="phonecall-button" />
    </a>
  );
}
export default PhoneCallButton;