import React, { Fragment } from "react";
import LinkButton from "../../../button/LinkButton/LinkButton";

/**
 * @param {Object} props - The props object.
 * @param {Company} props.company - The company object (or undefined).
 */
const CompanyWebsiteInfo = (props) => {
  const { company } = props;

  if (!company?.website) {
    return <Fragment />;
  }

  const renderLinkButton = () => {
    return (
      <LinkButton
        onClick={() => {}}
        styleClass="link-container"
        textClass="small"
        valueLink={company?.website}
        valueText={company?.website}
      />
    );
  };

  return <div className="info-text">{renderLinkButton()}</div>;
}

export default CompanyWebsiteInfo;