import React from 'react';
import DocumentList from '../documentList/documentList.jsx';
import Gallery from '../gallery/gallery.jsx';
import GridTabs from '../list/grid/gridTabs.jsx';

class MediaFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialStateFromProps(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.files !== this.props.data.files || prevProps.data.state !== this.props.data.state) {
      const newState = this.getInitialStateFromProps(this.props);
      this.setState({
        actionCloseGallery: newState.actionCloseGallery,
        onCreateAppointment: newState.onCreateAppointment,
        media: newState.media,
        documents: newState.documents,
        serviceState: newState.serviceState,
        // Preserve the selectedTab state
        //selectedTab: this.state.selectedTab
        selectedTab: newState.selectedTab

      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if the relevant props or state have changed
    if (
      nextProps.data.files !== this.props.data.files ||
      nextProps.data.state !== this.props.data.state ||
      nextState.selectedTab !== this.state.selectedTab ||
      nextState.media !== this.state.media ||
      nextState.documents !== this.state.documents ||
      nextState.selectedTab !== this.props.selectedTab
    ) {
      return true;
    }
    return false;
  }

  getInitialStateFromProps = (props) => {
    const { files, state } = props.data;
    const { onCreateAppointment, actionCloseGallery } = props;

    const customerDocumentsLength = files?.customer?.documents?.length || 0;
    const providerDocumentsLength = files?.provider?.documents?.length || 0;
    const totalDocumentsLength = customerDocumentsLength + providerDocumentsLength;

    const customerMediaLength = files?.customer?.media?.length || 0;
    const providerMediaLength = files?.provider?.media?.length || 0;
    const totalMediaLength = customerMediaLength + providerMediaLength;

    let selectedTab = this.state?.selectedTab ?? 0;
    if(this.props.selectedTab != null) {
      selectedTab=this.props.selectedTab;
    }
    if (totalMediaLength===0 && totalDocumentsLength!==0) selectedTab=1;
    if (totalMediaLength!==0 && totalDocumentsLength===0) selectedTab=0;

    return {
      actionCloseGallery,
      onCreateAppointment,
      selectedTab,
      media: {
        customer: files?.customer?.media,
        provider: files?.provider?.media
      },
      documents: {
        customer: files?.customer?.documents,
        provider: files?.provider?.documents
      },
      serviceState: state
    };
  };

  renderTabContent = (media, documents, selectedTab) => {
    const { serviceState, onCreateAppointment, actionCloseGallery} = this.state;
    if (selectedTab === 0) {
      return <Gallery data={{media, serviceState}} newMediaFileLoaded={this.props.newMediaFileLoaded}
        onCreateAppointment={onCreateAppointment}
        onDeleteMediaFile={this.props.onDeleteMediaFile}
        actionCloseGallery={actionCloseGallery}/>
    } else {
      return <DocumentList data={{documents, serviceState}}
        onCreateAppointment={onCreateAppointment}
        newMediaFileLoaded={this.props.newMediaFileLoaded} onDeleteMediaFile={this.props.onDeleteMediaFile}/>
    }
  }

  selectTab = (tab) => {
    if (this.props.onSelectedTabChange) {
      this.props.onSelectedTabChange(tab);
    }
    this.setState({ selectedTab: tab });
  }
  render() {
    const { media, documents, selectedTab } = this.state;
    const { styleClass } = this.props;
    return (
      <div className={`w-100 mb-0 pt-0 pb-3 ${styleClass ? styleClass : ''}`} >
        <GridTabs selectedTab={selectedTab} data={{ media, documents }} callback={this.selectTab}/>
        {this.renderTabContent(media, documents, selectedTab)}
      </div>
    );
  }
}

export default MediaFiles;