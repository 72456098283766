import React from 'react';
import Helper from '../../core/helper/helper';
import { GENERAL } from '../../assets/images/images';

function Logo(props) {
  const { styleClass } = props;
  if (!Helper.companyLogo() || Helper.companyLogo() === 'unknown') {
    if (Helper.isDemo()) {
      return (<img className="company-logo flex-center" src={GENERAL.defaultCompanyLogo} alt="logo" />);
    }
    else {
      return (
        <div className={`company-name ${styleClass ? styleClass : ''}`}>
          {Helper.companyName() || ''}
        </div>
      );
    }
  }
  return (<img className="company-logo fle-center" src={Helper.companyLogo()} alt="logo" />);
}


export default Logo;