import React from 'react';
import Labels from '../../variables/labels';

const Loader = (props) => {
  const { message, inverted, local, big, small, height } = props;
  return (
    <div style={{ minHeight: height || "100px" }}>
      <div
        className={`ui active dimmer ${inverted ? "inverted" : ""} ${local ? "local" : ""} ${
          small ? "small" : ""
        }`}
        style={{ minHeight: height || "100px" }}
      >
        <div className={`ui text loader ${big ? "big" : ""}`}>{message}</div>
      </div>
    </div>
  );
}

Loader.defaultProps = {
  message: Labels.loaderMessage,
  inverted: true,
  big: true,
  local: false,
}
export default Loader;