import React from 'react';
import { GENERAL } from '../../assets/images/images';
import Helper from '../../core/helper/helper';

const PlayStoreButton = () => {

  if (Helper.hidePlayStore()) {
    return <React.Fragment />;
  }

  return(
    <a className="playstore-button-container" role={'button'}
      href={Helper.playstoreLink} target='_blank' rel="noopener noreferrer">
      <img src={GENERAL.playstore} alt="playstore-button" />
    </a>
  );
}
export default PlayStoreButton;