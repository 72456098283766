/* Keymaster Customer Self-Service Models class. */

const CONFIG_DATA = {
  vertical: 'AUTO',
  icons: 'AUTO',
  app_name: "Keymaster Customer",
  company_name: "ClickStop®",
  logo: 'https://loremflickr.com/320/240',
  whatsapp_number: '+351912345678',
  item_fields_config: [
    {
      type: 'inputselect',
      name: 'equipamento',
      label: 'Item',
      length: 255,
      position: 1,
      required: false,
      identifier: false,
      values: [
        'All in One',
        'Desktop',
        'Disco Externo',
        'Impressora',
        'Portátil',
        'Tablet',
        'Telemóvel'
      ]
    },
    {
      type: 'input',
      name: 'marca',
      label: 'Marca',
      length: 255,
      position: 2,
      required: false,
      identifier: false,
      values: [
        'Apple',
        'ASUS',
      ]
    },
    {
      type: 'input',
      name: 'modelo',
      label: 'Modelo',
      length: 255,
      position: 3,
      required: false,
      identifier: false,
      values: []
    },
    {
      type: 'input',
      name: 'sn',
      label: 'SN',
      length: 255,
      position: 4,
      required: false,
      identifier: false,
      values: []
    },
    {
      type: 'input',
      name: 'cpu',
      label: 'CPU',
      length: 255,
      position: 5,
      required: false,
      identifier: false,
      values: []
    },
    {
      type: 'input',
      name: 'ram',
      label: 'RAM',
      length: 255,
      position: 6,
      required: false,
      identifier: false,
      values: []
    },
    {
      type: 'input',
      name: 'so',
      label: 'SO',
      length: 255,
      position: 7,
      required: false,
      identifier: false,
      values: []
    }
  ]
}

// description: String
const SYMPTOM = {
  description: 'Just a Symptom description',
};

// id: Long format: int64
// description: String format: string
const STATE = {
  id: 76543,
  description: 'Just a State description',
};

// id: Long format: int64
const CLIENT_DETAILS = {
  id: 1,
};

// number (optional)
const CLIENT_PHONE = {
  number: 987654321,
};

// code: Integer format: int32
// message: String
const ERROR = {
  code: 200,
  message: 'Just an Error message',
};

// classification: Integer format: int32
// observation: String
const FEEDBACK = {
  classification: 10,
  observation: 'Just a Feedback observation',
};

// id: Long format: int64
// description (optional): String
// status (optional): String
// price (optional): BigDecimal
const ITEM = {
  id: 1,
  description: 'Just an Item decription',
  status: 'Just an Item status',
  price: 100000,
};

// code: String
const LICENSE_PLATE = {
  code: 'cod1234',
};

// name: String
// address (optional): String
// vat: String
// phones (optional): array[ClientPhone]
const NEW_CLIENT_DETAILS = {
  name: 'Just a Client name',
  address: 'Just a Client address',
  vat: 'Just a Client VAT',
  phones: [
    CLIENT_PHONE,
    { number: 123456789 },
  ],
};

// description: String
const NEW_SYMPTOM = {
  description: 'Just a New Symptom description',
};

// licensePlate: String
const NEW_ITEM = {
  licensePlate: 'Just a Item license plate',
};

// id: Long format: int64
// symptoms (optional): array[Symptom]
// state (optional): State
// checkin (optional): String format: yyyyMMddTHHmmssxxxx
// checkout (optional): String format: yyyyMMddTHHmmssxxxx
const SERVICE = {
  id: 1,
  symptoms: [
    { description: 'Just a Symptom description' },
    SYMPTOM,
  ],
  state: STATE,
  checkin: '20181205T1005595646',
  checkout: '20181206T1225148765',
};

// items (optional): array[Item]
// photos (optional): array[String] format: string
const SERVICE_DETAILS = {
  items: [
    ITEM,
  ],
  photos: [
    'https://loremflickr.com/320/240',
    'https://loremflickr.com/320/240',
  ],
};

// begin: String
// symptomId (optional): Long format: int64
// observations (optional): String format: string
// itemId (optional): Long format: int64
// make (optional): String format: string
// brand (optional): String format: string
// serviceId (optional): Long format: int64
const SERVICE_INPUT = {
  begin: 'Just a Begin value',
  symptomId: 123456,
  observations: 'Just an Observation',
  itemId: 98765,
  make: 'Just a Make value',
  brand: 'Just a Brand value',
  serviceId: 1234567889,
};

// id: Long format: int64
// symptoms (optional): array[Symptom]
// state (optional): State
// checkin (optional): String format: yyyyMMddTHHmmssxxxx
// checkout (optional): String format: yyyyMMddTHHmmssxxxx
const SERVICE_WITH_ITEM = {
  id: 9876543221,
  symptoms: [
    SYMPTOM,
  ],
  state: STATE,
  checkin: '20181205T1005595646',
  checkout: '20181206T1225148765',
};

// begin: String
const SLOT = {
  begin: 'Just a Begin value',
};

// token: String
const TOKEN = {
  token: 'Just a Token',
};

// name: String
// address (optional): String
// vat: String
// phones (optional): array[ClientPhone]
const UPDATABLE_CLIENT_DETAILS = {
  name: 'Just an Updatable Client Details name',
  address: 'Just an Updatable Client Details address',
  vat: 'Just an Updatable Client Details vat',
  phones: [
    CLIENT_PHONE,
    { number: 123456789 },
  ],
};

// email: String
// password (optional): String
const USER_AUTH = {
  email: 'utilizador@mail.com',
  password: 'mySecretPassword!123',
};

// licensePlate: String
// id: Long format: int64
const ITEM_VEHICLE = {
  licensePlate: '12-XX-18',
  id: 876543219,
};

// id: Long format: int64
// photoUrl (optional): String format: string
// brand (optional): String format: string
// make (optional): String format: string
const ITEM_DETAILS = {
  id: 234567891,
  photoUrl: 'https://loremflickr.com/320/240',
  brand: 'Just a Item Details brand',
  make: 'Just a Item Details make',
};

// photoUrl (optional): String format: string
// brand (optional): String format: string
// make (optional): String format: string
const ITEM_DETAILS_WITH_SERVICES = {
  photoUrl: 'https://loremflickr.com/320/240',
  brand: 'Just a Item Details With Services brand',
  make: 'Just a Item Details With Services make',
};

// TODO: Ask Client what this model is?
const ITEM_DETAILS_WITHS_SERVICES_AND_SCRAPING = {
  // TODO: What is needed here? Ask client!
};

// key (optional): String format: string
// value (optional): String format: string
const ITEM_WEB_SCRAPING = {
  key: 'Just a Item Web Scraping key',
  value: 'Just a Item Web Scraping value',
};

// password: String
const BODY = {
  password: 'Just a Body password',
};

// email: String
const BODY_1 = {
  email: 'someones_email@domain.com',
};

// password (optional): String format: string
const BODY_2 = {
  password: 'Just a Body_2 password',
};

// image (optional): byte[] format: binary
const BODY_3 = {
  image: '',
};

module.exports = {
  CONFIG_DATA,
  CLIENT_DETAILS,
  CLIENT_PHONE,
  ERROR,
  FEEDBACK,
  ITEM,
  LICENSE_PLATE,
  NEW_CLIENT_DETAILS,
  NEW_SYMPTOM,
  NEW_ITEM,
  SERVICE,
  SERVICE_DETAILS,
  SERVICE_INPUT,
  SERVICE_WITH_ITEM,
  SLOT,
  STATE,
  SYMPTOM,
  TOKEN,
  UPDATABLE_CLIENT_DETAILS,
  USER_AUTH,
  ITEM_VEHICLE,
  ITEM_DETAILS,
  ITEM_DETAILS_WITH_SERVICES,
  ITEM_DETAILS_WITHS_SERVICES_AND_SCRAPING,
  ITEM_WEB_SCRAPING,
  BODY,
  BODY_1,
  BODY_2,
  BODY_3,
};
