// React Imports
import React from 'react';
// Components
import Button from 'components/button/button.jsx';
import InputField from 'components/inputField/inputField.jsx';
// API Imports

class ChangeDataSample extends React.Component {

  constructor(props){
    super(props);
    this.refArray = [];
    this.state = {
      title: this.props.title,
      data: this.props.data,
    };
  }

  _buildObject = () => {
    const DATA = [];
    this.refArray.forEach((prop) => {
      if (prop.state.value) {
        DATA.push({key: prop._reactInternalFiber.key, label: prop.props.name, value: prop.state.value});
      }
    });
    return DATA;
  }

  _changeItemData = () => {
    this.props.onClick(this._buildObject());
  } 

  render() {
    return (
      <div className='modal-area-container'>
        <div className='modal-title-text'>{this.state.title}</div>
        <div className='modal-content-container'>
          {
            this.state.data.map((prop, key) => {
              return (
                <div key={key} className='detail-AreaRow'>
                  <div className='detail-AreaLabel'>{prop.label}</div>
                  <InputField key={prop.key} ref={refArray => this.refArray[key] = refArray} name={prop.label} styleInput="input-default-style detail-InputContainer" type='text' title='' customValue={prop.value}  required={true}/>
                </div>
              ); 
            })
          }
        </div>
        <div className='modal-action-container'>
            <Button className='submit-container' type='button' value='Guardar Alterações' onClick={() => this._changeItemData()}/>
        </div>
      </div>
    );
  }
}

export default ChangeDataSample