import React, { Fragment, useEffect, useState } from "react";
import CategoryCard from "../../../components/card/CategoryCard/CategoryCard";
import { CategorySectionPropTypes } from "../../../core/models/props-interface.d";
import './MenuKey.scss';
/**
 * CategorySection component.
 * @param {Object} props
 * @param {string=} props.styleClass - An optional CSS class for styling.
 * @param {Category[]=} props.categories - An array of categories.
 * @param {(category: Category) => void} props.onSelected - A callback function when a category is selected.
 * @param {() => void} props.onGoBack - A callback function when go back is clicked.
 * @param {() => void} props.onScrollList - A callback function when a scroll is detected.
 */
const CategorySection = (props) => {
  const [styleClass, setStyleClass] = useState(props.styleClass);
  const [categories] = useState(props.categories);

  // Use useEffect to update state when props change
  useEffect(() => {
    setStyleClass(props.styleClass);
    // setCategories(props.categories);
  }, [props.styleClass, props.categories]);


  /**
   * Handle category selection.
   * @param {Category} category - The selected category.
   */
  const handleCategorySelected = (category) => {
    props.onSelected(category);
  };

  /**
   * Render the category list.
   * @returns {JSX.Element[] | null}
   */
  const renderCategoryList = () => {
    if (!categories?.length) {
      return <Fragment />;
    }
    return categories?.map((c, index) => {
      return <CategoryCard category={c} onClick={() => handleCategorySelected(c)} key={index} />;
    });
  };

  return (
    <React.Fragment>
      <div className={`category-container ${styleClass || ""}`} id="category-container">
        {/* <div className="d-none d-md-block back-button-container pb-4">
          <LinkButton
            onClick={props.onGoBack}
            styleClass="w-100 d-none d-md-flex justify-content-center link-container"
            valueText={Labels.sidebarCompanies.backButtonText}
            icon={GENERAL.iconArrowUp}
          />
        </div> */}
          {/* <div className="list-instruction-container position-relative pt-0 pb-2 box-shadow">
            <div className="instruction-text">
              {Labels.sidebarCompanies.categorySection.instruction}
            </div>
          </div> */}
        {/* <div
          className={`list-scroll-container ${
            Helper.isAndroidWebview() || Helper.isIOSWebview() ? "" : "browser"
          }`}
          onScroll={props.onScroll}
        >
        </div> */}
          <div
            className="list-container grid-container mt-0 pb-4 pb-md-10"
            id="category-list-scroll-container"
          >
            {renderCategoryList()}
          </div>
        {/* {renderSearchBar()} */}
        {/* <div className="d-md-none scroll-base-container"></div> */}
      </div>
    </React.Fragment>
  );
};

CategorySection.propTypes = CategorySectionPropTypes;
export default CategorySection;
