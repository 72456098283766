import React, { Component } from "react";
import Helper from "../../core/helper/helper";

// components
import Button from 'components/button/button.jsx';
import Labels from "../../variables/labels";
import SelectList from "../SelectComponent/selectList/select-list";
import LocalData from "../../core/localData";

class AddEquipment extends Component {
  constructor(props) {
    super(props);
    this.initialFields = Helper.itemFieldsToAdd();
    this.isLiteVersion = Helper.isLiteVersion();
    this.state = {
      fields: null,
      inputSelectFields: null,
      selectedOption: null,
      disableButton: !Helper.hasValidFields(this.initialFields),
      iAgree: LocalData.terms_accepted,
    }
    this.baseState = {...this.state};
  }

  componentDidMount() {
    this.setState({
      fields: this.initialFields,
      inputSelectFields: Helper.buildInputSelectFields(),
    });
  }

  resetState = () => {
    this.setState(this.baseState);
    this.setState({
      fields: this.initialFields,
      inputSelectFields: Helper.buildInputSelectFields(),
    });
  }

  updateSelectedOption = (field, option) => {
    const { fields } = this.state;   
    const attributes = {...field, selectedValue: option};
    this.updateEquipmentState(fields, field, attributes);
  }

  updateField = (field, event) => {
    const { fields } = this.state;
    const attributes = {...field, selectedValue: event?.target?.value};
    this.updateEquipmentState(fields, field, attributes);
  }

  updateEquipmentState = (newFields, field, attributes) => {
    const index = Helper.findFieldIndex(newFields, field);
    const updatedFields = [
      ...newFields.slice(0, index),
      Object.assign({}, newFields[index], attributes),
      ...newFields.slice(index + 1)
    ];
    this.setState({
      fields: updatedFields,
      disableButton: !Helper.hasValidFields(updatedFields),
    });
  }

  renderInputSelect = (field) => {
    const { inputSelectFields } = this.state;

    const renderWarning = (field) => {
      if (Helper.isSelectFieldEmpty(field)) {
        return (<input required onInvalid={this.handleInputError} className="required-warning-tooltip"/> );
      }
      return;
    }

    return (
      <React.Fragment key={field.name}>
        <div className="select-container" style={{position: 'relative'}} >
          { renderWarning(field) }
          <SelectList  options={inputSelectFields ? inputSelectFields[field.position - 1] : null}
            placeholder={`Selecione ${field.label}`} selectedOption={field.selectedValue} required={field.required}
          onChange={(option) => this.updateSelectedOption(field, option)}/>
        </div>
      </React.Fragment>
    );
  }

  handleInputError = (e) => {
    if (e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity(Labels.requiredField);
      }
    }
  }

  renderItemFields = () => {
    const { isForm } = this.props;
    const { fields } = this.state;

    if (!fields) {
      return;
    }
    return fields.map((field) => {
      if (field.type === 'inputselect') {
        return this.renderInputSelect(field);
      }
      if (!field.required && Helper.isAuto()) {
        return null;
      }
      const inputStyle = `input-medium-default ${field.required ? '' : ''} ${!isForm ? 'register' : '' }`;
      return (
        <div className='input-container mb-2 mt-0' key={field.name}>
           <p className="input-label invert regular"> { field.label } </p>
          <div className="input-row-container">
            <input
              className={inputStyle}
              type='text'
              required={field.required}
              onInvalid={this.handleInputError}
              onChange={(value) => this.updateField(field, value)}
              autoFocus
            />
          </div>
        </div>
      )
    });
  };

  handleSubmit = (event) => {
    const { fields } = this.state;
    event.preventDefault();

    if (Helper.hasValidFields(fields)) {
      this.resetState();
      this.props.onClick(fields);
    }
  }

  render() {
    const { isForm } = this.props;
    const { disableButton, iAgree } = this.state;
    const buttonStyle = `cta-button-secondary w-100 ${disableButton && (!iAgree || !this.isLiteVersion) ? 'is-disabled' : ''}`;

    if (isForm) {
      return (
        <form onSubmit={this.handleSubmit} name="add-item-fields-form">
          {this.renderItemFields()}
          <div className="mt-5 mb-4">
            <Button className={buttonStyle} type="submit" nextRow={true} value={"Guardar"} />
          </div>
        </form>
      );
    }
    return (
      <React.Fragment>
        { this.renderItemFields() }
        <Button className={buttonStyle}
          type="button"
          value={`Adicionar ${Labels.server.item_label}`}
          onClick={(e) => this.handleSubmit(e)}/>
      </React.Fragment>
    );
  }
}
export default  AddEquipment;