import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import './SelectMulti.scss';
import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import { styleObject } from './SelectCustomStyling';
import { SelectMultiPropTypes } from '../../core/models/props-interface.d';

/**
 * @param {Object} props
 * @param {Option[]} props.options - The list of available options.
 * @param {Option[]} props.selectedOptions - The currently selected options.
 * @param {Object} [props.config] - Configuration options.
 * @param {boolean} [props.config.isMulti] - Whether multiple options can be selected.
 * @param {boolean} [props.config.isClearable] - Whether the select field is clearable.
 * @param {boolean} [props.config.autoFocus] - Whether the select field is autofocus.
 * @param {boolean} [props.config.isDisabled] - Whether the select field is disabled.
 * @param {string} [props.placeholder] - The placeholder text.
 * @param {(options: Option[]) => void} props.onChange - Callback function when options change.
 */
const SelectMulti = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
  const [options, setOptions] = useState(props.options?.filter(opt => !opt?.isDisabled));
  const [isLoading, setIsLoading] = useState(props.options ? false : true);

  const selectRef = useRef();

  useEffect(() => {
    setOptions(props.options?.filter(opt => !opt?.isDisabled));
    setIsLoading(props.options ? false : true);
  }, [props.options]);

  useEffect(() => {
    setSelectedOptions(props.selectedOptions);
  }, [props.selectedOptions]);

  const handleChange = (selectedOptions, action) => {
    if (selectedOptions) {
      setSelectedOptions(selectedOptions);
      props.onChange(selectedOptions);
    } else {
      setSelectedOptions([]);
      props.onChange([], action);
    }
  };
  
  return (
    <div className="select-list-container no-swipe" onClick={props.onClick}>
      <div className="select-list-row-container" onClick={props.onClick}>
        <div
          className={`select-list mt-3`}
          onClick={props.onClick}
          onTouchEnd={props.onClick}
          // onClick={(e) => (props.config?.isDisabled ? props.onClick() : e?.stopPropagation())}
        >
          <Select
            ref={selectRef}
            value={selectedOptions}
            onChange={handleChange}
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder={props.placeholder || "Selecione..."}
            options={options}
            isLoading={isLoading}
            isDisabled={isLoading || props.config.isDisabled}
            styles={styleObject}
            onMouseDown={props.onClick}
            onClick={props.onClick}
            onTouchEnd={props.onClick}
            onFocus={() => {
              setTimeout(() => {
                props.onClick();
              }, 100);

            }}
            {...props.config}
          />
        </div>
      </div>
    </div>
  );
};

SelectMulti.propTypes = SelectMultiPropTypes;
export default SelectMulti;
