// React Imports
import React from 'react';
import {withRouter} from 'react-router';
import Cookies from 'universal-cookie';
// Components
import MenuButton from 'components/button/menuButton.jsx';
// Assets
import { SIDEBAR } from 'assets/images/images.js';
import Helper from '../../core/helper/helper';
import LocalData from '../../core/localData';
import { GENERAL } from '../../assets/images/images';
import Modal, { Confirmation, Information, VideoTutorial } from '../../components/modal/modal';
import Labels from '../../variables/labels';
import { deleteClientData } from '../../api/routes/api.route';

class Conta extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.state = {
      bottomBarTop: 0,
      listHeight: "auto",
      hideBottomShadow: true,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener("scroll", this.handleScrollEvent);
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleWindowResize);
    this.scrollListener();
    setTimeout(() => {
      this.setListHeight();
    }, 300);
  };

  scrollListener = () => {
    const scrollContainer = document.getElementById("listContainer");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScrollEvent);
      this.scrollContainer = scrollContainer;
    }
  };

  // Handler do evento de scroll
  handleScrollEvent = (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight; // Altura total do conteúdo
    const clientHeight = event.target.clientHeight;
    if (scrollHeight - scrollTop - clientHeight < 10) {
      this.setState({ hideBottomShadow: true });
    } else {
      this.setState({ hideBottomShadow: false });
    }
  };

  handleWindowResize = () => {
    // this.setBottomBarTop();
    this.setListHeight();
  };

  setListHeight = () => {
    const scrollable = document.getElementById("listContainer");
    if (scrollable) {
      const scrollTop = scrollable.getBoundingClientRect().top;
      const element = document.getElementById("logoutWrapper");

      if (element) {
        const elementHeight = element.getBoundingClientRect().height;

        if (window.innerWidth > 768) {
          const listHeight = window.innerHeight - scrollTop - elementHeight;

          const smallContainer = scrollable.scrollHeight <= listHeight;
          const hideBottomShadow =
            scrollable.scrollHeight - scrollable.scrollTop - listHeight < 10 || smallContainer;

          this.setState({
            listHeight,
            bottomBarTop: 0,
            hideBottomShadow,
            smallContainer,
          });
        } else {
          const bar = document.getElementById("bar-container");
          if (bar) {
            const barHeight = bar.getBoundingClientRect().height;
            const listHeight = window.innerHeight - scrollTop - barHeight - elementHeight;
            const smallContainer = scrollable.scrollHeight <= listHeight;
            const hideBottomShadow =
              scrollable.scrollHeight - scrollable.scrollTop - listHeight < 10 || smallContainer;

            this.setState({
              listHeight,
              bottomBarTop: barHeight,
              hideBottomShadow,
              smallContainer,
            });
          }
        }
      }
    }
  };

  handleEventVideoTutorial = () => {
    this.renderModal(
      <VideoTutorial
        title={"Tutorial Cliente"}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        videoId="ZgPUE68tg-U" // Replace with your YouTube video ID
      />,
      false,
      true
    );
  };

  handleEventPerfil = () => {
    this.props.handleBackButton();
    this.props.history.push(`/perfil`);
  };

  handleEventChangePassword = () => {
    this.props.handleBackButton();
    this.props.history.push(`/alterar-password`);
  };

  handleEventFeedback = () => {
    this.props.handleBackButton();
    this.props.history.push(`/enviar-feedback`);
  };

  handleEventSuporte = () => {
    this.props.handleBackButton();
    this.props.history.push(`/suporte-cliente`);
  };

  handleEventDelete = () => {
    this.renderModal(
      <Confirmation
        title="Atenção"
        text={Labels.deleteAccount.confirmation.message(Helper.companyName())}
        confirmText={Labels.deleteAccount.confirmation.confirm + " " + Helper.companyName()}
        confirmationHandle={() => this.deleteClient()}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  };

  deleteClient = () => {
    this.refModal.current.closeModal();
    deleteClientData(this.cookies.get("sessionToken")).then((res) => {
      if (res) {
        if (res.code) {
          if (res.code === 409) {
            this.refModal.current.closeModal();
            this.props.handleBackButton();
            this.logoutClient(true);
          } else {
            this.renderGenericError();
          }
        } else {
          this.renderModal(
            <Information
              title={`Conta ${Helper.companyName()}`}
              text={res.data?.message}
              onClick={() => {
                this.refModal.current.closeModal();
                this.props.handleBackButton();
                this.logoutClient(true);
              }}
            />
          );
        }
      }
    });
  };

  logoutClient = (doNotMemorizeCurrentCompany = false) => {
    const eventInfo = {
      auth_token: this.cookies.get("sessionToken"),
      route: this.cookies.get("route"),
      client_route: this.cookies.get("clientroute"),
    };
    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.logout.postMessage(eventInfo);
      } catch (error) {
        console.error(error);
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.logout(eventInfo.auth_token, eventInfo.route, eventInfo.client_route);
      } catch (error) {
        console.error(error);
      }
    }
    this.resetClientData();
    Helper.logout(doNotMemorizeCurrentCompany);
  };

  handleEventLogout = () => {
    this.renderModal(
      <Confirmation
        title="Atenção"
        text={"Deseja terminar a sua sessão na app Keymaster?"}
        confirmText={"Terminar Sessão"}
        confirmationHandle={() => this.logoutClient()}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  };

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  };

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}
      />
    );
  };

  renderModal = (view, isMedia = false, occupyScreen = false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  };

  resetClientData() {
    this.cookies.remove("sessionToken", Helper.cookiesConfig);
    this.cookies.remove("liteToken", Helper.cookiesLiteConfig);
    this.cookies.remove("liteEmail", Helper.cookiesLiteConfig);
    this.cookies.remove("litePhone", Helper.cookiesLiteConfig);
    this.cookies.remove("userId", Helper.cookiesLiteConfig);
    this.cookies.remove("isCalToken", Helper.cookiesLiteConfig);
    LocalData.reset();
  }

  render() {
    const { listHeight, smallContainer } = this.state;
    return (
      <React.Fragment>
        <div className="side-bar-menu-container-block pt-2 pt-md-4">
          <div className="top-shadow-container"></div>
          <div
            id="listContainer"
            style={{ height: smallContainer ? "auto" : listHeight, maxHeight: listHeight }}
            className="menu-info scrollable-container pt-2 pt-md-0"
          >
            <div className="menu-list-container w-100">
              <MenuButton
                icon={SIDEBAR.editProfileBlue}
                title="Editar Perfil"
                path={`/`}
                onClick={this.handleEventPerfil}
              />
              {LocalData.userDetails?.email_validated && (
                <MenuButton
                  icon={SIDEBAR.lockerBlue}
                  title="Alterar Password"
                  path={`/`}
                  onClick={this.handleEventChangePassword}
                />
              )}
              <MenuButton
                icon={SIDEBAR.feedbackBlue}
                title="Enviar Feedback"
                path={`/`}
                onClick={this.handleEventFeedback}
              />
              <MenuButton
                icon={SIDEBAR.suporteBlue}
                title="Apoio ao Cliente"
                path={`/`}
                onClick={this.handleEventSuporte}
              />
              <MenuButton
                icon={GENERAL.deleteIcon}
                title={`Apagar a minha conta em ${Helper.companyName()}`}
                path={`/`}
                onClick={this.handleEventDelete}
              />
              <MenuButton
                icon={SIDEBAR.videoDemoIcon}
                title="Vídeo de Demonstração"
                path={`/`}
                onClick={this.handleEventVideoTutorial}
              />
            </div>
          </div>
          {this.renderLogoutWrapper()}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }

  renderLogoutWrapper() {
    const { hideBottomShadow, bottomBarTop } = this.state;
    return (
      <div
        id="logoutWrapper"
        style={{ bottom: bottomBarTop }}
        className={`fixed-buttons-wrapper ${!hideBottomShadow ? "box-shadow" : ""}`}
      >
        <div
          className="cta-button-secondary px-1 mb-4 mt-3 w-100 d-flex justify-content-center align-items-center"
          role="button"
          onClick={() => {
            this.handleEventLogout();
          }}
        >
          Terminar Sessão
        </div>
      </div>
    );
  }
}

export default withRouter(Conta);
