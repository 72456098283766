import PropTypes from 'prop-types';
// COMPONENTS PROPS
export const CompanyPropTypes = PropTypes.shape({
  name: PropTypes.string,
  domain: PropTypes.string,
  logoUrl: PropTypes.string,
  geo: PropTypes.string,
  address: PropTypes.string,
  town: PropTypes.string,
  distance: PropTypes.number,
  website: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
  keywords: PropTypes.arrayOf(
    PropTypes.shape({
      keyword: PropTypes.string,
      type: PropTypes.string,
    })
  ),
});

export const CompanyCardPropTypes = {
  company: CompanyPropTypes,
  isDetailsCard: PropTypes.bool,
  onSelect: PropTypes.func,
};

export const CategoryPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  key: PropTypes.string,
  icon: PropTypes.string,
});

export const OptionPropTypes = PropTypes.shape({
  value: PropTypes.string || undefined,
  label: PropTypes.string || undefined,
  isDisabled: PropTypes.bool,
});

export const CategorySectionPropTypes = {
  styleClass: PropTypes.string,
  categories: PropTypes.arrayOf(CategoryPropTypes),
  onSelected: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onScrollList: PropTypes.func,
};

export const CategoryCardPropTypes = {
  category: PropTypes.object.isRequired || undefined, // Adjust the type as per your Category definition
  onClick: PropTypes.func.isRequired,
};

export const ProvidersPagePropTypes = {
  selectedCategory: CategoryPropTypes,
  isDisabled: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(OptionPropTypes),
  suggestions: PropTypes.arrayOf(CompanyPropTypes),
  companies: PropTypes.arrayOf(CompanyPropTypes),
  onGoBack: PropTypes.func,
  onGoActive: PropTypes.func,
  onUnselectCategory: PropTypes.func,
};

export const SelectMultiPropTypes = {
  options: PropTypes.arrayOf(OptionPropTypes),
  selectedOptions: PropTypes.arrayOf(OptionPropTypes),
  config: PropTypes.shape({
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    autoFocus: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};