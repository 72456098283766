import React, { useState } from "react";
import Labels from "../../../../../variables/labels";
// import Button from "../../../../../components/button/button";
// import { getCompanyIAgreeData } from "../../../../../api/routes/api.route.js";
// import { GENERAL } from "../../../../../assets/images/images.js";
// import Emitter from "../../../../../core/services.js";
import IAgree from "../../../../../components/iagree/iagree.jsx";

/**
 * @param {Object} props - The component props.
 * @param {Company | undefined} props.company - The company data.
 * @param {() => void} props.onClose - A function to close
 * @param {() => void} props.onAdd - A function to handle add the company
 */
const CompanyDetailsAuthentication = (props) => {
  const { company } = props;
  const handleAddCompany = (justEnter = false) => {
    props.onAdd(justEnter);
  };

  const [iAgree, setIAgree] = useState(false);

  const handleCheckboxChange = (value) => {
    setIAgree(value);
  };

  return (
    <>
      <div className="company-details-authentication-section w-100 d-flex justify-content-center flex-column">
        {!company.registered &&
          <>
            <div className="company-details-authentication-title container">
              <h2 className="text-center">
                {!props.termsChanged ? Labels.sidebarCompanies.companyDetails.authenticationTitle(company?.name)
                  : Labels.sidebarCompanies.companyDetails.termsChangedTitle(company?.name)}
              </h2>
            </div>
          </>
        }
        {!company.registered && <IAgree onChange={handleCheckboxChange} company={company}/>}
        <div className="cta-buttons-container mt-5 d-flex flex-column justify-content-center">
          <button
            className={`cta-button-primary w-100 ${(!iAgree && !company.registered) ? 'disabled' : ''}`}
            onClick={() => handleAddCompany(company.registered)}
            disabled={!iAgree && !company.registered}
          >
            {company.registered ? Labels.sidebarCompanies.companyDetails.entrar : Labels.sidebarCompanies.companyDetails.adicionar}
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsAuthentication;
