import React, { Fragment, useEffect, useRef, useState } from "react";
import { GENERAL } from "../../../../assets/images/images";
import SelectMulti from "../../../../components/SelectComponent/SelectMulti";
import LinkButton from "../../../../components/button/LinkButton/LinkButton";
import { ProvidersPagePropTypes } from "../../../../core/models/props-interface.d";
import { CategoryService } from "../../../../core/services/category.service";
import CompanyService from "../../../../core/services/company.service";
import Labels from "../../../../variables/labels";
import "./ProvidersPage.scss";
import CompanyDetailsCard from "./CompanyDetails/CompanyDetailsCard";

// Import Swipe
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper/core';
// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/components/scrollbar/scrollbar.scss'; // Scrollbar styles
import CategorySection from "../CategorySection";
import Helper from "../../../../core/helper/helper";
import SidebarHelper from "../../../../core/helper/sidebarHelper";
import ExpandedCompanyCard from "../../../../components/card/CompanyCard/ExpandedCompanyCard";

// Enable the Scrollbar module
SwiperCore.use([Scrollbar]);

/**
 * The component's state.
 * @typedef {Object} ProvidersPageState
 * @property {Option[]} selectedOptions - An array of selected options.
 * @property {Option[]} suggestions - An array of suggestions.
 * @property {Company[]} companies - An array of company objects.
 * @property {Company} selectedCompany - The selected company or undefined.
 * @property {boolean} detailsIsOpen - A boolean indicating if the modal is open.
 * @property {boolean} isDisabled - A boolean indicating if the component is disabled.
 */

/**
 * ProvidersPage component.
 * @typedef {Object} ProvidersPageProps
 * @property {Category} props.selectedCategory - The selected category or undefined.
 * @property {boolean} props.isDisabled - A boolean indicating if the component is disabled.
 * @property {Option[]} props.selectedOptions - An array of selected options.
 * @property {Option[]} props.suggestions - An array of suggestions.
 * @property {Company[]} props.companies - An array of company objects.
 * @property {() => void} props.onGoBack - A callback function to go back.
 * @property {() => void} props.onGoActive - A callback function to go active.
 * @property {() => void} props.onUnselectCategory - A callback function to unselect a category.
 */

/**@param {ProvidersPageProps} props */
const ProvidersPage = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions);
  const [suggestions, setSuggestions] = useState(props.suggestions || []);
  const [categories] = useState(props.categories || []);
  // const [selectedCategory, setSelectedCategory] = useState(undefined)
  const [companiesDisplayed, setCompanies] = useState(props.companies);

  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const [styleClass, setStyleClass] = useState(props.styleClass)

  const swiperRef = useRef(null);

  

  useEffect(() => {
    setSelectedOptions(props.selectedOptions);
  }, [props.selectedOptions]);

  useEffect(() => {
    setSuggestions(props.suggestions);
  }, [props.suggestions]);

  useEffect(() => {
    if (selectedOptions?.length > 0 && !props.selectedCategory) {
      const filteredCompanies = CompanyService.filterCompanies(props.companies, selectedOptions);
      setCompanies(filteredCompanies);
      updateSwiper();
    } else {
      setCompanies(props.companies);
      updateSwiper();
    }
    // eslint-disable-next-line
  }, [props.companies]); // Dependencies


  useEffect(() => {
    if (selectedCompany) {
      setDetailsIsOpen(true);
      updateSwiper();
    } else {
      setDetailsIsOpen(false);
    }
  }, [selectedCompany]);

  useEffect(() => {
    setStyleClass(props.styleClass);
    if (props.styleClass === "fixed") {
      updateSwiper();
    }
  }, [props.styleClass]);

  const handleOptChanged = (options, _, activeSlide = 1) => {
    const { companies, selectedCategory } = props;

    if (selectedCategory && !CategoryService.findCategoryInOptions(options, selectedCategory)) {
      props.onUnselectCategory();
      setSelectedOptions(options);
    } else if (
      !options?.length ||
      (options?.length === 1 && CategoryService.findCategoryInOptions(options, selectedCategory))
    ) {
      setSelectedOptions(options);
      setCompanies(companies);
    } else {
      const filteredCompanies = CompanyService.filterCompanies(companies, options);
      setSelectedOptions(options);
      setCompanies(filteredCompanies);
    }

    goToSlide(activeSlide);
    updateSwiper(false);
  };

  const handleSelectCompany = (selectedCompany) => {
    setSelectedCompany(selectedCompany);

  };

  const handleAddCompany = (justEnter = false) => {
    if (!Helper.isDemo()) {
      if (justEnter) {
        window.open((/** @type {Company} */ selectedCompany)?.url);
      } else {
        window.open((/** @type {Company} */ selectedCompany)?.url + "?iagree=true");
      }
    } else {
      props.modalDemoMode();
    }
  };

  const handleCloseDetails = () => {
    setSelectedCompany(undefined);
    updateSwiper();
  }

  const handleGoBack = () => {
    props.onGoBack();
  };

  const renderCompanyList = () => {
    if (!companiesDisplayed?.length) {
      return (
        <div className="d-flex text-center mx-4 mt-2">
          A lista de Prestadores está a ser carregada...
        </div>
      );
    }
    return (
      <div
        className="company-list-container"
        id="company-list-container"
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
      >
        {companiesDisplayed?.map((c, index) => (
          // <CompanyCard company={c} key={index} onSelect={handleSelectCompany} />
          <div className="expanded-company-container" key={index}>
            <div className={`company-details-card-container px-4 py-2`}>
              <div className="company-details-card">
                <div className="company-details-content-container flex-column align-center">
                  <div className="company-details-container w-md-100">
                    <div className="company-details-header">
                      <ExpandedCompanyCard
                        company={c}
                        isDetailsCard={true}
                        onSelect={handleSelectCompany}
                        isDark={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCardDetails = () => {
    return !detailsIsOpen ? null : (
      <CompanyDetailsCard
        company={selectedCompany}
        onClose={handleCloseDetails}
        onAdd={handleAddCompany}
      />
    );
  };

  const handleOnSearBarClick = () => {
    props.onGoActive();  // Existing handler
    goToSlide(1);        // New functionality to navigate to slide
  };

  const renderSearchBar = () => {
    // if (detailsIsOpen) {
    //   return <Fragment />;
    // }
    return (
      <div className="anchor search-bar-container mt-2" onClick={handleOnSearBarClick} onTouchEnd={handleOnSearBarClick} role="button">
        <SelectMulti
          selectedOptions={selectedOptions}
          onChange={handleOptChanged}
          onClick={handleOnSearBarClick}
          options={suggestions}
          config={{
            isMulti: true,
            isClearable: true,
            autoFocus: false,
            isDisabled: props.styleClass === "bottom-fixed" || !suggestions,
          }}
          placeholder={Labels.sidebarCompanies.searchInputPlaceholder}
        />
      </div>
    );
  };

  const renderCompanyListContainer = () => {
    return detailsIsOpen ? null : (
      <Fragment>
        <div
          className="company-container grid-scroll-container pt-4 pb-5"
          id="company-scroll-container"
          onScroll={props.onScroll}
          >
          {renderCompanyList()}
        </div>
        {/* <div className="scroll-base-container"></div> */}
      </Fragment>
    );
  };

  const renderCategorySection = () => {
    
    return !categories?.length ? null : (
      <div
        className={`select-category-container`}
        id="select-category-container"
      >
        <CategorySection
          categories={categories}
          onSelected={(cat) => {
            props.onCategorySelected(cat);
            goToSlide(1);
          }}
          onClickSearchCompany={() => this.swiperInstance.slideNext()}
          onScroll={() => {}}
          onGoBack={() => this.handleAddCompany(false)}
        />
      </div>
    );
  }

  const updateSwiper = () => {
    if (swiperRef.current) {
      setTimeout(() => {    
        SidebarHelper.checkBoxShadow('menu-key-container');    
        swiperRef.current.update();
        swiperRef.current.updateAutoHeight();
      }, 500);
    }
  };

  const goToSlide = (slide) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(slide);
    }
    //console.log("Swiping to slide: ", slide);
    //props.onUnselectCategory();
    //setSelectedOptions(null);
  };

  const renderTabsSection = () => {
    const onSwiper = (swiper) => {
      swiperRef.current = swiper;
    //  console.log('Swiper initialized');
    }

    return (
      <div
        className={`swiper-companies-container ${styleClass} ${
          Helper.isIOS() || Helper.isIOSWebview() ? "is-ios" : ""
        }`}
        id="swiper-companies-container"
      >
        <div className={`swiper-tabs-container row mx-0 px-0`} role="button">
          <div className="swiper-tab col-6 pb-2 px-0" onClick={() => goToSlide(0)}>
            {Labels.sidebarCompanies.tabs.categories}
          </div>
          <div className="swiper-tab col-6 pb-2 px-0" onClick={() => goToSlide(1)}>
            {Labels.sidebarCompanies.tabs.companies}
          </div>
        </div>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            updateSwiper();
            if (swiper?.activeIndex === 0) {
              setTimeout(() => {                
                setSelectedOptions([]);
                props.onUnselectCategory();
                handleOptChanged([], null, 0);
              }, 100);
            }
          }}
          onSwiper={onSwiper}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          modules={[Scrollbar]}
          autoHeight={true}
          touchRatio={1}
          longSwipesRatio={0}
          speed={80}
          resistance={false}
          touchAngle={15}
          zoom={false}
          // threshold={1}
          // followFinger={false}
          // freeMode={true}
          // cssMode={false}
        >
          <SwiperSlide>{renderCategorySection()}</SwiperSlide>
          <SwiperSlide>
            {renderCompanyListContainer()}
            {renderCardDetails()}
          </SwiperSlide>
          {/* ... additional slides */}
        </Swiper>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="main-page-container" id="providers-main-page">
        <div
          className={`top-section-container d-flex flex-column px-3 ${
            Helper.isIOS() || Helper.isIOSWebview() ? "is-ios" : ""
          }`}
        >
          <div className={"back-button-container mt-2"}>
            <LinkButton
              onClick={handleGoBack}
              styleClass="w-100 d-none d-md-flex justify-content-center link-container"
              valueText={Labels.sidebarCompanies.backButtonText}
              icon={GENERAL.iconArrowUp}
            />
          </div>
          {renderSearchBar()}
        </div>
        {renderTabsSection()}
        {/* {renderCompanyListContainer()} */}
      </div>
      {/* {renderCardDetails()} */}
    </Fragment>
  );
};

ProvidersPage.propTypes = ProvidersPagePropTypes;

export default ProvidersPage;
