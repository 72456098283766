// React Imports
import React, { createRef } from 'react';
import { withRouter } from 'react-router';
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

import RequirementList from 'components/card/RequirementList/RequirementList.jsx';
import TasksList from '../../components/list/tasks/tasks.jsx';
import Modal, { Information, Confirmation, MultiOptions } from '../../components/modal/modal.jsx';
import Button from '../../components/button/button.jsx';

// UTILS
import Labels from '../../variables/labels.js';
import Helper from '../../core/helper/helper';
import { HelperSchedule } from '../schedule/core/helpers/helperSchedule.js';
import { Helmet } from 'react-helmet';
// API Imports
import {
  getClientData, getFoDetails, provideServiceFeedback,
  requestInvoice, getShowcaseWorkDetails, submitApproveApi, getScheduleDetails, getShowcaseWorkDetailsCal
} from '../../api/routes/api.route.js';
import ScheduleService from '../schedule/core/services/schedule.service';
import LocalData from '../../core/localData.js';
import ServiceDetailsCard from '../../components/card/service/serviceDetailsCard.jsx';
import Grid from '../../components/list/grid/grid.jsx';
import { SERVICE, GENERAL } from '../../assets/images/images.js';
import MediaFiles from '../../components/mediaFiles/MediaFiles.jsx';
import { addClientServiceObs, addMediaFile, editPersonalServiceObs, getUploadConfig, readServiceObs, readServiceCheck, removeMediaFile } from '../../api/routes/api.route.js';
import Loader from '../../components/loader/loader.jsx';
import Emitter from '../../core/services.js';
import { analyseUrlShowcaseAnalytics, analyseUrlWorkAnalytics } from '../../core/services/analytics.service.js';
import CompanyService from '../../core/services/company.service.js';
import NotificationsService from '../../core/services/notifications.service.js';
import ConfirmAppointment from '../../components/button/ConfirmAppointment.jsx';

// const { id, symptoms, phase, state, item, allow_cancel, allow_approve, message_toast_state,
// date_start, date_end, feedback, schedule, company_obs, client_obs, token, show_invoice_button } = data;
/**
 * ServiceData definition
 * @typedef  {Object}  ServiceData
 * @property {string}  id
 * @property {string}  token
 * @property {string}  client_obs
 * @property {string}  internal_obs
 * @property {string}  company_obs
 * @property {string}  total
 * @property {string}  total_discount
 * @property {Object}  symptoms
 * @property {Object}  state
 * @property {Object}  establishment
 * @property {Object}  company_extras
 * @property {Object}  client_extras
 * @property {Object[]}  services
 * @property {boolean}  allow_approve
 * @property {boolean}  allow_cancel
 * @property {boolean}  is_cancelled
 * @property {boolean}  show_invoice_button
 * @property {ServiceItem}  item
 * @property {'IGNORE' | 'EXPANDED' | 'MINIMIZED'}  message_toast_state
 * @property {'IGNORE' | 'EXPANDED' | 'MINIMIZED'}  service_toast_state
 */

/**
 * ServiceItem
 * @typedef {Object} ServiceItem
 * @property {number} id
 * @property {string} image
 * @property {string} title
 * @property {string} subtitle
 */

class ServiceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.imageRef = React.createRef();
    this.timer = null;
    const params = new URLSearchParams(this.props.location.search);
    this.token = params.get("token") || LocalData.liteData?.token;
    this.token_cal = params.get("token_cal") || LocalData.liteData?.token_cal;
    this.isLiteVersion = Helper.isLiteVersion();
    this.isLiteWithoutToken = this.isLiteVersion && !this.token && !this.token_cal;
    this.state = {
      id: this.props.match.params.id,
      draftWorkID: undefined,
      data: null,
      serviceDetail: {},
      photos: [],
      files: undefined,
      establishment: {},
      valueRatingPrev: 0,
      observationPrev: "",
      valueRating: 0,
      observation: "",
      userData: {},
      showInvoiceButton: false,
      isAppointment:
        this.props.match.params.id?.substring(0, 2) === "S-" ||
        (this.token_cal !== undefined && this.token_cal !== null && this.token_cal !== ""),
      analyticsCalled: false,
      galleryButtonVisible: true,
      selectedMediaTab: null,
      confirmLoading: false,
    };
    this.notificationId = params.get("notifid");
    this.goToFeedback = params.has("feedback");
    this.galleryRef = createRef();
  }

  componentDidMount() {
    this.callAnalytics();
    this.isComponentMounted = true;
    if (this.isComponentMounted) {
      this.baseState = { ...this.state };
    }
    window.scrollTo(0, 0);
    //    ReactGA.pageview(window.location.pathname + window.location.search);
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (!this.isLiteVersion) {
      const sessionUser = LocalData.userData;
      if (sessionUser) {
        this.setState({ userData: sessionUser });
      } else {
        this.getUserData();
      }
      this.getServiceDetails();
      this.getUploadFilesTypes();
      this.updateNotifications();
    } else {
      if (this.token) {
        this.cookies.set("liteToken", this.token, Helper.cookiesLiteConfig);
      }
      if (this.token_cal) {
        this.cookies.set("liteToken", this.token_cal, Helper.cookiesLiteConfig);
      }
      if (this.email) {
        this.cookies.set("liteEmail", this.email, Helper.cookiesLiteConfig);
      }
      if (this.phone) {
        this.cookies.set("litePhone", this.phone, Helper.cookiesLiteConfig);
      }
      const companyDomain = Helper.companyDomain();
      if (!companyDomain.includes("asuaempresa")) {
        this.cookies.set("kssreturncompany", companyDomain, Helper.cookiesLiteConfig);
      }

      if (LocalData.liteData) {
        this.buildServiceDetails(LocalData.liteData);
      } else {
        if (!this.state.isAppointment) {
          if (this.token) {
            this.getShowcaseDetails(this.token, this.cookies.get("sessionToken"));
          }
        } else {
          this.cookies.set("isCalToken", true, Helper.cookiesLiteConfig); //means that we are in calendar context
          if (this.token_cal) {
            this.getShowcaseDetailsCal(this.token_cal, this.cookies.get("sessionToken"));
          }
        }
      }
    }

    this.refreshPage();

    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.saveLastUrl.postMessage({ url: window.location.href });
      } catch (error) {
        console.error(error);
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.saveLastUrl(window.location.href);
      } catch (error) {
        console.error(error);
      }
    }
    window.addEventListener("scroll", this.listenToScroll);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    this.callAnalytics();
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.isComponentMounted = true;
      if (this.isComponentMounted) {
        this.baseState = { ...this.state };
      }
      window.scrollTo(0, 0);
      //    ReactGA.pageview(window.location.pathname + window.location.search);
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.isLiteVersion) {
        const sessionUser = LocalData.userData;
        if (sessionUser) {
          this.setState({ userData: sessionUser });
        } else {
          this.getUserData();
        }
        this.getServiceDetails();
        this.getUploadFilesTypes();
      } else {
        if (LocalData.liteData) {
          this.buildServiceDetails(LocalData.liteData);
        } else {
          if (!this.state.isAppointment) {
            if (this.token) {
              this.getShowcaseDetails(this.token, this.cookies.get("sessionToken"));
            }
          } else {
            if (this.token_cal) {
              this.getShowcaseDetailsCal(this.token_cal, this.cookies.get("sessionToken"));
            }
          }
        }
      }
      this.refreshPage();

      if (Helper.isIOSWebview()) {
        try {
          window.webkit.messageHandlers.saveLastUrl.postMessage({ url: window.location.href });
        } catch (error) {
          console.error(error);
        }
      }
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.saveLastUrl(window.location.href);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    window.removeEventListener("scroll", this.listenToScroll);
    window.removeEventListener("scroll", this.handleScroll);
  }

  listenToScroll = () => {
    this.mobileElement = window.document.documentElement;
    const pos = this.mobileElement.scrollTop;
    const toastElem = document.querySelector(".Toastify__toast-container--top-right");
    if (pos < 60) {
      if (toastElem) {
        toastElem.style.position = "absolute";
        toastElem.style.top = "60px";
      }
    } else {
      if (toastElem) {
        toastElem.style.position = "fixed";
        toastElem.style.top = "-4px";
      }
    }
  };

  callAnalytics() {
    if (this.state.analyticsCalled) {
      return null;
    }

    if (Helper.isLiteVersion()) {
      if (LocalData.liteData?.is_client !== undefined) {
        analyseUrlShowcaseAnalytics(
          window.location.href,
          LocalData.liteData?.is_client,
          LocalData.liteData?.id
        );
        this.setState({ analyticsCalled: true });
      }
    } else {
      analyseUrlWorkAnalytics(window.location.href);
      this.setState({ analyticsCalled: true });
    }
  }

  updateNotifications = () => {
    if (this.notificationId) {
      if (!LocalData.notificationsData?.notificationsList) {
        setTimeout(() => {
          this.updateNotifications();
        }, 200);
      } else {
        NotificationsService.read(this.notificationId);
      }
    }
  };

  checkInternetConnection = () => {
    // Return a promise that resolves to true or false based on internet connectivity
    const url = `https://www.google.com/favicon.ico?${new Date().getTime()}`;
    return fetch(url, { mode: "no-cors", method: "HEAD" })
      .then(() => true) // Internet is working
      .catch(() => false); // No internet connection detected
  };

  refreshPage = () => {
    if (!this.isLiteWithoutToken) {
      this.timer = setTimeout(() => {
        this.resetState();
        if (!this.isLiteVersion) {
          this.getUserData();
          this.getServiceDetails();
          CompanyService.getCompaniesCount();
        } else {
          if (!this.state.isAppointment) {
            if (this.token) {
              this.getShowcaseDetails(this.token, this.cookies.get("sessionToken"));
            }
          } else {
            if (this.token_cal) {
              this.getShowcaseDetailsCal(this.token_cal, this.cookies.get("sessionToken"));
            }
          }
        }
        this.refreshPage(); // Reschedule after processing the current check's result
      }, 300000);
    }
  };

  resetState = () => {
    if (this.isComponentMounted) {
      this.setState(this.baseState);
      document.body.style.overflowY = "auto";
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.enablePullDownToRefresh();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  getUploadFilesTypes = () => {
    getUploadConfig(this.cookies.get("sessionToken")).then((res) => {
      if (res?.data) {
        Helper.updateFilesAccept(res.data);
      }
    });
  };

  getAppointmentFiles = async (idParam, draftWorkIDParam) => {
    if (!this.state.isAppointment || !this.isLiteVersion || !idParam || !draftWorkIDParam) {
      return;
    }

    let _id = idParam;
    let _draftWorkID = draftWorkIDParam;
    const { id, draftWorkID } = this.state;

    if (!_id || !_draftWorkID) {
      _id = id;
      _draftWorkID = draftWorkID;
      //idParam = id;//.replace(/^S-/i, "");
    }
    try {
      const response = await ScheduleService.fetchAppointmentFiles(_id, _draftWorkID);
      if (response?.data) {
        this.setState((prevState) => ({
          ...prevState,
          files: {
            provider: response.data.company_extras,
            customer: response.data.client_extras,
          },
        }));
      } else {
        //this.renderInfoModal(response?.title, response?.body, response?.code);
      }
    } catch (error) {
      console.error("fetchAppointmentFiles [catch error]", error);
    }
  };

  /**
   * Pedido para retornar a obra a mostrar em caso de versão Lite
   *
   * @param {string} token - id para a obra
   */
  getShowcaseDetails(id, token) {
    getShowcaseWorkDetails(id, token).then((value) => {
      if (value) {
        if (value?.code === 1 && value?.details) {
          const { item } = value.details;
          const showcaseWork = {
            ...value.details,
            item: Helper.initialItemDetailsState(item, this.isLiteVersion),
          };
          LocalData.liteData = showcaseWork;
          this.buildServiceDetails(showcaseWork);
          if (value.details.user_id) {
            this.cookies.set("userId", value.details.user_id, Helper.cookiesLiteConfig);
          }
          if (value.details.email) {
            this.cookies.set("liteEmail", value.details.email, Helper.cookiesLiteConfig);
          }
          if (value.details.phone) {
            this.cookies.set("litePhone", value.details.phone, Helper.cookiesLiteConfig);
          }
        } else if (value?.code === 5) {
          if (this.goToFeedback) {
            analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id); //Send analytics before redirect
            window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0&feedback=t`;
          } else {
            analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id);
            window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0`;
          }
        } else if (value?.code === 3 || value?.code === 2) {
          Emitter.emit("TOAST_LITEVERSION_DISABLE", true);
          this.renderModal(
            <Information
              title="Deseja aceder ao histórico da sua última intervenção?"
              text={Labels.liteGenericMessage}
              confirmText={Helper.getLiteRegisterButton()}
              onClick={() => Helper.logoutFromLite()}
            />
          );
        } else {
          this.renderInfoModal(Labels.genericErrorTitle, value.message);
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  /**
   * Pedido para retornar a obra a mostrar em caso de versão Lite
   *
   * @param {string} token - id para a obra
   */
  getShowcaseDetailsCal(id, token) {
    getShowcaseWorkDetailsCal(id, token).then((value) => {
      if (value) {
        if (value?.code === 1 && value?.details) {
          const { item, date_start, date_end } = value.details;
          const formattedDateStart = date_start
            ? ScheduleService.convertDateFromISOPendingAppointment(date_start)
            : null;
          const formattedTime = date_start ? ScheduleService.getTimeFromISO(date_start) : null;
          const formattedCalendarShareDateStart = date_start
            ? ScheduleService.convertDateFromISOCalendarShare(date_start)
            : null;
          const formattedCalendarShareDateEnd = date_end
            ? ScheduleService.convertDateFromISOCalendarShare(date_end)
            : null;

          const { token, ...detailsWithoutToken } = value.details;
          const showcaseWork = {
            ...detailsWithoutToken,
            date_start: formattedDateStart,
            time: formattedTime,
            state: {
              name: HelperSchedule.getAppointmentStateBarsDescription(value.details),
              bars: 0,
            },
            shareCalendar_start_time: formattedCalendarShareDateStart,
            shareCalendar_end_time: formattedCalendarShareDateEnd,
            item: Helper.initialItemDetailsState(item, this.isLiteVersion, true),
            token_cal: token,
          };

          LocalData.liteData = showcaseWork;
          this.buildServiceDetails(showcaseWork);
          if (value.details.user_id) {
            this.cookies.set("userId", value.details.user_id, Helper.cookiesLiteConfig);
            this.cookies.set("isCalToken", true, Helper.cookiesLiteConfig); //means that we are in calendar context
          }
          if (value.details.email) {
            this.cookies.set("liteEmail", value.details.email, Helper.cookiesLiteConfig);
          }
          if (value.details.phone) {
            this.cookies.set("litePhone", value.details.phone, Helper.cookiesLiteConfig);
          }
        } else if (value?.code === 5) {
          analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id); //Send analytics before redirect
          window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0`;
        } else if (
          value?.code === 3 ||
          value?.code === 2 ||
          (value?.code === 1 && !value.details)
        ) {
          Emitter.emit("TOAST_LITEVERSION_DISABLE", true);
          this.renderModal(
            <Information
              title="Os detalhes deste agendamento não estão partilhados."
              text={Labels.liteGenericMessage}
              confirmText={Helper.getLiteRegisterButton()}
              onClick={() => Helper.logoutFromLite()}
            />
          );
        } else {
          this.renderInfoModal(Labels.genericErrorTitle, value.message);
        }
      } else {
        this.props.history.push(`/services`);
      }
    });
  }

  /**
   * Pedido para obter dos dados do cliente
   */
  getUserData = () => {
    getClientData(this.cookies.get("sessionToken")).then((value) => {
      if (value) {
        if (!value.code) {
          LocalData.userData = value;
          this.setState({ userData: null });
          this.setState({ userData: value });
          this.forceUpdate();
        }
      }
    });
  };

  /**
   * Lida com a resposta a um request quando existe value code
   */
  handleValueCodeResponse = (value) => {
    if (value.code === 401) {
      return;
    } else if (value.code === 404) {
      this.props.history.push(`/services`);
    } else if (value.code === 409) {
      this.renderModal(
        <Information
          title="Acesso Não Autorizado"
          text={value.message}
          onClick={() => {
            this.refModal.current.closeModal();
            this.props.history.push(`/services`);
          }}
        />
      );
    } else if (value.code === 406) {
      this.renderInfoModal("Formato Inválido", value.message);
    } else if (value.code === 301) {
      if (this.goToFeedback) {
        this.props.history.replace(`/service-details/${value.id}?s=I&t=0&feedback=t`);
      } else {
        this.props.history.replace(`/service-details/${value.id}?s=I&t=0`);
      }
    } else {
      this.renderModal(
        <Information
          title="Pedido Sem Sucesso"
          text="Foi encontrado um problema durante o pedido, por favor tente mais tarde"
          onClick={() => {
            this.refModal.current.closeModal();
            this.props.history.push(`/services`);
          }}
        />
      );
    }
  };

  buildServiceDetails = (/** @type {ServiceData} */ data) => {
    /** @type {ServiceData} */
    const {
      id,
      symptoms,
      phase,
      state,
      item,
      allow_cancel,
      allow_approve,
      service_toast_state,
      message_toast_state,
      date_start,
      old_date,
      date_end,
      time,
      old_time,
      feedback,
      schedule,
      company_obs,
      client_obs,
      internal_obs,
      token,
      token_cal,
      show_invoice_button,
      shareCalendar_start_time,
      shareCalendar_end_time,
      is_cancelled,
      is_history,
      work_id,
      draft_work_id,
      allow_confirmation,
    } = data;

    const missedAppointment = !work_id && !is_cancelled && is_history && this.state.isAppointment;

    /** @type {ServiceItem} */
    const serviceDetails = {
      total: data.total,
      totalDiscount: data.total_discount,
      items: data.services,
      companyObs: company_obs?.replace(/(\r\n|\n|\r)/gm, "<br>"),
    };
    this.setState({ data: null });
    this.setState({
      id: id,
      draftWorkID: draft_work_id,
      serviceDetail: serviceDetails,
      files: {
        provider: data?.company_extras,
        customer: data?.client_extras,
      },
      establishment: data.establishment,
      data: {
        symptoms: symptoms,
        item: item,
        phase: phase,
        state: {
          bars: state?.bars,
          name: missedAppointment ? "O cliente não compareceu" : state?.name,
        },
        allowCancel: allow_cancel,
        is_cancelled: is_cancelled,
        is_history: is_history,
        allowApprove: allow_approve,
        dateStart: date_start,
        old_date: old_date,
        dateEnd: date_end,
        old_time: old_time,
        time: time,
        shareCalendar_start_time: shareCalendar_start_time,
        shareCalendar_end_time: shareCalendar_end_time,
        schedule: schedule,
        feedback: feedback,
        clientObs: client_obs,
        internalObs: internal_obs,
        showInternalNotes: !!internal_obs,
        checkoutInfo: data?.exit_kms,
        token,
        token_cal,
        showUnreadCheck: service_toast_state === "EXPANDED",
        showUnreadMessage: message_toast_state === "EXPANDED",
        hasMessage: !!serviceDetails?.companyObs,
        missedAppointment: missedAppointment,
        allowConfirm: allow_confirmation,
      },
      valueRating: Helper.printRating(feedback),
      observation: Helper.printObserv(feedback),
      valueRatingPrev: Helper.printRating(feedback),
      observationPrev: Helper.printObserv(feedback),
      showFeedback: Boolean(!feedback),
      showInvoiceButton: show_invoice_button,
    });
    this.getAppointmentFiles(id, draft_work_id);
  };

  /**
   * Pedido para devolver details do serviço de uma equipamento especifica
   */
  getServiceDetails() {
    const { match } = this.props;
    if (match.params.id && match.params.id.substring(0, 2) === "S-") {
      const appointmentId = match.params.id;
      getScheduleDetails(this.cookies.get("sessionToken"), appointmentId).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            const { token, ...valueWithoutToken } = value;
            const updatedValue = {
              ...valueWithoutToken,
              state: {
                name: HelperSchedule.getAppointmentStateBarsDescription(value),
                bars: value.state.bars,
              },
              token_cal: token,
            };
            this.buildServiceDetails(updatedValue);
          }
        }
      });
    } else {
      getFoDetails(match.params.id, this.cookies.get("sessionToken")).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            this.buildServiceDetails(value);
          }
        }
      });
    }
  }

  ratingValueHandle = (value) => {
    this.setState({ valueRating: value });
  };

  /**
   * Pedido para retornar dos os seriços que foram agendados
   *
   * @param {number} id - id do serviço
   * @param {string} feedback - objecto com o feedback (rating, observação)
   */
  serviceFeedback = (id, feedback) => {
    provideServiceFeedback(this.cookies.get("sessionToken"), id, feedback).then((value) => {
      if (value) {
        if (value.code) {
          this.renderModal(
            <Information
              title="Pedido Sem Sucesso"
              text={value.message}
              onClick={() => {
                this.setState({
                  observation: this.state.observationPrev,
                  valueRating: this.state.valueRatingPrev,
                });
                this.refModal.current.closeModal();
              }}
            />
          );
        } else {
          this.getServiceDetails();
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  /**
   * Confirmação de pedir faturação
   */
  confirmSubmitInvoice() {
    if (this.isLiteVersion) {
      this.renderModal(
        <Information
          title={Labels.liteSendInvoiceTitle}
          text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => Helper.logoutFromLite()}
        />
      );
      return;
    }
    const { userData } = this.state;
    if (!userData.invoice_primary && !userData.invoice_secondary) {
      this.renderInfoModal("Atenção", Labels.warningSendInvoiceMessage);
    } else {
      this.renderModal(
        <Confirmation
          title="Atenção"
          text={Labels.confirmSendInvoice}
          confirmText="Enviar"
          confirmationHandle={() => this.submitInvoice()}
          declineHandle={() => this.refModal.current.closeModal()}
        />
      );
    }
    this.refModal.current.openModal();
  }

  submitInvoice = () => {
    const { userData, id } = this.state;
    const emails = [];
    if (userData.invoice_primary) {
      emails.push(userData.email);
    }
    if (userData.invoice_secondary) {
      emails.push(userData.secondary_email);
    }
    this.refModal.current.closeModal();
    const body = { emails: emails };
    requestInvoice(id, this.cookies.get("sessionToken"), body).then((value) => {
      if (value) {
        if (value.code) {
          this.renderGenericError();
        } else {
          this.renderInfoModal("Pedido efectuado com Sucesso", value.message);
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  confirmAllowApprove() {
    if (this.isLiteVersion) {
      this.renderModal(
        <Information
          title={Labels.liteApproveBudgetTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => Helper.logoutFromLite()}
        />
      );
      return;
    }
    this.renderModal(
      <Confirmation
        title={Labels.confirmApproveBudgetTitle}
        text={Labels.confirmApproveBudget(this.state.serviceDetail.total)}
        confirmText={Labels.confirm}
        confirmationHandle={() => {
          this.refModal.current.closeModal();
          this.submitApprove();
        }}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  }

  submitApprove = () => {
    const { id } = this.state;
    submitApproveApi(id, this.cookies.get("sessionToken")).then((value) => {
      if (value) {
        if (value.code) {
          this.renderGenericError();
        } else {
          this.renderModal(
            <Information
              title={Labels.modal.approvedBudget.title}
              text={value.message}
              onClick={() => {
                this.refModal.current.closeModal();
                this.buildServiceDetails(value);
              }}
            />
          );
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  validateFeedbackSubmition = (feedback) => {
    const { id } = this.state;
    if (!this.isLiteVersion) {
      this.serviceFeedback(id, feedback);
      CompanyService.getCompaniesCount();
    } else {
      this.renderModal(
        <Information
          title={Labels.liteSendFeedbackTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => Helper.logoutFromLite()}
        />
      );
    }
  };

  updateSelectedMediaTab = (value) => {
    this.setState({ selectedMediaTab: value });
  };

  renderFilesSection = () => {
    if (this.state.isAppointment && !this.state.draftWorkID) return null;
    const {
      files,
      data: { state },
      selectedMediaTab,
    } = this.state;
    const styleClass = "mt-3 mt-md-4";
    return (
      <div ref={this.galleryRef}>
        <MediaFiles
          key="media-files" // Ensure the key remains stable unless you need to force a remount
          data={{ files, state }}
          styleClass={styleClass}
          newMediaFileLoaded={this.newMediaFileLoaded}
          onDeleteMediaFile={this.deleteMediaFile}
          selectedTab={selectedMediaTab}
          onSelectedTabChange={this.updateSelectedMediaTab}
        />
      </div>
    );
  };

  deleteMediaFile = (id) => {
    this.renderModal(
      <Confirmation
        title="Atenção"
        text={Labels.confirmRemoveFile}
        confirmText="Remover"
        confirmationHandle={() => {
          this.renderModal(
            <Loader
              message="O seu ficheiro está a ser apagado..."
              inverted={false}
              local={true}
              big={false}
            ></Loader>
          );
          removeMediaFile(this.cookies.get("sessionToken"), this.props.match.params.id, id).then(
            (value) => {
              if (value) {
                if (value.code) {
                  this.handleValueCodeResponse(value);
                } else {
                  this.renderModal(
                    <Information
                      title={"Concluído!"}
                      text={value.message}
                      onClick={() => {
                        this.refModal.current.closeModal();
                        this.getServiceDetails();
                      }}
                    />
                  );
                }
              } else {
                this.renderGenericError();
              }
            }
          );
        }}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  };

  newMediaFileLoaded = (file, thumbnail) => {
    let formData = new FormData();
    formData.append("media", file, file.name);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail, file.name);
    }
    this.renderModal(
      <Loader
        message="O seu ficheiro está a ser carregado..."
        inverted={false}
        local={true}
        big={false}
      ></Loader>
    );

    const serviceId = this.state.id;
    //const serviceId = this.state.draftWorkID ? "S-" + this.state.draftWorkID : this.state.id;

    //addMediaFile(this.cookies.get('sessionToken'), this.props.match.params.id, formData).then(value => {
    addMediaFile(this.cookies.get("sessionToken"), serviceId, formData).then((value) => {
      this.refModal.current.closeModal();
      if (value) {
        if (value.code) {
          this.handleValueCodeResponse(value);
        } else {
          this.renderModal(
            <Information
              title={"Concluído!"}
              text={value.message}
              onClick={() => {
                this.refModal.current.closeModal();
                this.getServiceDetails();
              }}
            />
          );
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  handleOnReadMessage = () => {
    readServiceObs(this.props.match.params.id, this.cookies.get("sessionToken")).then((res) => {
      if (res.code) {
        return;
      }
      this.getServiceDetails();
    });
  };

  handleOnReadCheck = () => {
    readServiceCheck(this.props.match.params.id, this.cookies.get("sessionToken")).then((res) => {
      if (res.code) {
        return;
      }
      this.getServiceDetails();
    });
  };

  renderServiceDetailsCard = () => {
    const { id, data, establishment, isAppointment } = this.state;
    return (
      <ServiceDetailsCard
        data={{ ...data, id, establishment, isAppointment }}
        isLiteVersion={this.isLiteVersion}
        location={this.props.location}
        history={this.props.history}
        callback={this.validateFeedbackSubmition}
        onReadMessage={() => this.handleOnReadMessage()}
        onReadCheck={() => this.handleOnReadCheck()}
      />
    );
  };

  renderTasksList = () => {
    //if (this.state.isAppointment) return;
    const {
      serviceDetail,
      data: { state, allowApprove },
      showInvoiceButton,
    } = this.state;
    return (
      <TasksList
        title="Detalhes do Serviço"
        allowApprove={allowApprove}
        data={serviceDetail}
        state={state.bars}
        invoiceEmail={showInvoiceButton}
        submitInvoice={() => this.confirmSubmitInvoice()}
        onAllowApprove={() => this.confirmAllowApprove()}
        isAppointment={this.state.isAppointment}
      />
    );
  };

  handleCalendarEventShare = (appointment) => {
    if (
      Helper.isAndroidWebview() ||
      Helper.isIOSWebview() ||
      Helper.isAndroidBrowser() ||
      Helper.isIOS()
    ) {
      HelperSchedule.createICalAndShare(
        ScheduleService.convertCardDataToCalendarShare(appointment)
      );
      return;
    }
    this.refModal.current.renderView(
      <MultiOptions
        title="Adicionar a Outros Calendários"
        option1Text="Calendário Google"
        icon1={GENERAL.iconGoogle}
        option2Text="Calendário Apple"
        icon2={GENERAL.iconApple}
        option3Text="Calendário Outlook"
        icon3={GENERAL.iconOutlook}
        handleOption1={() => {
          HelperSchedule.createGoogleCalendarUrl(
            ScheduleService.convertCardDataToCalendarShare(appointment)
          );
        }}
        handleOption2={() => {
          HelperSchedule.createICalAndShare(
            ScheduleService.convertCardDataToCalendarShare(appointment)
          );
        }}
        handleOption3={() => {
          HelperSchedule.createICalAndShare(
            ScheduleService.convertCardDataToCalendarShare(appointment)
          );
        }}
      />
    );
    this.refModal.current.openModal();
  };

  renderCalendarEventShare = (size) => {
    if (!this.state.isAppointment) return;
    const appointment = this.state;
    const margin = this.state.hasDiscount ? 
                  "mt-2" :
                          size === 'small' ?
                            "mt-3" :
                            "mt-4";

    const buttonClass = size === 'small' 
    ? 'cta-button-secondary d-sm-medium w-75 flex-center' 
    : 'cta-button-secondary d-medium d-sm-large w-100 px-4 flex-center';
    return (
      <div className={`${margin}`}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "349px" }}>
            <Button
              className={buttonClass}
              type="iconAndText"
              value="Adicionar a Outros Calendários"
              icon={SERVICE.calendar}
              onClick={() => this.handleCalendarEventShare(appointment)}
            />
          </div>
        </div>
      </div>
    );
  };

  renderConfirmButton = () => {
    if (!this.state.isAppointment || !this.state.data.allowConfirm) return;
    const appointmentId = this.state.id.substring(2);
    const margin = this.state.hasDiscount ? "mt-2" : "mt-4";

    return (
      <div className={`${margin}`}>
        <ConfirmAppointment
          appointmentId={appointmentId}
          token_cal={this.token_cal}
          onRenderInfoModal={(title, message) => this.renderInfoModal(title, message)}
          onConfirmation={() => this.setState(prevState => ({
            data: {
              ...prevState.data,
              allowConfirm: false
            }
          }))}
        />
      </div>
    )
  };

  renderCancelAppointment = () => {
    if (!this.state.isAppointment || !this.state.data.allowCancel) return;
    const appointmentId = this.state.id.substring(2);
    const margin = this.state.hasDiscount ? "mt-2" : "mt-2";
    return (
      <div className={`mb-3 ${margin}`}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div >
            <div
              role={"button"}
              onClick={() => this.handleCancelAppointment(appointmentId)}
              style={{ zIndex: 200 }}
            >
              <span className="link-container-span small">Cancelar Agendamento
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleCancelAppointment = (appointmentId) => {
    this.refModal.current.renderView(
      <Confirmation
        title="Cancelar Agendamento"
        text="Deseja cancelar o agendamento?"
        cancelText="Voltar atrás"
        confirmationHandle={() => this.handleCancelAppointmentAction(appointmentId)}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
    this.refModal.current.openModal();
  };

  handleCancelAppointmentAction = (appointmentId) => {
    try {
      ScheduleService.deleteAppointment(appointmentId).then((response) => {
        if (response?.success) {
          this.refModal.current.closeModal();
          // Emitter.emit("APPOINTMENTS_COUNT");
          CompanyService.getCompaniesCount();
          this.props.history.push(`/services`);
        } else {
          this.renderInfoModal(response?.title, response?.body);
        }
      });
    } catch (error) {
      console.error("handleScheduleConfirmation [catch error]", error);
    }
  };

  renderRequirements = () => {
    const {
      data: { symptoms, state, clientObs, is_cancelled, is_history },
    } = this.state;

    const renderGridContent = () => {
      return <RequirementList symptoms={symptoms} />;
    };
    return (
      <Grid
        styleClass="mt-4 mt-lg-0 mb-0 pt-0"
        title={Labels.cardCategoriaDetailsTitle}
        status={state.bars < 1 ? true : symptoms?.length > 0 || clientObs}
        icon={GENERAL.listIcon}
        hasObservationItem={true}
        hasSymptoms={symptoms?.length > 0}
        obsType={"message"}
        obsData={{
          observations: clientObs,
          readOnly:
            Helper.isLiteVersion() || (this.state.isAppointment && (is_cancelled || is_history)),
          noClickAction:
            !Helper.isLiteVersion() && this.state.isAppointment && (is_cancelled || is_history),
        }}
        onSaveObservation={(val) => this.saveRequirementObservation(val)}
      >
        {renderGridContent()}
      </Grid>
    );
  };

  saveRequirementObservation = (val) => {
    const { id } = this.state;
    const isAppointment = this.state.isAppointment;

    if (isAppointment) {
      ScheduleService.updateAppointmentObs(id, val).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            this.getServiceDetails();
          }
        } else {
          this.renderGenericError();
        }
      });
    } else {
      addClientServiceObs(id, this.cookies.get("sessionToken"), { obs: val }).then((value) => {
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            this.getServiceDetails();
          }
        } else {
          this.renderGenericError();
        }
      });
    }
  };

  renderInternalObs = () => {
    if (this.state.isAppointment) return;
    const {
      data: { internalObs },
    } = this.state;

    return (
      <Grid
        styleClass="mt-4 mb-0 pt-0"
        title={Labels.cardPersonalNotesTitle}
        status={internalObs}
        icon={GENERAL.notesIcon}
        hasObservationItem={true}
        hasSymptoms={false}
        obsType={"internal"}
        obsData={{ observations: internalObs, readOnly: Helper.isLiteVersion() }}
        onSaveObservation={(val) => this.saveInternalObs(val)}
      ></Grid>
    );
  };

  saveInternalObs = (val) => {
    const { id } = this.state;
    editPersonalServiceObs(id, this.cookies.get("sessionToken"), { obs: val }).then((data) => {
      if (data) {
        if (data.code) {
          this.handleValueCodeResponse(data);
        } else {
          this.getServiceDetails();
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  };

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}
      />
    );
  };

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  };

  handleScroll = () => {
    if (this.galleryRef.current) {
      const galleryRect = this.galleryRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (
        galleryRect.top <= windowHeight - 120 ||
        window.scrollY + windowHeight >= document.documentElement.scrollHeight
      ) {
        this.setState({ galleryButtonVisible: false });
      } else {
        this.setState({ galleryButtonVisible: true });
      }
    }
  };

  scrollToGallery = () => {
    if (this.galleryRef.current) {
      const galleryTop = this.galleryRef.current.offsetTop - 10;
      window.scrollTo({
        top: galleryTop,
        behavior: "smooth",
      });
    }
  };

  renderMediaIcon = () => {
    const { files, galleryButtonVisible } = this.state;
    const isMediaFiles =
      files?.customer?.documents?.length +
        files?.customer?.media?.length +
        files?.provider?.documents?.length +
        files?.provider?.media?.length >
      0;
    if (!isMediaFiles || !galleryButtonVisible) return null;
    return (
      <div className="media-button" onClick={this.scrollToGallery}>
        <img className={"icon-container"} src={GENERAL.mediaIcon} alt="mediaIcon" />
        <img className={"icon-container small"} src={GENERAL.iconSmallArrowDown} alt="mediaIcon" />
      </div>
    );
  };

  render() {
    const { isAppointment, data } = this.state;
    if (data) {
      return (
        <div className="main-details-container px-3 px-md-4 pt-3" id="main-details-container">
          <div className="center-container-left">
            {this.renderServiceDetailsCard()}

            <div className="visible-desktop" id="service-tasks-list">
              {!data.allowConfirm && this.renderCalendarEventShare()}
              {data.allowConfirm && this.renderConfirmButton()}
              {!this.isLiteVersion && this.renderCancelAppointment()}
              {data.allowConfirm && this.renderCalendarEventShare('small')}
              {this.renderTasksList()}
            </div>

            <div className="visible-mobile">
              {!data.allowConfirm && this.renderRequirements()}
              <div id="internal-notes-container-mobile">{this.renderInternalObs()}</div>
            </div>
          </div>

          <div className="center-container-right" id="center-container-right">
            <div className="visible-mobile" id="service-tasks-list-mobile">
              {!data.allowConfirm && this.renderCalendarEventShare()}
              {data.allowConfirm && this.renderConfirmButton()}
              {!this.isLiteVersion && this.renderCancelAppointment()}
              {data.allowConfirm && this.renderRequirements()}
              {data.allowConfirm && this.renderCalendarEventShare()}
              {!isAppointment && this.renderTasksList()}
            </div>

            <div className="visible-desktop">
              {this.renderRequirements()}
              <div id="internal-notes-container">{this.renderInternalObs()}</div>
            </div>
            {this.renderFilesSection()}
            {this.renderMediaIcon()}
          </div>
          <Modal ref={this.refModal} />
          <Helmet>
            {" "}
            <title> {Helper.titleServiceDetailsPage()} </title>{" "}
          </Helmet>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="main-details-container">
            <Modal ref={this.refModal} />
            <Helmet>
              {" "}
              <title> {Helper.titleServiceDetailsPage()} </title>{" "}
            </Helmet>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(ServiceDetails);
