import React, { useEffect, useState } from 'react';
import './ExpandedCompanyDetails.scss';
import CurrentCompanyDetailsInfo from '../ProvidersPage/CompanyDetails/CurrentCompanyDetailsInfo';

/**
 * @param {Object} props - The component props.
 * @param {Company} props.company - The company data.
 * @param {Company} props.isCurrentCompany - represents the current company
 * @param {() => void} props.onClose - A function to close the component.
 * @param {() => void} props.onAdd - A function to add the selected company.
 */
const ExpandedCompanyDetailsCard = (props) => {
  const [company, setCompany] = useState(props.company);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  return !props.isCurrentCompany ? null : (
    <div className={`company-details-card-container `}>
      <div className="company-details-card current">
        <div className="company-details-content-container flex-column align-center">
          <CurrentCompanyDetailsInfo company={{ ...company, town: company?.town }} />
        </div>
      </div>
    </div>
  );
}
export default ExpandedCompanyDetailsCard;
