import React, { useEffect, useState } from "react";
import CompanyCard from "../../../../../components/card/CompanyCard/CompanyCard";
import CompanyAddressInfo from "../../../../../components/card/CompanyCard/CompanyCardInfo/CompanyAddressInfo";
import CompanyPhoneInfo from "../../../../../components/card/CompanyCard/CompanyCardInfo/CompanyPhoneInfo";
import CompanyWebLinkInfo from "../../../../../components/card/CompanyCard/CompanyCardInfo/CompanyWebLinkInfo";

/**
 * @param {Object} props - The component props.
 * @param {Company | undefined} props.company - The company data to display.
 */
const CompanyDetailsInfo = (props) => {
  const [company, setCompany] = useState(props.company);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  return (
    <div className='company-details-container w-md-100'>
      <div className="company-details-header">
        <CompanyCard company={company} isDetailsCard={true} onSelect={() => {}}/>
      </div>
      <div className="company-details-info d-flex flex-column">
        <CompanyAddressInfo  company={company} />
        <CompanyPhoneInfo company={company} />
        <CompanyWebLinkInfo company={company} />
      </div>
    </div>
  );
};

export default CompanyDetailsInfo;
