import React from 'react';
import { GENERAL } from '../../../assets/images/images';
import Labels from '../../../variables/labels';
const GridTabs = (props) => {
  const { selectedTab, data: { media, documents } } = props;
  
  const selectTab = (n) => props.callback(n);

  const renderIcon = (icon) => {
    if (!icon) { return; }
    return <img className="d-none d-lg-block icon me-2" src={icon} alt="" />
  }

  return (
    <div className="media-tab-container d-inline-flex w-100 pt-2">
      <div className={`w-100 media-tab me-2 mb-2 ${selectedTab === 0 ? 'selected pb-2' : ''}`}
        onClick={() => selectTab(0)}>
        <div className="media-tab-text-container px-3 py-2 px-md-4 py-md-3">
          <div className='flex-start tab-text'>
            <span className='d-none d-lg-block'>{renderIcon(GENERAL.mediaIcon)}</span>
            <span className='d-none d-xl-block'>{Labels.galleryTitle.desktop}</span> 
            <span className='d-xl-none'>{Labels.galleryTitle.mobile}</span>
            <div className="tab-notification big">
              <div className="number big">{((media?.customer?.length ?? 0) + (media?.provider?.length ?? 0))}</div>

            </div>
          </div>
        </div>
      </div>
      <div className={`w-100 media-tab ms-2 mb-2 ${selectedTab === 1 ? 'selected pb-2' : ''}`}
        onClick={() => selectTab(1)}>
        <div className="media-tab-text-container px-3 py-2 px-md-4 py-md-3">
          <span className="flex-start tab-text">
            {renderIcon(GENERAL.docsIcon)}
            <span>{Labels.documentsTitle}</span>
            <div className="tab-notification big">
              <div className="number big">{((documents?.customer?.length ?? 0) + (documents?.provider?.length ?? 0))}</div>

            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
export default GridTabs;
