// React Imports
import React, { Component } from "react";
import Emitter from "../../core/services";
import "./notifications.style.scss";
import Labels from "../../variables/labels";
import LocalData from "../../core/localData";
import {
  deleteNotification,
  readAllNotifications,
  readNotification,
  requestNotificationsList,
  requestNotificationsListChanges,
} from "../../api/routes/api.route";
import PoweredBy from "../logo/PoweredBy";
import Cookies from "universal-cookie";
import NotificationsListVirtualized from "./notificationsListVirtualized";
import { GENERAL } from "../../assets/images/images";
import AndroidBack from "../../core/services/androidBack.service";
import moment from "moment";
import classNames from "classnames";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.cookies = new Cookies();
    this.timestamp = null;
    this.state = {
      isOpen: false,
      notifications: undefined,
      notificationsState: false,
      notificationsLoadError: false,
      notificationsLoadingState: true,
      isMobile: false,
      footerTop: undefined,
      isSmallList: false,
    };
  }

  componentDidMount() {
    if (!this.isLiteVersion && LocalData.terms_accepted) {
      this.getNotificationsListener();
      this.getNotifications();
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.autoRefreshNotifications();

    Emitter.on("NOTIFICATIONS_SIDEBAR_OPEN", () => {
      if (this.state.isOpen) return;

      this.setState({ isOpen: true });
      let bar = document.getElementById("notifications-sidebar");
      let barLayer = document.getElementById("notifications-sidebar-layer");
      let body = document.body;
      body.style.overflowY = "hidden";
      bar.className += " open";
      barLayer.className += " notifications-sidebar-layer-open";

      AndroidBack.setNotificationsOpen(true);
      setTimeout(() => {
        this.setListHeight();
      }, 100);
    });
    Emitter.on("NOTIFICATIONS_SIDEBAR_CLOSE", () => {
      if (!this.state.isOpen) return;

      setTimeout(() => {
        this.setState({ isOpen: false });
      }, 300);
      let bar = document.getElementById("notifications-sidebar");
      let barLayer = document.getElementById("notifications-sidebar-layer");
      let body = document.body;
      body.style.overflowY = "auto";
      bar.className = bar.className.replace(" open", "");
      barLayer.className = barLayer.className.replace(" notifications-sidebar-layer-open", "");

      AndroidBack.setNotificationsOpen(false);
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    Emitter.off("NOTIFICATIONS_SIDEBAR_OPEN");
    Emitter.off("NOTIFICATIONS_SIDEBAR_CLOSE");
    Emitter.off("NOTIFICATIONS_UPDATE");
    window.removeEventListener("resize", this.handleResize);
  }

  getNotificationsListener = () => {
    Emitter.on("NOTIFICATIONS_UPDATE", (notifications) => {
      this.setState({ notifications });
      this.setListHeight();
    });
  };

  autoRefreshNotifications = () => {
    this.timer = setTimeout(() => {
      this.refreshNotifications();
      this.autoRefreshNotifications();
    }, 60000);
  };

  updateLastTimestamp = (data) => {
    if (data && Array.isArray(data) && data.length > 0) {
      let returnedDate = { id: data[0].id, timestamp: data[0].timestamp };
      let lastCalculatedDate = returnedDate;
      let listedDates = [];
      try {
        data.forEach((d) => {
          d.timestamp && listedDates.push({ id: d.id, date: d.timestamp });
          d.changed && listedDates.push({ id: d.id, date: d.changed });
        });
        lastCalculatedDate = listedDates.reduce((a, b) => {
          return moment(b.date).isSameOrAfter(moment(a.date)) ? b : a;
        });
        returnedDate.timestamp = lastCalculatedDate.date;
      } catch (error) {
        console.error(error);
      }
      this.timestamp = returnedDate;
    }
  };

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
    this.setListHeight();
  };

  setListHeight = () => {
    const footerEl = document.getElementById("notifications-container-footer");
    const listContainer = document.querySelector(".ReactVirtualized__Grid__innerScrollContainer");
    const headerEl = document.getElementById("notifications-container-header");
    if (footerEl && listContainer && headerEl) {
      const listHeight = listContainer.getBoundingClientRect().height;
      const footerHeight = footerEl.getBoundingClientRect().height;
      const headerHeight = headerEl.getBoundingClientRect().height;
      this.setState({
        isSmallList: window.innerHeight - headerHeight - footerHeight > listHeight,
        footerTop: headerHeight + listHeight,
      });
    }
  };

  refreshNotifications = () => {
    const actualData = this.state.notifications;

    const handleData = (data) => {
      const actualDataIds = actualData.map((a) => a.id);
      const newNotifications = data.filter((d) => !actualDataIds.includes(d.id));
      const changedNotifications = actualData.map((n) => {
        return data.map((a) => a.id).includes(n.id) ? data.find((a) => a.id === n.id) : n;
      });
      const newData = newNotifications.concat(changedNotifications);

      if (newData) {
        this.updateLastTimestamp(newData);
        this.setState({
          notifications: newData,
          notificationsState: true,
          notificationsLoadError: false,
          notificationsLoadingState: false,
          isLoading: false,
        });
        LocalData.notificationsData["notificationsList"] = newData;
        Emitter.emit("NOTIFICATIONS_UPDATE", newData);
      }
    };

    if (actualData && Array.isArray(actualData) && actualData.length > 0 && this.timestamp) {
      requestNotificationsListChanges(this.cookies.get("sessionToken"), {
        id: this.timestamp.id,
        last: this.timestamp.timestamp,
      }).then((response) => {
        if (response) {
          if (!response.code) {
            const list =
              response &&
              "data" in response &&
              response.data &&
              "all" in response.data &&
              response.data.all &&
              "all" in response.data.all &&
              response.data.all.all
                ? response.data.all.all
                : [];
            handleData(list);
          }
        } else {
          this.renderGenericError();
        }
      });
    } else {
      this.getNotificationsList();
    }
  };

  getNotificationsList = () => {
    const handleData = (data) => {
      if (data) {
        this.updateLastTimestamp(data);

        this.setState({
          notifications: data,
          notificationsState: true,
          notificationsLoadError: false,
          notificationsLoadingState: false,
          isLoading: false,
        });
        LocalData.notificationsData["notificationsList"] = data;
        Emitter.emit("NOTIFICATIONS_UPDATE", data);
      } else {
        this.setState({
          notificationsState: true,
          notificationsLoadError: true,
          notificationsLoadingState: false,
          isLoading: false,
        });
      }
    };

    requestNotificationsList(this.cookies.get("sessionToken")).then((response) => {
      if (response) {
        if (response.code) {
          handleData();
        } else {
          const list =
            response &&
            "data" in response &&
            response.data &&
            "all" in response.data &&
            response.data.all &&
            "all" in response.data.all &&
            response.data.all.all
              ? response.data.all.all
              : [];
          handleData(list);
        }
      } else {
        this.renderGenericError();
      }
    });

    if (!LocalData.notificationsData?.notificationsList) {
      return;
    }

    this.setState({
      notifications: LocalData.notificationsData?.notificationsList,
      notificationsState: true,
      notificationsLoadError: false,
      notificationsLoadingState: false,
      isLoading: false,
    });
  };

  handleDeleteNotify = (id) => {
    deleteNotification(this.cookies.get("sessionToken"), id).then((response) => {
      if (response) {
        if (!response.code && !response.http_code) {
          const { notifications } = this.state;

          const newData = notifications.filter((n) => n.id !== id);
          this.setState({
            notifications: newData,
          });
          LocalData.notificationsData["notificationsList"] = newData;
          Emitter.emit("NOTIFICATIONS_UPDATE", newData);
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  handleReadNotify = (id, fullUrl) => {
    readNotification(this.cookies.get("sessionToken"), id).then((response) => {
      if (response) {
        if (!response.code && !response.http_code) {
          const { notifications } = this.state;

          const newData = notifications.map((n) => {
            return n.id === id ? Object.assign({}, n, { read: true }) : n;
          });
          this.setState({
            notifications: newData,
          });
          LocalData.notificationsData["notificationsList"] = newData;
          Emitter.emit("NOTIFICATIONS_UPDATE", newData);
          if (fullUrl) {
            const urlObj = new URL(fullUrl);
            const urlParams = new URLSearchParams(urlObj.search);
            urlParams.set("s", "NI");
            urlObj.search = urlParams.toString();
            // if (window.location.hostname === "localhost") {
            // 	urlObj.protocol = "http";
            // 	urlObj.host = "localhost";
            // 	urlObj.port = "3000";
            // }
            const newUrl = urlObj.toString();
            newUrl && window.open(newUrl, "_self");
          }
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  handleReadAllNotifications = () => {
    readAllNotifications(this.cookies.get("sessionToken")).then((response) => {
      if (response) {
        if (!response.code && !response.http_code) {
          const { notifications } = this.state;

          const newData = notifications.map((n) => {
            return Object.assign({}, n, { read: true });
          });
          this.setState({
            notifications: newData,
          });
          LocalData.notificationsData["notificationsList"] = newData;
          Emitter.emit("NOTIFICATIONS_UPDATE", newData);
        }
      } else {
        this.renderGenericError();
      }
    });
  };

  renderNotificationsList = () => {
    const { notifications, footerTop, isSmallList } = this.state;
    const footerClassName = classNames("notifications-container-footer w-100 flex-center", {
      "position-relative": !notifications?.length,
      "position-fixed": isSmallList,
    });

    return (
      <div className="notifications-container-group">
        <div
          id="notifications-container-header"
          className="notifications-container-header"
          onClick={() => Emitter.emit("NOTIFICATIONS_SIDEBAR_CLOSE")}
        >
          <div className="notifications-container-button">
            <svg viewBox="0 0 33 33" width="24px" height="24px">
              <g fill="none" fillRule="evenodd">
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            fill={"#D8D8D8"}
                            fillOpacity="0"
                            d="M0.524 0.5H32.524V32.5H0.524z"
                            transform="translate(-1130 -444) translate(50 185) translate(0 50) translate(24 81) translate(1056 128)"
                          />
                          <path
                            stroke={"#22272A"}
                            strokeLinecap="round"
                            strokeWidth="2"
                            d="M20.858 26.48l-9.716-9.59m9.716-9.723l-9.428 9.428"
                            transform="translate(-1130 -444) translate(50 185) translate(0 50) translate(24 81) translate(1056 128)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="notifications-container-title">{Labels.notificationsList.title}</div>
        </div>
        <div
          className={"notifications-container-list"}
          style={{ flex: !notifications?.length && "unset" }}
        >
          {notifications && notifications.length > 0 && (
            <NotificationsListVirtualized
              notifications={notifications}
              handleReadNotify={this.handleReadNotify}
              handleDeleteNotify={this.handleDeleteNotify}
            />
          )}
          {(!notifications || notifications.length === 0) && (
            <div className="notification-item-no-notifications">{"Sem notificações"}</div>
          )}
        </div>
        {this.state.isMobile && (
          <div
            id="notifications-container-footer"
            className={footerClassName}
            style={{ top: footerTop }}
          >
            <div style={{ width: "10rem" }}>
              <PoweredBy />
            </div>
          </div>
        )}
      </div>
    );
  };

  getNotifications() {
    /**
     * Pedido para retornar notificações
     *
     * @param {string} token - token de sessão
     */
    this.getNotificationsList();
  }

  render() {
    return (
      <>
        <div id="notifications-sidebar" className="notifications-sidebar">
          {this.state.isOpen && (
            <>
              {this.renderNotificationsList()}
              <div
                className="button-close-container"
                role={"button"}
                onClick={() => Emitter.emit("NOTIFICATIONS_SIDEBAR_CLOSE")}
              >
                <img src={GENERAL.closeIcon} alt="close_notifications" />
              </div>
            </>
          )}
        </div>
        <div
          id="notifications-sidebar-layer"
          className="notifications-sidebar-layer"
          onClick={() => Emitter.emit("NOTIFICATIONS_SIDEBAR_CLOSE")}
        ></div>
      </>
    );
  }
}

export default Notifications;
