export default class SidebarHelper {
  static isHomePage = (pathname) => {
    /**@type {boolean} */
    return (
      pathname.toString().includes('services')
      || pathname.toString().includes('services-lite')
    );
  };
  
  static isAccountPage = (pathname) => {
    /**@type {boolean} */
    return (
      pathname.toString().includes('perfil')
      || pathname.toString().includes('dados-cliente')
      || pathname.toString().includes('alterar-password')
      || pathname.toString().includes('enviar-feedback')
      || pathname.toString().includes('suporte-cliente')
    );
  };
  
  static isItemsPage = (pathname) => (/** @type {boolean} */ pathname.toString().includes('equipment-details'));
  static isProfilePage = (pathname) => {
    /**@type {boolean} */
    return (
      pathname.toString().includes('perfil')
      || pathname.toString().includes('dados-cliente')
    );
  };

  static checkBoxShadow = (id) => {
    const container = document.getElementById(id);
    const barContainer = document.getElementById("bar-container");
    const scrollBaseContainer = document.getElementById("scroll-base-container");
    if (container.scrollHeight - container.scrollTop - container.clientHeight < 80) {
      barContainer.classList.add("end");
      if (scrollBaseContainer) {
        scrollBaseContainer.classList.add("end");
      }
    } else {
      barContainer.classList.remove("end");
      if (scrollBaseContainer) {
        scrollBaseContainer.classList.remove("end");
      }
    }
  }
}
