import { linkKeymasterAnalyticsAPI, sendWorkAnalytics} from "../../api/routes/api.route";
import Helper from "../helper/helper";

export const linkKeymasterAnalytics = () => {
  linkKeymasterAnalyticsAPI();
};

const analyzeWorkUrl = (url) => {
  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  const searchParams = urlObj.searchParams;
  const workMatch = pathname.match(/(A-\w+|C-\w+|S-\w+)/);
  const work = workMatch ? workMatch[0] : null;
  const source = searchParams.get('s');
  const type = searchParams.get('t');
  return { work, source, type };
};

export const analyseUrlWorkAnalytics = (url) => {
  const registered = true;
  const { work, source, type } = analyzeWorkUrl(url);
  if (work) {
    sendWorkAnalytics(work, source, type, registered);
  }
};

const analyzeShowcaseUrl = (url) => {
  const urlObj = new URL(url);
  const searchParams = urlObj.searchParams;
  const source = searchParams.get('s');
  const type = searchParams.get('t');
  const token = searchParams.get('token') || searchParams.get('token_cal');
  const iscal = searchParams.has('token_cal');
  return { token, source, type, iscal };
};

export const analyseUrlShowcaseAnalytics = (url, isRegistered, associatedWorkId) => {
  if (Helper.isFO()) return;
  const { source, type } = analyzeShowcaseUrl(url);
  if (associatedWorkId != null && isRegistered != null) {
    sendWorkAnalytics(associatedWorkId, source, type, isRegistered);
  }
};

