import React, { useEffect, useState } from 'react';
import './CompanyDetails.scss';
import CompanyDetailsAuthentication from './CompanyDetailsAuthentication';
import CompanyDetailsInfo from './CompanyDetailsInfo';
import { GENERAL } from '../../../../../assets/images/images';

/**
 * @param {Object} props - The component props.
 * @param {Company} props.company - The company data.
 * @param {Company} props.isCurrentCompany - represents the current company
 * @param {() => void} props.onClose - A function to close the component.
 * @param {() => void} props.onAdd - A function to add the selected company.
 */
const CompanyDetailsCard = (props) => {
  const [company, setCompany] = useState(props.company);

  useEffect(() => {
    setCompany(props.company);
  }, [props.company]);

  return (
    <div className={`company-details-card-container mt-3 ${props.isCurrentCompany ? 'current' : ''}`}>
      <div className="company-details-card mt-3 mt-md-4 ">
        <div className="company-details-content-container flex-column align-center">
          <div className="icon-close" role="button" onClick={props.onClose}>
            <img src={GENERAL.iconClose} alt="icon-modal-close" />
          </div>
          <CompanyDetailsInfo company={company} />
          <div className="company-details-content-separator"></div>
          <CompanyDetailsAuthentication
            company={company}
            onClose={props.onClose}
            onAdd={props.onAdd}
          />
        </div>
      </div>
    </div>
  );
}
export default CompanyDetailsCard;
