import React from "react";
import LocalData from "../../core/localData";
import Helper from "../../core/helper/helper";
import CompanyDetailsAuthentication from "../../views/SidebarMenu/MenuKey/ProvidersPage/CompanyDetails/CompanyDetailsAuthentication";

const IAgreeBox = ({url}) => {
  if (LocalData.terms_accepted || Helper.isLiteVersion() || Helper.isDemo()) return null;
  return (<>
    {LocalData.configData?.companyDetails &&
      <div className={`company-details-card-container mt-3`} >
        <div className="company-details-card mt-3 mt-md-4" style={{ maxWidth: '768px' }}>
          <div className="company-details-content-container flex-column align-center">
            <CompanyDetailsAuthentication
              company={LocalData.configData?.companyDetails}
              termsChanged='true'
              onAdd={() => {
                if (!Helper.isDemo()) {
                  const fullUrl = new URL(url);
                  fullUrl.searchParams.set("iagree", "true");
                  window.open(fullUrl.toString(), '_self');
                }
              }}
            />
          </div>
        </div>
      </div>
    }
  </>)
};

export default IAgreeBox;