import React, { Component, createRef } from 'react';
import Labels from '../../../variables/labels';
import Helper from '../../../core/helper/helper';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader/loader';
import MyEquipments from '../myEquipments.view.jsx';
import AppointmentCard from '../../../components/card/appointment/appointmentCard.jsx';
import ScheduleService from '../../schedule/core/services/schedule.service.js';
import Modal, { Information, Confirmation, MultiOptions } from "../../../components/modal/modal";
import { GENERAL } from '../../../assets/images/images';
import CompanyDetailsInfo from '../MenuKey/ProvidersPage/CompanyDetails/CompanyDetailsInfo';
import LocalData from '../../../core/localData.js';
import { HelperSchedule } from '../../schedule/core/helpers/helperSchedule.js';
import Emitter from '../../../core/services.js';
import SidebarHelper from '../../../core/helper/sidebarHelper.js';
import AndroidBack from '../../../core/services/androidBack.service.js';
import IAgreeBox from '../../../components/iagree/iagreeBox.jsx';
import CompanyCardList from '../../../components/sidebar/CompanyCardList.jsx';
import CompanyService from '../../../core/services/company.service.js';

const cLabels = {
  title: "Os seus agendamentos pendentes",
  title2: "Os seus agendamentos antigos",
  noAppointments: "Sem agendamentos pendentes!",
  noAppointments2: "Sem agendamentos antigos",
  errorEmptyAppointments: "Não foi possível aceder aos seus agendamentos.",
  chooseEquip: "Selecione o equipamento para o qual deseja realizar o seu agendamento:"
};
class MyAppointments extends Component {
  constructor(props) {
    super(props);
    this.refModal = createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.searchParams = new URLSearchParams(this.props.location?.search);
    this.is_cal_token = this.isLiteVersion && this.searchParams.get("token_cal");
    this.state = {
      pendingAppointments: [],
      oldAppointments: [],
      showSelectCompany: false,
      showMyEquipments:
        (LocalData.isSchedule || props.state.navOptionParams.showMyEquipments) ?? false,
      showOldAppointments: props.state.navOptionParams.showOldAppointments ?? false,
      isLoading: !this.isLiteVersion,
      config: Helper.getConfig(),
      userCompanies: [],
      userCompaniesLoading: true,
      selectedCompany: undefined,
      selectCompanyScrollHeight: 0,
      bottomBarTop: 0,
      appointmentsListHeight: "auto",
      hideBottomShadow: false,
    };
  }

  componentDidMount() {
    if (!this.isLiteVersion) this.getAppointments();

    AndroidBack.setScheduleMenuOld(this.state.showOldAppointments);

    Emitter.on("OLD_APPOINTMENTS_VISIBLE_BACK", () => {
      this.handleClickToggleShowOldAppointments(this.state.showOldAppointments);
    });

    Emitter.on("COMPANIES_LOADED", () => {
      this.updateCompaniesList();
    });

    if (!Helper.isLiteVersion()) {
      if (!LocalData.companies) {
        CompanyService.getUserCompaniesList();
      } else {
        this.updateCompaniesList();
      }
    }

    const element = document.getElementById("bar-container");
    if (element) {
      const elementY = element.getBoundingClientRect().y;
      const top = window.innerHeight - elementY;
      this.setState({ bottomBarTop: top });
    }

    // this.calculateSelectCompanyHeight();
    setTimeout(() => {
      this.setListHeight();
    }, 200);
    window.addEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { showSelectCompany, showOldAppointments } = this.state;
    setTimeout(() => {      
      if (showSelectCompany) {
        this.calculateSelectCompanyHeight();
      }
  
      if (showOldAppointments) {
        this.setOldListHeight();
      }
  
      if (!showSelectCompany && !showOldAppointments) {
        this.setListHeight();
      }
    }, 200);
  };

  // Method to calculate the height of elements dynamically
  calculateSelectCompanyHeight = () => {
    const scrollable = document.getElementById("select-schedule-company");
    if (scrollable) {
      const scrollY = scrollable.getBoundingClientRect().y;
      if (window.innerWidth > 768) {
        this.setState({
          selectCompanyScrollHeight: window.innerHeight - scrollY,
          bottomBarTop: 0,
        });
        return;
      }
      const element = document.getElementById("bar-container");
      if (element) {
        const elementY = element.getBoundingClientRect().y;
        const top = window.innerHeight - elementY;
        const scrollHeight = window.innerHeight - scrollY - top;

        // Set the calculated scroll height in the component's state
        this.setState({ selectCompanyScrollHeight: scrollHeight, bottomBarTop: top });
      }
    }
  };

  updateCompaniesList = () => {
    const newList = [...LocalData.companies];
    this.setState({
      userCompanies: newList || [],
      userCompaniesLoading: false,
    });
    // const companyDetails = LocalData.configData?.companyDetails;
    // if (companyDetails) {
    //   newList.unshift(companyDetails);
    // }
  };

  componentDidUpdate(_, prevState) {
    // Check if the isOpen state has changed
    if (this.state.showOldAppointments !== prevState.showOldAppointments) {
      // Call AndroidBack.setMenuOpen with the updated isOpen state
      AndroidBack.setScheduleMenuOld(this.state.showOldAppointments);
    }

    if (this.state.showSelectCompany && !prevState.showSelectCompany) {
      this.selectCompanyScrollListener();
    }
  }

  componentWillUnmount() {
    AndroidBack.setScheduleMenuOld(false);
    Emitter.off("OLD_APPOINTMENTS_VISIBLE_BACK");
    Emitter.off("COMPANIES_LOADED");
    window.removeEventListener("resize", this.handleWindowResize);
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener("scroll", this.handleScrollEvent);
    }
  }

  selectCompanyScrollListener = () => {
    const scrollContainer = document.getElementById("select-schedule-company");
    // Adiciona listener apenas se o container estiver visível
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScrollEvent);
      this.scrollContainer = scrollContainer;
    }
  };

  myAppointmentsScrollListener = () => {
    const scrollContainer = document.getElementById("myAppointmentsContainer");
    // Adiciona listener apenas se o container estiver visível
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScrollEvent);
      this.scrollContainer = scrollContainer;
    }
  };

  // Handler do evento de scroll
  handleScrollEvent = (event) => {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight; // Altura total do conteúdo
    const clientHeight = event.target.clientHeight;
    if (scrollHeight - scrollTop - clientHeight < 10) {
      this.setState({ hideBottomShadow: true });
    } else {
      this.setState({ hideBottomShadow: false });
    }
  };

  // Method to calculate the height of elements dynamically
  setListHeight = () => {
    const scrollable = document.getElementById("myAppointmentsContainer");
    if (scrollable) {
      const scrollTop = scrollable.getBoundingClientRect().top;
      const element = document.getElementById("addNewSchedule");
      
      if (element) {
        const elementHeight = element.getBoundingClientRect().height;
        
        if (window.innerWidth > 768) {
          const listHeight =
            window.innerHeight - scrollTop - elementHeight;

          const smallContainer = scrollable.scrollHeight <= listHeight;
          const hideBottomShadow =
          scrollable.scrollHeight - scrollable.scrollTop - listHeight < 10 || smallContainer;

          this.setState({
            appointmentsListHeight: listHeight,
            bottomBarTop: 0,
            hideBottomShadow,
            smallContainer
          });

        } else {
          const bar = document.getElementById("bar-container");
          if (bar) {
            const barHeight = bar.getBoundingClientRect().height;
            const listHeight = window.innerHeight - scrollTop - barHeight - elementHeight;
            const smallContainer = scrollable.scrollHeight <= listHeight;
            const hideBottomShadow =
              scrollable.scrollHeight - scrollable.scrollTop - listHeight < 10 ||
              smallContainer;

            this.setState({
              appointmentsListHeight: listHeight,
              bottomBarTop: barHeight,
              hideBottomShadow,
              smallContainer
            });
          }
        }
      }
    }
  };

  // Method to calculate the height of elements dynamically
  setOldListHeight = () => {
    const scrollable = document.getElementById("myAppointmentsContainer");
    if (scrollable) {
      const scrollTop = scrollable.getBoundingClientRect().top; 
        
      if (window.innerWidth > 768) {
        const listHeight =
          window.innerHeight - scrollTop;
        const smallContainer = scrollable.scrollHeight <= listHeight;
        const hideBottomShadow =
        scrollable.scrollHeight - scrollable.scrollTop - scrollable.scrollHeight < 10 || smallContainer;



        this.setState({
          appointmentsListHeight: listHeight,
          bottomBarTop: 0,
          hideBottomShadow,
          smallContainer
        });

      } else {
        const bar = document.getElementById("bar-container");
        if (bar) {
          const barHeight = bar.getBoundingClientRect().height;
          const listHeight =
            window.innerHeight -
            scrollTop -
            barHeight;
          const smallContainer = scrollable.scrollHeight <= listHeight;
          const hideBottomShadow =
            scrollable.scrollHeight - scrollable.scrollTop - listHeight < 10 ||
            smallContainer;

          this.setState({
            appointmentsListHeight: listHeight,
            bottomBarTop: barHeight,
            hideBottomShadow,
            smallContainer
          });
        }
      }
    }
  };

  handleSelectScheduleItem = (equipmentId) => {
    this.props.openDrawerView(); //toggle sidebar
    const token = LocalData.equipmentsToken !== "0" ? LocalData.equipmentsToken : "";
    const tokenCal = LocalData.equipmentsTokenCal !== "0" ? LocalData.equipmentsTokenCal : "";
    if (this.isLiteVersion) {
      this.props.history.push(
        `/schedule-lite/${equipmentId}${
          token ? `?token=${token}` : tokenCal ? `?token_cal=${tokenCal}` : ""
        }`
      );
    } else {
      LocalData.isSchedule = false;
      this.props.history.push(`/schedule/${equipmentId}`);
    }
  };

  handleClickNewSchedule = () => {
    if (LocalData.companies?.length > 1) {
      this.setState({ showSelectCompany: true, showMyEquipments: false }, () =>
        this.calculateSelectCompanyHeight()
      );
    } else {
      this.setState({ showMyEquipments: true, showSelectCompany: false });
    }
  };

  handleClickToggleShowOldAppointments = () => {
    this.setState(
      (prevState) => ({
        showOldAppointments: !prevState.showOldAppointments,
        appointmentsListHeight: "auto",
      }),
      () => {
        // Scroll to the top of the container after state update
        const container = document.getElementById("myAppointmentsContainer");
        if (container) {
          container.scrollTo({ top: 0, behavior: "smooth" });
        }
        
       setTimeout(() => {
        if (this.state.showOldAppointments) {
          this.setOldListHeight();
        } else {
          this.setListHeight();
        }
       }, 200);
      }
    );
  };

  filterNotSharedWorksFromAppointments = () => {
    const { servicesData: listWorks } = LocalData;
    const { pendingAppointments, oldAppointments } = this.state;

    // Helper function to filter work IDs
    const getFilteredIds = (worksArray) =>
      worksArray?.map((work) => work?.id?.replace(/^[AC]-/, ""));

    // Get filtered work IDs for progress and completed works
    const progressWorksIds = listWorks?.progressWorks
      ? getFilteredIds(listWorks?.progressWorks)
      : [];
    const completedWorksIds = listWorks?.completedWorks
      ? getFilteredIds(listWorks?.completedWorks)
      : [];
    const combinedIds = new Set([...progressWorksIds, ...completedWorksIds]); // Use a Set for faster lookups

    // Helper function to filter appointments
    const filterAppointments = (appointments) =>
      appointments.map(({ work_id, ...rest }) =>
        work_id && !combinedIds?.has(work_id) ? rest : { work_id, ...rest }
      );

    // Filter pending and old appointments
    const updatedPendingAppointments = filterAppointments(pendingAppointments);
    const updatedOldAppointments = filterAppointments(oldAppointments);

    // Update state and trigger side effect
    this.setState(
      {
        pendingAppointments: updatedPendingAppointments,
        oldAppointments: updatedOldAppointments,
      },
      () => SidebarHelper.checkBoxShadow("myAppointmentsContainer") // Call this after state update
    );
  };

  getAppointments = async () => {
    if (this.isLiteVersion) {
      const { pending, history } = undefined;
      this.setState({
        pendingAppointments: pending,
        oldAppointments: history,
        isLoading: false
      });
    } else {
      if (LocalData.terms_accepted) {
        try {
          const { pending, history } = await ScheduleService.fetchScheduleAppointments();
          this.setState(
            {
              isLoading: false,
              pendingAppointments:  pending,
              oldAppointments: history,
            },
            () => {
              setTimeout(() => {
                this.setListHeight();
                this.myAppointmentsScrollListener();
              }, 100);
            }
          );
          //this.filterNotSharedWorksFromAppointments(); //removed filter to enable showing "Não compareceu" on appointments without work_id
          // Emitter.emit("APPOINTMENTS_COUNT");
          CompanyService.getCompaniesCount();
        } catch (error) {
          this.setState({ isLoading: false});
          console.error(error);
        }
      }
    }
  };

  handleRepeatAppointment = (repeatAppointment) => {
    const domain = repeatAppointment.domain;
    const url = `https://${domain}.keymaster.pt/client/schedule/${repeatAppointment.item_id}`;
    this.props.handleBackButton();

    localStorage.setItem("repeatAppointment", JSON.stringify(repeatAppointment));
    window.location.href = url;
  };

  handleCalendarEventShare = (appointment) => {
    if (Helper.isAndroidWebview()) {
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    if (Helper.isIOSWebview()) {
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    if (Helper.isAndroidBrowser() || Helper.isIOS()) {
      HelperSchedule.createICalAndShare(appointment);
      return;
    }
    this.refModal.current.renderView(
      <MultiOptions
        title="Adicionar a Outros Calendários"
        option1Text="Calendário Google"
        icon1={GENERAL.iconGoogle}
        option2Text="Calendário Apple"
        icon2={GENERAL.iconApple}
        option3Text="Calendário Outlook"
        icon3={GENERAL.iconOutlook}
        handleOption1={() => {
          HelperSchedule.createGoogleCalendarUrl(appointment);
        }}
        handleOption2={() => {
          HelperSchedule.createICalAndShare(appointment);
        }}
        handleOption3={() => {
          HelperSchedule.createICalAndShare(appointment);
        }}
      />
    );
    this.refModal.current.openModal();
  };

  handleCancelAppointment = (appointmentId) => {
    this.refModal.current.renderView(
      <Confirmation
        title="Cancelar Agendamento"
        text="Deseja cancelar o agendamento?"
        cancelText="Voltar atrás"
        confirmationHandle={() => this.handleCancelAppointmentAction(appointmentId)}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
    this.refModal.current.openModal();
  };

  handleCancelAppointmentAction = (appointmentId) => {
    try {
      ScheduleService.deleteAppointment(appointmentId).then((response) => {
        if (response?.success) {
          this.getAppointments();
          // CompanyService.getCompaniesCount();
          this.refModal.current.closeModal();
        } else {
          this.renderInfoModal(response?.title, response?.body);
        }
      });
    } catch (error) {
      console.error("handleScheduleConfirmation [catch error]", error);
    }
  };

  /**
   * Evento de click do serviço selecionado
   *
   * @param {number} id - id do serviço
   * @param {string} info - objecto da serviço selecionado
   */
  handlePressEvent = (id, info) => {
    const domain = info.domain;
    const url = `https://${domain}.keymaster.pt/client/service-details/${id}?s=I&t=0`;
    this.props.handleBackButton();
    window.location.href = url;
  };

  handleSelectedCompany = (c) => {
    if (c.domain === Helper.companyDomain()) {
      this.setState({ selectedCompany: null, showMyEquipments: true, showSelectCompany: false });
    } else if (c?.schedulerModule !== "FULL") {
      this.setState({ selectedCompany: c, showMyEquipments: true, showSelectCompany: false });
    } else {
      const url =
        process.env.NODE_ENV === "development"
          ? new URL(window.location.href)
          : new URL(`https://${c.domain}.keymaster.pt/client/services`);

      url.searchParams.set("openAppointment", "true");
      url.searchParams.set("schedule", "true");
      window.location.href = url.toString();
    }
  };

  renderNewScheduleButton = () => {
    let companyName = '';
    if (LocalData?.companies?.length === 1 ) companyName = Helper.companyName().replace(/ /g, '\u00A0');
    const schedulerLabel = `${Labels.schedulers.newscheduler} ${companyName}`;
    return (
      <div
        className={`cta-bottom-container mt-5 w-100 px-1 flex-center ${
          this.is_cal_token ? "mb-5" : ""
        } `}
      >
        <div
          className="cta-button-primary dark-shadow w-100 d-flex d-inline-flex px-3 justify-content-center align-items-center"
          role="button"
          onClick={this.handleClickNewSchedule}
        >
          <span className="text-multiline-truncate">{schedulerLabel}</span>
        </div>
      </div>
    );
  };

  renderOldAppointmentsLink = () => {
    const { oldAppointmentsLink } = Labels.sidebarScheduleMessage();
    if (this.state.showOldAppointments) return null;
    return (
      <div className="link-container mt-4 mb-4 text-center">
        <span onClick={() => this.handleClickToggleShowOldAppointments()}>
          {" "}
          {/*TODO: Update state that hides the link, scrolls up, and shows previous appointments */}
          {oldAppointmentsLink}
        </span>
      </div>
    );
  };

  renderOldAppointments = () => {
    const { oldAppointments, bottomBarTop, hideBottomShadow } = this.state;
    return (
      <div>
        {this.state.showOldAppointments && oldAppointments && (
          <div>
            {oldAppointments.map((appointment, index) => (
              <AppointmentCard
                key={`old_${appointment.id}_ ${index}`}
                appointment={appointment}
                onRepeatAppointment={() => this.handleRepeatAppointment(appointment)}
                oldAppointments={true}
                onClick={() =>
                  appointment.work_id
                    ? this.handlePressEvent("A-" + appointment.work_id, appointment)
                    : this.handlePressEvent("S-" + appointment.id, appointment)
                }
              />
            ))}
            <div
            id="scrollBaseContainer"
              style={{ bottom: bottomBarTop - 2 }}
              className={`scroll-base-container ${hideBottomShadow ? "end" : ""}`}
            ></div>
          </div>
        )}
      </div>
    );
  };

  renderAppointmentsSidebarTitles = () => {
    const { pendingAppointments } = this.state;
    if (!this.state.showMyEquipments && !this.state.showOldAppointments) {
      return (
        <>
          <p className="pb-2 menu-state-message"> {cLabels.title} </p>
          {!pendingAppointments?.length && (
            <div className="menu-info-noAppointments mt-3">
              <div className="menu-info pt-4 pb-5 normal-light-white">{cLabels.noAppointments}</div>
            </div>
          )}
        </>
      );
    }
    if (!this.state.showMyEquipments && this.state.showOldAppointments) {
      return (
        <>
          <div className="link-container mt-2 mb-4 text-center">
            <span onClick={() => this.handleClickToggleShowOldAppointments()}>
              {" "}
              {/*TODO: Update state that hides the link, scrolls up, and shows previous appointments */}
              {"Voltar para agendamentos pendentes"}
            </span>
          </div>
          <div className="pb-2 menu-state-message">
            {" "}
            {this.state.oldAppointments?.length ? cLabels.title2 : cLabels.noAppointments2}{" "}
          </div>
        </>
      );
    }
    if (
      !this.state.showMyEquipments &&
      !pendingAppointments?.length &&
      !this.state.showOldAppointments
    ) {
      return (
        <div className="menu-info-noAppointments">
          <div className="menu-info pt-4 pb-5 normal-light-white">{cLabels.noAppointments}</div>
        </div>
      );
    }
  };

  renderPendingAppointments = () => {
    const { pendingAppointments } = this.state;
    return (
      <React.Fragment>
        {pendingAppointments?.map((appointment) => (
          <AppointmentCard
            key={appointment.id}
            appointment={appointment}
            onCancelAppointment={() => this.handleCancelAppointment(appointment.id)}
            onCalendarEventShare={() => this.handleCalendarEventShare(appointment)}
            onRenderInfoModal={this.renderInfoModal}
            onClick={() =>
              appointment.work_id
                ? this.handlePressEvent("A-" + appointment.work_id, appointment)
                : this.handlePressEvent("S-" + appointment.id, appointment)
            }
          />
        ))}
      </React.Fragment>
    );
  };

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
      />
    );
  };

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  };

  renderLoader = () => {
    const { isLoading, showMyEquipments } = this.state;
    if (isLoading && !showMyEquipments) {
      return <Loader message="" inverted={false} local={true} big={false}></Loader>;
    }
    return;
  };

  renderScheduleNone = () => {
    const { selectedCompany } = this.state;
    return (
      <div className="company-details-card-container">
        <div className="company-details-card mt-3 mt-md-4">
          <div className="company-details-content-container flex-column align-center">
            <div
              className="icon-close"
              role="button"
              onClick={() => this.setState({ showMyEquipments: false, showSelectCompany: true })}
            >
              <img src={GENERAL.iconClose} alt="icon-modal-close" />
            </div>
            <h2 className="text-center">
              {Labels.sidebarScheduleMessage(selectedCompany?.name)?.fullScheduleDesactivated}
            </h2>
            <p className="text-center">Contacte-nos via:</p>
            {/* <div className="company-details-content-separator"></div> */}
            <CompanyDetailsInfo company={selectedCompany} />
          </div>
        </div>
      </div>
    );
  };

  renderEquipments = () => {
    const { selectedCompany } = this.state;
    if (selectedCompany && selectedCompany.schedulerModule !== "FULL") {
      return <>{this.renderScheduleNone()}</>;
    }
    return (
      <MyEquipments
        embeddedSchedule={true}
        handleClickNewSchedule={this.handleSelectScheduleItem}
        {...this.props}
      />
    );
  };

  renderCompaniesLoader = () => {
    const { userCompaniesLoading } = this.state;
    if (userCompaniesLoading) {
      return (
        <div className="w-100">
          <Loader message="" inverted={false} local={true} big={false}></Loader>
        </div>
      );
    }
    return <React.Fragment />;
  };

  renderCompanyCardList = () => {
    const { userCompanies } = this.state;

    return (
      <CompanyCardList
        addActivated={false}
        companies={userCompanies}
        scheduleMode={true}
        onSelectedCompany={this.handleSelectedCompany}
      />
    );
  };

  renderSelectCompany = () => {
    const { selectCompanyScrollHeight, hideBottomShadow, bottomBarTop } = this.state;
    return (
      <div className="user-companies-container">
        <div className="anchor companies-top-container pt-0 h-auto pb-2 px-4 box-shadow">
          <p>{Labels.sidebarScheduleMessage().selectCompany}</p>
        </div>
        <div
          id="select-schedule-company"
          className={`grid-scroll-container scrollable-container ${
            Helper.isAndroidWebview() || Helper.isIOSWebview() ? "" : "browser"
          }`}
          style={{ maxHeight: selectCompanyScrollHeight }}
        >
          {Helper.isLiteVersion() || Helper.isDemo() ? null : (
            <div
              className="grid-container--companies px-0 pb-4 pt-0 justify-content-between"
              id="grid-companies"
            >
              {this.renderCompanyCardList()}
            </div>
          )}
          <div
            id="scrollBaseContainer"
            style={{ bottom: bottomBarTop - 2 }}
            className={`scroll-base-container ${hideBottomShadow ? "end" : ""}`}
          ></div>
        </div>
        {this.renderCompaniesLoader()}
      </div>
    );
  };

  render() {
    const {
      showMyEquipments,
      showOldAppointments,
      showSelectCompany,
      smallContainer,
      appointmentsListHeight,
      isLoading,
    } = this.state;

    const justPendingAppointments = !showMyEquipments && !showOldAppointments && !showSelectCompany;
    const showPendingAppointments = !this.is_cal_token && justPendingAppointments;

    const heightAuto = smallContainer || (!showPendingAppointments && !showOldAppointments);

    return (
      <React.Fragment>
        <div className="side-bar-menu-container-block text-white mt-4 mt-md-5 pt-1">
          {!LocalData.terms_accepted && (
            <IAgreeBox
              url={Helper.addOrUpdateSearchParam(window.location, "openAppointment", true)}
            />
          )}
          {(LocalData.terms_accepted || this.isLiteVersion) && (
            <>
              {!this.is_cal_token && !showSelectCompany && (
               <>{this.renderAppointmentsSidebarTitles()}</>
              )}
              <div
                id="myAppointmentsContainer"
                style={{ height: heightAuto ? "auto" : appointmentsListHeight, maxHeight: appointmentsListHeight }}
                className={`menu-info scrollable-container pt-2 ps-1`}
              >
                {showPendingAppointments && this.renderPendingAppointments()}
                {showOldAppointments && this.renderOldAppointments()}
              </div>
              {this.renderLoader()}
              {justPendingAppointments && !isLoading &&
                this.renderAddNewAppointmentSection(showPendingAppointments)}
              {showMyEquipments && this.renderEquipments()}
              {showSelectCompany && this.renderSelectCompany()}
            </>
          )}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }

  renderAddNewAppointmentSection(showPendingAppointments) {
    const { bottomBarTop, hideBottomShadow } = this.state;
    return (
      <div
        id="addNewSchedule"
        style={{ bottom: bottomBarTop }}
        className={`fixed-buttons-wrapper ${!hideBottomShadow ? "box-shadow" : ""}`}
      >
        {this.renderNewScheduleButton()}
        {showPendingAppointments && this.renderOldAppointmentsLink()}
      </div>
    );
  }
}

export default withRouter(MyAppointments);