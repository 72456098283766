// React Imports
import React from 'react';
// import { FaTimes } from 'react-icons/fa';
// Components
// import Button from 'components/button/button.jsx';
// Child Components
import ConfirmationSample from 'components/modal/samples/confirmation.jsx';
import InformationSample from 'components/modal/samples/information.jsx';
import MediaSample from 'components/modal/samples/mediaSample.jsx';
import ChangeDataSample from 'components/modal/samples/change_data.js';
import MultiOptionsSample from 'components/modal/samples/multiOptions.jsx';
import VideoTutorialSample from 'components/modal/samples/videoTutorial.jsx';
import ModalIAgreeSample from 'components/modal/samples/modalIAgreeSample.jsx';
import Helper from '../../core/helper/helper';
// Style
import 'components/modal/modal.style.scss';
import { GENERAL } from '../../assets/images/images';
import AndroidBack from '../../core/services/androidBack.service';
import Emitter from '../../core/services';

class Modal extends React.Component {

  constructor(props) {
    super(props);
    Modal._singletonRef = this;
    this.state = {
      hidden: true,
      view: '',
      isMedia: false,
      occupyScreen: false,
      isOverflowAlreadyHidden: document.body.style.overflowY === 'hidden'
    };
  }

  componentDidMount() {
    Emitter.on('CLOSE_MODAL', () => {
      this.closeModal();});
  }

  componentWillUnmount() {
    Emitter.off('CLOSE_MODAL');
  }

  static open() { Modal._singletonRef.openModal() }
  static close() { Modal._singletonRef.closeModal() }
  static create(render) { Modal._singletonRef.renderView(render) }

  /**
   * Activar o Modal Component
   */
  openModal = () => {
    this.setState({
      hidden: false,
      isOverflowAlreadyHidden: document.body.style.overflowY === "hidden",
    });
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.disablePullDownToRefresh();
      } catch (error) {
        console.error(error);
      }
    }
    document.body.style.overflowY = 'hidden';
   /* if (window.scrollY === 0) {
      window.scrollTo(0,1);
    }*/
    AndroidBack.setModalOpenned(true);
  }

  /**
   * Fechar o Modal Component
   */
  closeModal = () => {
    this.setState({ hidden: true, view: '' });

    if (!this.state.isOverflowAlreadyHidden) {
      document.body.style.overflowY = 'auto';
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.enablePullDownToRefresh();
        } catch (error) {
          console.error(error);
        }
      }
    }
    AndroidBack.setModalOpenned(false);
  }

  /**
   * Recebe uma view para ser apresentada no modal
   */
  renderView = (render, isMedia = false, occupyScreen = false) => {
    this.setState({ hidden: false, view: render, isMedia: isMedia, occupyScreen: occupyScreen});
    AndroidBack.setModalOpenned(true);
  }

  renderMainContainer = (view, hasDeleteIcon = false, occupyScreen = false) => {
    return (
      <div
        className="modal-main-container"
        style={occupyScreen ? { paddingTop: '2%' } : undefined}
      >
        <div className={`modal-container ${occupyScreen ? 'p-1 occupyScreen' : 'p-4'}`}>
          <div className='modal-header'>
            <div onClick={this.closeModal}>
              <img src={GENERAL.closeIcon} className="icon-close" alt="" />
            </div>
          </div>
          {view}
          {this.props.render}
        </div>
      </div>
    );
  }

  renderMediaMainContainer = (view) => {
    AndroidBack.setModalOpenned(true);
    return (
      <div className='modal-main-media-container'>
        <div className='modal-media-container' >
          <div className='modal-media-header'>
            
            <div onClick={this.closeModal}>
              <img src={GENERAL.minimizeBlueIcon} className="icon-media-close" alt="" />
            </div>
          </div>  
          {view}
          {this.props.render}
        </div>
      </div>
    );
  }

  render() {
    const { isMedia, hasDeleteIcon, hidden, view, occupyScreen } = this.state;
    if (!isMedia) {
      if (hidden) { return <div></div>; }
      return this.renderMainContainer(view, hasDeleteIcon, occupyScreen);
    } else {
      if (hidden) { return <div></div>; }
      return this.renderMediaMainContainer(view);
    }
  }
}
export default Modal;

/**
 * Exports dos samples dos modal views que foram criados
 * para este projecto
 */
export const Confirmation = ConfirmationSample;
export const Information = InformationSample;
export const OpenMedia = MediaSample;
export const ChangeData = ChangeDataSample;
export const MultiOptions = MultiOptionsSample;
export const VideoTutorial = VideoTutorialSample;
export const ModalIAgree = ModalIAgreeSample;