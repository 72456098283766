import React, { useState, useEffect } from "react";
import { HelperSchedule } from "../../core/helpers/helperSchedule";
import CardItemInformation from "../../../../components/card/equipment/CardItemInformation";
import { GENERAL } from "../../../../assets/images/images";
import Helper from "../../../../core/helper/helper";

/**
 * @param {Object} props
 * @param {string} props.location
 * @param {ScheduleData} props.scheduleData
 */
const ScheduleServiceDetailsCard = (props) => {
  const [location] = useState(props.location);
  const [scheduleData, setScheduleData] = useState(props.scheduleData);
  const [date, setDate] = useState(props.scheduleData?.selectedDate ?  props.scheduleData?.selectedDate?.toLocaleDateString('pt-PT'): '-')
  const isInternalLiteEquip = typeof scheduleData?.itemDetails?.itemFoId === 'string' && scheduleData?.itemDetails?.itemFoId.includes("int");

  useEffect(() => {
    setScheduleData(props.scheduleData);
    if (props.scheduleData?.selectedDate) {
      setDate(props.scheduleData?.selectedDate?.toLocaleDateString('pt-PT'));
    }
  }, [props.scheduleData]);

  const renderItemImage = () => {
    const imgSrc = Helper.isLiteVersion() ? (scheduleData?.itemDetails?.image ? scheduleData?.itemDetails?.image : '') : HelperSchedule.urlItemImage(scheduleData?.itemDetails?.photo);
    return (
      <div className="card-service-image-container details">
        <img
          className={!isInternalLiteEquip && Helper.isLiteVersion() ? 'pixelized' : ''}
//          src={HelperSchedule.urlItemImage(scheduleData?.itemDetails?.photo)}
          src={imgSrc}
          alt="service_image"
          onError={(e) => ((e.target).src = Helper.defaultImage())}
        />
      </div>
    );
  };

  const renderItemInformation = () => {
    const { itemDetails } = scheduleData;
    return <CardItemInformation data={{ title: itemDetails?.title, subtitle: itemDetails?.subtitle, id: itemDetails?.itemFoId }} styleClass="mt-3 mt-md-0 text-start details"/>
  }

  const renderDate = (type) => {
    return (
      <div className="service-equipment-data-row d-inline-flex text-start w-50">
        <img
          src={type === "date" ? GENERAL.iconCalendarScheduled : GENERAL.iconClock}
          alt="scheduled-date"
        />
        <div className={"service-date-text flex-start"}>
          {type === "date" ? HelperSchedule.formatDateScheduler(date) : scheduleData?.selectedTime?.value}
        </div>
      </div>
    );
  };

  const renderDates = () => {
    return (
      <div className='service-equipment-data d-inline-flex w-100 mt-4'>
        {renderDate('date')}
        {renderDate('time')}
      </div>
    );
  };

  const renderLocationContainer = () => {
    return (
      <div className="d-flex justify-content-center justify-content-sm-start mt-3">
        <div className="card-service-location" title={'Localização'}>
          <img src={GENERAL.iconLocation} alt="" />
          <div className="ms-2 card-service-location-text">
            {Helper.isDemo(location) ? "Empresa Demo" : location}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`card-service-main-container p-3 w-100 m-0 h-auto mw-100`}>
        <div className="row">
          <div className="col-12 col-sm-6">{renderItemImage()}</div>
          <div className="col-12 col-sm-6">
            {renderItemInformation()}
            {renderDates()}
            {renderLocationContainer()} 
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScheduleServiceDetailsCard;
