// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
// Components Imports
import Helper from 'core/helper/helper';
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx'
import Modal, { Information, Confirmation } from 'components/modal/modal.jsx';
// API Imports
import { alterUserPassword } from 'api/routes/api.route.js';
import Labels from '../variables/labels';

class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.refPassword = React.createRef();
    this.refCheckPassword = React.createRef();
    this.state = {
      password: '',
      checkPassword: '',
    };
//    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
    * Verifica se as password são iguais, em caso de sucesso será
    * returnado @true caso contrartio será returnado @false com uma
    * respectiva mensagem através de um modal
    *
    * @param {string} password - valor da password
    * @param {string} password - valor da password de confirmação
    */
  checkPasswordValidation = (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      this.refModal.current.renderView(
        <Information
          title='Dados Incorrectos'
          text="As passwords inseridas são diferentes"
          onClick={() => this.refModal.current.closeModal()} />);
      this.refModal.current.openModal();
      return false;
    } else {
      return true;
    }
  }

  /**
  * Confirmação para alterações de password
  */
  confirmPasswordUpdate = (NEW_PASSWORD) => {
    this.refModal.current.renderView(
      <Confirmation
        title='Atenção'
        text={Labels.confirmUpdatePassword}
        confirmationHandle={() => this._alterarPassword(NEW_PASSWORD)}
        declineHandle={() => this.refModal.current.closeModal()}
      />);
    this.refModal.current.openModal();
  }

  /**
    * Pedido para altração da password do utilizador
    *
    * @param {string} NEW_PASSWORD - valor da nova password
    */
  _alterarPassword = (NEW_PASSWORD) => {
    alterUserPassword(this.cookies.get('sessionToken'), NEW_PASSWORD).then(res => {
      if (res) {
        if (res.http_code) {
          if (res.http_code === 400) {
            this.refModal.current.renderView(
              <Information
                title='Dados Incorrectos'
                text={res.message}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          } else {
            this.refModal.current.renderView(
              <Information
                title='Pedido Sem Sucesso'
                text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          }
        } else {
          this.refModal.current.renderView(
            <Information
              title='Password Alterada'
              text={res.message}
              onClick={() => this.props.history.push(`/`)} />);
          this.refModal.current.openModal();
        }
      } else {
        this.refModal.current.renderView(
          <Information
            title='Pedido Sem Sucesso'
            text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
            onClick={() => this.refModal.current.closeModal()} />);
        this.refModal.current.openModal();
      }
    });
  }


  /**
    * Validação dos dados inseridos
  */
  handlePasswordSubmit = (event) => {
    event.preventDefault();
    const pass = this.refPassword.current.getValues();
    const passCheck = this.refCheckPassword.current.getValues();
    const checked = this.checkPasswordValidation(pass, passCheck);
    if (checked) {
      const newPassword = { password: pass };
      this.confirmPasswordUpdate(newPassword);
    }
  }

  render() {
    return (
      <div className="main-container px-3 pb-4">
        <form className='w-100' onSubmit={this.handlePasswordSubmit}>
          <div className='grey-box-container w-100 mt-5'>
            <h2 className='text-center w-100 mb-0'> {Labels.newPassword.header}</h2>
            <div className='box-form-container'>
                <InputField
                  styleClass='mt-2 pt-1'
                  styleInput="input-medium-default password"
                  ref={this.refPassword} type='password'
                  title='Nova Password'
                  forgotPassword={false}
                  pattern={Helper.passwordRegex}
                  required={true} />
                <InputField
                  styleInput="input-medium-default password"
                  ref={this.refCheckPassword}
                  type='password' title='Confirmar Password'
                  forgotPassword={false}
                  pattern={Helper.passwordRegex}
                  required={true} />
            </div>
          </div>
            <Button className='cta-button-primary d-medium w-100 mt-4' style={{maxWidth: '427px'}}
              type='submit' value='Guardar Alterações' />
        </form>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default withRouter(ChangePassword);