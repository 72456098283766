import React from 'react';
import { Route } from 'react-router-dom';

import Authenticated from 'containers/App/Authenticated.jsx';
import NonAuthenticated from 'containers/App/NonAuthenticated.jsx';

const ProtectedRoute = ({ loggedIn, path, ...rest }) => (
  <Route
    path={path}
    {...rest}
    render={(props) => (loggedIn ? (<Authenticated {...props} />) : ( <NonAuthenticated {...props} /> ))}
  />
);

export default ProtectedRoute;
