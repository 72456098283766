// React Imports
import { SIDEBAR } from 'assets/images/images.js';
import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Cookies from 'universal-cookie';
import Conta from 'views/SidebarMenu/conta.view.jsx';
import MyEquipments from 'views/SidebarMenu/myEquipments.view.jsx';
import CompanyService from '../../core/services/company.service';
import { GENERAL } from '../../assets/images/images';
import Helper from '../../core/helper/helper';
import SidebarHelper from '../../core/helper/sidebarHelper';
import LocalData from '../../core/localData';
import Emitter from '../../core/services';
import Labels from '../../variables/labels.js';
import Companies from '../../views/SidebarMenu/MenuKey/companies.view';
import ConfirmationCTA from '../button/ConfirmationCTA';
import Modal, { Confirmation } from '../modal/modal';
import './sidebar.style.scss';
import MyAppointments from '../../views/SidebarMenu/myAppointments/MyAppointments';
import { HelperSchedule } from '../../views/schedule/core/helpers/helperSchedule.js';
import AndroidBack from '../../core/services/androidBack.service.js';
import VideoTutorial from '../modal/samples/videoTutorial.jsx';
import { ToastContainer } from 'react-toastify';
import Button from '../button/button.jsx';


class Sidebar extends Component {

  static backButtonSideBar() {
    Sidebar.__singletonRef.handleBackButton();
  }

  constructor(props) {
    super(props);
    Sidebar.__singletonRef = this;
    this.cookies = new Cookies();
    this.state = {
      companyName: null,
      isOpen: false,
      navOption: '',
      navOptionParams: {},
      disable: false,
      prevPath: '',
      currentPath: this.props.location.pathname.toString(),
      isItemsPage: SidebarHelper.isItemsPage(props.location.pathname),
      isAccountPage: SidebarHelper.isAccountPage(props.location.pathname),
      isHomePage: SidebarHelper.isHomePage(props.location.pathname),
      isSchedulePage: HelperSchedule.isSchedulePage(),
      companiesLength: 0,
      countPendAppoints: 0,
      config: Helper.getConfig(),
      sidebarButton: undefined,
      isMobile: window.innerWidth <= 768,
    };
    this.readSearchParams();
  }
  
  readSearchParams = () => {
    const searchParams = new URLSearchParams(this.props.location?.search);
    this.showCompanies = searchParams.get('showCompanies');
    searchParams.delete('showCompanies');
    let cleanedSearch = searchParams.toString();

    this.showCreateAppointment = searchParams.get('openAppointment');
    searchParams.delete('openAppointment');
    this.showMyEquipments = searchParams.get('openEquipments');
    searchParams.delete('openEquipments');
    searchParams.delete('schedule');
    cleanedSearch = searchParams.toString();

    const newUrl = `${window.location.pathname}${cleanedSearch ? `?${cleanedSearch}` : ''}`;
    window.history.replaceState({}, '', newUrl);
  }
  
  componentWillUnmount() {
    Emitter.off('COMPANIES_COUNT');
    Emitter.off('APPOINTMENTS_COUNT');
    Emitter.off('SIDEBAR_BUTTON');
    Emitter.off('SIDEBAR_BACK');
    Emitter.off('SIDEBAR_OPEN');
  }

  componentDidMount() {
    this.setState({ companyName: Helper.companyName() });
    Emitter.on('COMPANIES_COUNT', (value) => {
      this.setState({ companiesLength: value});
    });
    Emitter.on('SIDEBAR_BUTTON', (value) => {
      this.setState({ sidebarButton: value});
    });
    if (!Helper.isLiteVersion() && this.state.config?.scheduler_module !== 'NONE'){
      Emitter.on('APPOINTMENTS_COUNT', (value) => {
        this.getAppointmentCount(value);
      });    
    }
    if (!Helper.isLiteVersion() && !LocalData.companiesCount) {
      CompanyService.getCompaniesCount();
    } else {
      Emitter.emit("COMPANIES_COUNT", LocalData.companiesCount?.warnings);
      Emitter.emit("APPOINTMENTS_COUNT", LocalData.companiesCount?.scheduled_appointments);
    }
    if (this.showCompanies) {
      this.openDrawerView('btnKeymaster');
      // setTimeout(() => { this.openDrawerView('btnKeymaster'); }, 5000);
    }
    if (this.showCreateAppointment) {
      //this.openDrawerView('btnSchedule');
      setTimeout(() => { this.openDrawerView('btnSchedule'); }, LocalData.isSchedule ? 0 : 1000);
    }
    if (this.showMyEquipments) {
      //this.openDrawerView('btnSchedule');
      setTimeout(() => { this.openDrawerView('btnEquipments'); }, 1000);
    }

    Emitter.on('SIDEBAR_BACK', () => {
      const { sidebarButton, navOption } = this.state;
      if (sidebarButton != null && window.innerWidth <= 768 && navOption === 'btnKeymaster') {
        Emitter.emit("GO_BACK_PROVIDERS", sidebarButton);
      } else {
        this.openDrawerView(this.state.navOption);
      }
    });

    Emitter.on('SIDEBAR_OPEN', (btn) => {
      if(btn === "btnScheduleOldAppointments")
        this.openDrawerView('btnSchedule', { showOldAppointments: true })
      else if(btn === "btnScheduleShowMyEquipments")
        this.openDrawerView('btnSchedule', { showMyEquipments: true })
      else
        this.openDrawerView(btn);
    })

    window.addEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const past = prevProps.location.pathname.toString();
    const current = this.props.location.pathname.toString();
    if (current !== past) {
      this.setState({
        prevPath: past,
        currentPath: current,
        isItemsPage: SidebarHelper.isItemsPage(current),
        isAccountPage: SidebarHelper.isAccountPage(current),
        isHomePage: SidebarHelper.isHomePage(current),
        isSchedulePage: HelperSchedule.isSchedulePage(),
      });
    }

    // Check if the isOpen state has changed
    if (this.state.isOpen !== prevState.isOpen) {
      // Call AndroidBack.setMenuOpen with the updated isOpen state
      AndroidBack.setMenuOpen(this.state.isOpen);
    }
  }

  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768});
  };

  refModal = React.createRef();
  handleEventVideoTutorial = () => {
    this.renderModal(
      <VideoTutorial
        title={"Tutorial Cliente"}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        videoId="ZgPUE68tg-U" // Replace with your YouTube video ID
      />,
      false,
      true
    );
  };

  renderModal = (view, isMedia = false, occupyScreen=false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }

  getAppointmentCount(countPendAppoints) {
    if (LocalData.terms_accepted && countPendAppoints != null) {
      this.setState({ countPendAppoints });
    }
  }

  /**
   * Controlador responsavel pelo evento do botão back
 */
  handleBackButton = () => {
    let layer;
    let body = document.body;

    if (this.state.navOption !== '') {
      layer = document.getElementById('drawer-layer');
      layer.className = layer.className.replace(' drawer-layer-open', '');
    }

    if (this.state.navOption) {
      let bar = document.getElementById('drawer');
      bar.className = bar.className.replace(' open', '');
      layer = document.getElementById('drawer-layer');
      layer.className = layer.className.replace(' drawer-layer-open', '');
    }

    sessionStorage.removeItem('agendBody');
    sessionStorage.removeItem('category_symptom');
    sessionStorage.removeItem('location_data');
    sessionStorage.removeItem('data');
    sessionStorage.removeItem('equipment');
    this.setState({ isOpen: false, navOption: '', navOptionParams: {}, disable: false });
    AndroidBack.setMenuOpen(false); // call this here because sometimes componentDidUpdate does not have time to run.
    body.style.overflowY = 'auto';
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.enablePullDownToRefresh();
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Controlador responsavel pelo evento do botão abrir menu
   *  
   * @param {string} id - id do elemento botão carregado
 */
  openDrawerView = (btnID = this.state.navOption, params = {}) => {
    if (!LocalData.userRegistered) {
      return;
    }
    let bar = document.getElementById('drawer')
    let layer = document.getElementById('drawer-layer');
    let body = document.body;
    if (!this.state.isOpen) {
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.disablePullDownToRefresh();
        } catch (error) {
          console.error(error);
        }
      }
      /*if (body.scrollY === 0) {
        window.scrollTo(0,100);
      }*/
      body.style.overflowY = 'hidden';
      bar.className += ' open';
      layer.className += ' drawer-layer-open';
      this.setState({ isOpen: true, navOption: btnID, navOptionParams: params });
      return;

    } else if (this.state.isOpen && btnID === this.state.navOption) {
      if (Helper.isAndroidWebview()) {
          try {
            window.Android.enablePullDownToRefresh();
          } catch (error) {
            console.error(error);
          }
        }
      body.style.overflowY = 'auto';
      bar.className = bar.className.replace(' open', '');
      layer.className = layer.className.replace(' drawer-layer-open', '');
      this.setState({ isOpen: false, navOption: '', navOptionParams: {}, sidebarButton: undefined });
      AndroidBack.setMenuOpen(false); // call this here because sometimes componentUpdate does not have time to run.
      return;

    } else if (this.state.isOpen && btnID !== this.state.navOption) {
      this.setState({ isOpen: true, navOption: btnID, navOptionParams: params });
      return;
    }
  };

  renderShowcaseMessage = (btnID) => {
    const renderMessage = () => {
      return (
        <React.Fragment>
          <p className='mt-1 mb-0'>{/*Labels.liteGenericMessage*/}</p>
          <ConfirmationCTA
            confirmText={Helper.getLiteRegisterButton()}
            confirmationHandle={() => Helper.logoutFromLite()}
            declineHandle={() => this.openDrawerView(btnID)}
          />
          <div className='mt-6'>
            <a className='link-container' href={Helper.linkToAppStore()}
              target="_blank" rel="noopener noreferrer">
              Instalar App Keymaster Cliente
            </a>
            <button className='link-container showcase-message p-2'
              onClick={this.handleEventVideoTutorial}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
              <img src={SIDEBAR.videoDemoIcon} alt="Vídeo Demo Icon" className='showcase-icon' />
              Vídeo de Demonstração
            </button>
          </div>
        </React.Fragment>
      );
    }

    const renderLiteAsk = (title) => {
      return (
        <div className="ask-container">
          <div className="ask inverted">
            <h3> {title}</h3>
          </div>
        </div>
      );
    }

    const renderAskContainer = () => {
      switch (btnID) {
        case 'btnEquipments': return renderLiteAsk(Labels.liteSidebarEquipmentsTitle);
        case 'btnSchedule': return renderLiteAsk(Labels.liteSidebarScheduleTitle);
        case 'btnKeymaster': return renderLiteAsk(Labels.liteSidebarMenuKey);
        default: return renderLiteAsk(Labels.liteSidebarProfileTitle);
      }
    }

    const renderLogOut = () => {
      if (btnID === 'btnProfile') {
        return (
          <div style={{ width: '80%', marginTop: '40px' }}>
            <Button
              className='cta-button-secondary w-100'
              type='button'
              value='Terminar Sessão'
              onClick={() => { handleEventLogout() }} />
          </div>
        )
      }
    }

    const resetClientData = () => {
      this.cookies.remove('sessionToken', Helper.cookiesConfig);
      this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
      this.cookies.remove('liteEmail', Helper.cookiesLiteConfig);
      this.cookies.remove('litePhone', Helper.cookiesLiteConfig);
      this.cookies.remove('userId', Helper.cookiesLiteConfig);
      this.cookies.remove('isCalToken', Helper.cookiesLiteConfig);
      LocalData.reset();
    }

    const logoutClient = (doNotMemorizeCurrentCompany = false) => {
      const eventInfo = {
        auth_token: this.cookies.get('sessionToken'),
        route: this.cookies.get('route'),
        client_route: this.cookies.get('clientroute')
      };
      if (Helper.isIOSWebview()) {
        try {   
          window.webkit.messageHandlers.logout.postMessage(eventInfo);
        } catch (error) {
          console.error(error);
        }
      }
      if (Helper.isAndroidWebview()) {
        try {
          window.Android.logout(eventInfo.auth_token, eventInfo.route, eventInfo.client_route);
        } catch (error) {
          console.error(error);
        }
      }
      resetClientData();
      Helper.logout(doNotMemorizeCurrentCompany);
    }

    const handleEventLogout = () => {
      this.renderModal(
        <Confirmation
          title='Atenção'
          text={"Deseja terminar a sua sessão na app Keymaster?"}
          confirmText={"Terminar Sessão"}
          confirmationHandle={() => logoutClient(true)}
          declineHandle={() => this.refModal.current.closeModal()}
        />
      );
    }

    return (
      <div className='side-bar-menu-container-block'>
        <div className="menu-info">
          {renderAskContainer()}
          {renderMessage()}
          {renderLogOut()}
        </div>
      </div>
    );
  }

  renderNoScheduleActive = () => {
    return (
      <div className="side-bar-menu-container-block mt-5">
        <div className="menu-info">
        <div className="menu-info-title">Agendamentos</div>
          <p className="mt-3 mb-0">
            {Labels.sidebarScheduleMessage(Helper.companyName())?.fullScheduleDesactivated}
          </p>
        </div>
      </div>
    );
  };

  /**
   * Controlador responsavel pelo evento de trocar a view dentro do menu
   * 
   * @param {string} id - id do elemento botão carregado
   */
  switchDrawerView = (btnID) => {
    if (btnID === 'btnEquipments') {
      if (!Helper.isLiteVersion()) {
        return (<MyEquipments {...this} />);
      } else {
        return this.renderShowcaseMessage(btnID);
      }
    }
    if (btnID === 'btnProfile') {
      if (!Helper.isLiteVersion()) {
        return (<Conta {...this} />);
      } else {
        return this.renderShowcaseMessage(btnID);
      }
    }
    if (btnID === 'btnSchedule') {
//      if (!Helper.isLiteVersion() ) {
        if (Helper.getConfig()?.scheduler_module !== "NONE") {
          return <MyAppointments {...this} />;
        } else {
          return this.renderNoScheduleActive();
        }

//      } else {
//        return this.renderShowcaseMessage(btnID);
//      }
    }
    if (btnID === 'btnKeymaster') {
      return (<Companies {...this} />);
      // if (!Helper.isLiteVersion()) {
      // } else {
      //   return this.renderShowcaseMessage(btnID);
      // }
    }
  }

  renderHomeIcon = () => {
    const { currentPath, prevPath, isHomePage, navOption, isOpen, isMobile } = this.state;
    const isHomeActive = navOption === 'btnHome' || (isHomePage && !isOpen);
    if (SidebarHelper.isProfilePage(currentPath) && !LocalData.userRegistered) {
      const url = !LocalData.userRegistered && this.cookies.get('prevCompanyUrl') ? this.cookies.get('prevCompanyUrl') : prevPath;
      return (
        <div className='menu-icon-container logo'>
          <a href={url} onClick={this.handleBackButton}>
            <img src={SIDEBAR.arrowBack} alt=""/>
          </a>
          
        </div>
      );
    }
    if (currentPath.includes('service-details') && prevPath.includes('equipment-details')) {
      return (
        <div className='menu-icon-container logo'>
          <Link to={`${prevPath}`} onClick={this.handleBackButton}>
            <img src={SIDEBAR.arrowBack} alt="" />
          </Link>
          
        </div>
      );
    } else {
      const { search } = this.props.location;
      const redirectTo = Helper.isLiteVersion() ? `/services-lite${search}` : `/services`;
      return (
        <div
          onClick={() => {
            this.props.history.push(redirectTo);
            this.handleBackButton();
          }}
          role="button"
          className="menu-icon-container"
        >
          <img src={SIDEBAR.home} alt={``} />
         {(isHomeActive || !isMobile) && <div className={`nav-label inner ${isHomeActive ? "active" : ""}`}>{"Início"}</div>}
        </div>
      );
    }
  }

  renderSideBarOptions = () => {
    const { navOption, isItemsPage, isAccountPage, isOpen, isHomePage, isSchedulePage, isMobile } = this.state;
    const isItemsActive = (isItemsPage && !isOpen);
    const isItemsOpen = navOption === 'btnEquipments' && isOpen;
    const isProfileActive = (isAccountPage && !isOpen);
    const isProfileOpen = navOption === 'btnProfile' && isOpen;
    const isHomeActive = (isHomePage && !isOpen);
    const isScheduleActive = (isSchedulePage && !isOpen);
    const isScheduleOpen = navOption === 'btnSchedule' && isOpen;
   
    const renderMenuIcon = (opt) => {
      let icon;
      let label;
      let iconClassName = '';
      let iconStyle = {};
      let labelClassName = 'nav-label';
      switch (opt) {
        case 'btnProfile':
          icon = SIDEBAR.userSettings;
          label = 'Perfil';
          labelClassName += isProfileActive ? ' active' : ' inner';
          labelClassName += isProfileOpen ? ' open' : '';
          break;
        case 'btnKeymaster':
          icon = SIDEBAR.store;
          iconClassName = `${navOption === opt ? 'filter-white' : ''}`;
          label = 'Empresas';
          labelClassName += navOption === opt ? ' open outside' : ' outside';
          break;
        case 'btnEquipments':
          iconClassName='icon-equip-tint';
          icon = Helper.sidebarEquipmentIcon();
          label = `${Helper.itemLabel()}s`;
          labelClassName += isItemsActive ? ' active' : ' inner';
          labelClassName += isItemsOpen ? ' open' : '';
          break;
        default:
          icon = SIDEBAR.calendar;
          label = `Agenda`;
          labelClassName += isScheduleActive ? ' active outside' : ' outside';
          labelClassName += isScheduleOpen ? ' open outside' : ' outside';
          break;
      }
      return (
        <div
          className={`menu-icon-container ${
            opt === "btnKeymaster" ? "main-menu-container px-0" : ""
          }`}
        >
          <img src={icon} alt={`${opt}Icon`} className={iconClassName} style={iconStyle} />
          {!labelClassName?.includes("active") &&
          !labelClassName?.includes("open") && isMobile ? null : (
            <div className={labelClassName}>{label}</div>
          )}
        </div>
      );
    }

    const renderNotiIcon = () => {
      const { companiesLength } = this.state;
      if (!companiesLength) {
        return;
      }

      return (
        <div className="tab-notification-container">
          <div className="tab-notification">
            <div className={`number ${companiesLength > 99 ? 'small' : ''}`}> {companiesLength} </div>
          </div>
        </div>
      );
    }

    const renderAppointCount = () => {
      const { countPendAppoints } = this.state;
      if (!countPendAppoints) {
        return;
      }
      return (
        <div className="tab-notification-container">
          <div className="tab-notification">
            <div className={`number ${countPendAppoints > 99 ? 'small' : ''}`}> {countPendAppoints} </div>
          </div>
        </div>
      );
    }

    const renderMainMenus = () => {
      const renderBtnProfile = () => {
        return (
          <div
            id="btnProfile"
            className={`sidebar-option-button col col-md-0 px-md-0 ${
              isProfileActive ? "active" : ""
            } ${isProfileOpen ? "open" : ""}`}
            onClick={() => this.openDrawerView("btnProfile")}
          >
            {renderMenuIcon("btnProfile")}
          </div>
        );
      }
      if (!LocalData.userRegistered) {
        return renderBtnProfile();
      }

      return (
        <React.Fragment>
          <div id="btnHome"
            className={`sidebar-option-button col col-md-0 px-md-0 ${isHomeActive ? "active" : ""}`}
          >
            {this.renderHomeIcon()}
          </div>

          <div id='btnEquipments'
            className={`sidebar-option-button col col-md-0 px-md-0 ${isItemsActive ? 'active' : ''} ${isItemsOpen ? 'open' : ''}`}
            onClick={() => this.openDrawerView('btnEquipments')}>
            {renderMenuIcon('btnEquipments')}
          </div>

          { renderBtnProfile() }
        </React.Fragment>
      );
    };

    return (
      <>
      <div
        className={`sidebar-option-menu mt-md-2 ${isOpen ? "open" : ""} 
        ${navOption !== "btnKeymaster" ? "has-border" : ""}
        ${navOption === "btnKeymaster" || isHomePage ? "" : "has-left-border"}
        `}
      >
        <div
          className={`m-md-0 sidebar-inner-container ${
            isProfileOpen || isProfileActive ? "last-open" : ""
          }`}
        >
          {renderMainMenus()}
        </div>

        <div
          id="btnSchedule"
          className={`sidebar-option-button mt-0 mt-md-1 col col-md-0 px-md-0 ${
            isScheduleActive ? "active" : ""
          } ${isScheduleOpen ? "open outside" : "outside"}`}
          onClick={() => {
            this.openDrawerView("btnSchedule");
            LocalData.isSchedule = false;
          }}
        >
          {renderMenuIcon("btnSchedule")}
          {renderAppointCount()}
        </div>

        <div
          id="btnKeymaster"
          className={`sidebar-option-button mt-0 col col-md-0 px-md-0 ${
            navOption === "btnKeymaster" ? "open outside" : "outside"
          }`}
          onClick={() => this.openDrawerView("btnKeymaster")}
        >
          {renderMenuIcon("btnKeymaster")}
          {renderNotiIcon()}
        </div>
      </div>
      
      </>
    );
  }

  renderSidebarButton = () => {
    const { sidebarButton, navOption } = this.state;
    if ( sidebarButton != null && window.innerWidth <= 768 && navOption === 'btnKeymaster') {
      return (
        <div
          className="button-close-container"
          role={"button"}
          onClick={() => Emitter.emit("GO_BACK_PROVIDERS", sidebarButton)}
        >
          <img
            src={GENERAL.iconArrowUp}
            className={'filter-white'}
            alt="back_sidebar"
          />
        </div>
      );
    }
    return (
      <div
        className="button-close-container"
        role={"button"}
        onClick={() => this.openDrawerView(this.state.navOption)}
      >
        <img src={GENERAL.closeWhite} alt="close_sidebar" />
      </div>
    );
  };

  getBoxShadow = () => {
    const { navOption, isItemsPage, isAccountPage, isOpen, isHomePage, isSchedulePage } = this.state;
    const isItemsActive = (isItemsPage && !isOpen);
    const isProfileActive = (isAccountPage && !isOpen);
    const isHomeActive = (isHomePage && !isOpen);
    const isScheduleActive = (isSchedulePage && !isOpen);
    return isHomeActive
      ? "bottom-shadow-home"
      : isItemsActive
      ? "bottom-shadow-items"
      : isProfileActive
      ? "bottom-shadow-profile"
      : isScheduleActive
      ? "bottom-shadow-schedule"
      : (isOpen && navOption !== "btnKeymaster")
      ? ""
      : "bottom-shadow";
  };

  render() {
    const { isOpen, navOption } = this.state;
    const boxShadow = this.getBoxShadow();

    if (!this.state.disable) {
      return (
        <div id="sidebar-component">
          <div
            id="drawer-layer"
            className="drawer-layer"
            onClick={() => this.openDrawerView(navOption)}
          ></div>
          <div className="sidebar-container">
            <div
              className={`bar-container ${boxShadow} ${isOpen ? "open" : ""}`}
              id="bar-container"
            >
              <div className={`navigation-container ${isOpen ? "open" : ""}`}>
                {this.renderSideBarOptions()}
              </div>
            </div>
            <div id="drawer" className="view-container">
              {this.renderSidebarButton()}
              {this.switchDrawerView(navOption)}
            </div>
          </div>
          <Modal ref={this.refModal} />
          <ToastContainer />
        </div>
      );
    } else {
      // Menu para Agendamento só com o icon de close
      const { search } = this.props.location;
      return (
        <div className="sidebar-container">
          <div
            className={`bar-container ${
              isOpen && navOption === "btnKeymaster" ? "box-shadow" : ""
            } ${isOpen ? "open" : ""}`}
          >
            <div className="navigation-container">
              <div className="logo">
                <a
                  href={Helper.isLiteVersion() ? `/services-lite${search}` : `/`}
                  onClick={this.handleBackButton}
                >
                  <img src={SIDEBAR.close} alt="" />
                </a>
                <a href={Helper.isLiteVersion() ? `/services-lite${search}` : `/services`}>
                  {" "}
                  {this.state.companyName}
                </a>
              </div>
            </div>
          </div>
          <Modal ref={this.refModal} />
        </div>
      );
    }
  }
}

export default withRouter(Sidebar);
