// React Imports
import React from 'react';

// Styles
import 'components/card/RequirementList/RequirementList.style.scss';

class RequirementList extends React.Component {


  renderSymptoms = (symptoms) => {
    return (symptoms.map((value, index) => (
      <li key={index} className='list-text mb-2'>
        {value.name} {value.obs_value && `(${value.obs_value} ${value.obs_label})`}
      </li>
    )));
  }

  render() {
    if (!this.props.symptoms?.length) {
      return( <React.Fragment></React.Fragment>);
    }
    return (
      <div className='requirement-list-container pb-4'>
        <div className='list-text-container px-3'>
          <ul className='d-flex flex-column m-0'>
            {this.renderSymptoms(this.props.symptoms)}
          </ul>
        </div>
      </div>
    );
  }
}

export default RequirementList
