// Code for initializing HotJar and UXCam

class Analytics {
    init = () => {
        this.initializeUXCam();
        this.initializeHotJar();
    }

    initializeHotJar = () => {
        console.log("Initializing HotJ");
        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: 5175057, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); 
            r.async = 1;
            r.id = 'hotjar-script';
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }

    initializeUXCam = () => {
        console.log("Initializing UXC");
        (function (appKey, opts) {
            window.uxc = {
                __t: [],
                __ak: appKey,
                __o: opts,
                event: function (n, p) {
                    this.__t.push(['event', n, p]);
                },
                setUserIdentity: function (i) {
                    this.__t.push(['setUserIdentity', i]);
                },
                setUserProperty: function (k, v) {
                    this.__t.push(['setUserProperty', k, v]);
                },
                setUserProperties: function (p) {
                    this.__t.push(['setUserProperties', p]);
                },
            };
            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//websdk-recording.uxcam.com/index.js';
            script.async = true;
            script.defer = true;
            script.id = 'uxcam-web-sdk';
            script.crossOrigin = 'anonymous';
            head.appendChild(script);
        })('3nz0wcrx8anyvdg', {});
    }

    unmount = () => {
        this.unmountUXCam();
        this.unmountHotJar();
    }

    unmountUXCam = () => {
        if (window.uxc) {
            window.uxc = null;  // unmount UXCAM
        }
        const uxcamScript = document.getElementById('uxcam-web-sdk');
        if (uxcamScript) {
            uxcamScript.parentNode.removeChild(uxcamScript);
        }
    }

    unmountHotJar = () => {
        const hotjarScript = document.getElementById('hotjar-script');
        if (hotjarScript) {
            hotjarScript.parentNode.removeChild(hotjarScript);
        }
    }

}
export default Analytics;