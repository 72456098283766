export default class CalendarHelper {
  static notificationDate(year, month, day, hour, minute) {
    const MONTH_OPTIONS = [
      {
        id: "01",
        value: "Jan.",
      },
      {
        id: "02",
        value: "Fev.",
      },
      {
        id: "03",
        value: "Mar.",
      },
      {
        id: "04",
        value: "Abr.",
      },
      {
        id: "05",
        value: "Mai.",
      },
      {
        id: "06",
        value: "Jun.",
      },
      {
        id: "07",
        value: "Jul.",
      },
      {
        id: "08",
        value: "Ago.",
      },
      {
        id: "09",
        value: "Set.",
      },
      {
        id: "10",
        value: "Out.",
      },
      {
        id: "11",
        value: "Nov.",
      },
      {
        id: "12",
        value: "Dez.",
      },
    ];
    
    const today = new Date();

    return (
      (day + " ") + 
      ((MONTH_OPTIONS.some((m) => m.id=month) ? MONTH_OPTIONS.find((m) => m.id=month).value : "---") + " ") + 
      ((today.getFullYear().toString() !== year ? year : "") + " ") + 
      (hour + "h" + minute)
    );
  }
}
