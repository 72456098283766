import React, { Component } from "react";
import {
	List,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
} from "react-virtualized";
import CalendarHelper from "../../core/helper/calendarHelper";
import moment from "moment";

class NotificationsListVirtualized extends Component {
	constructor(props) {
		super(props);

		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 56,
		});

		this.handleResize = this.handleResize.bind(this);
		this.listRef = React.createRef();
	}

	clearCache = () => {
		this.cache.clearAll();
	};

	forceUpdateList = () => {
		if (this.listRef.current) {
			this.listRef.current.forceUpdateGrid();
		}
	};

	handleResize() {
		this.clearCache();
		this.forceUpdateList();
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.notifications !== this.props.notifications) {
			this.clearCache();
			this.forceUpdateList();
		}
	}

	formatDateTime = (timestamp) => {
		return CalendarHelper.notificationDate(
			moment(timestamp).format("YYYY"),
			moment(timestamp).format("MM"),
			moment(timestamp).format("DD"),
			moment(timestamp).format("HH"),
			moment(timestamp).format("mm")
		);
	};

	renderRow = ({ index, key, style, parent }) => {
		const {
			notifications,
			handleReadNotify,
			handleDeleteNotify,
		} = this.props;
		const notification = notifications[index];

		return (
			<CellMeasurer
				key={key}
				cache={this.cache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
			>
				{({ measure }) => (
					<div style={style}>
						<div
							className={`notifications-container-list-item notifications-container-list-item-${
								notification.read ? "read" : "unread"
							}`}
							onClick={() => handleReadNotify(notification.id, notification.url)}
						>
							<div className="notifications-container-list-item-title">
								{this.formatDateTime(notification.timestamp) +
									" - " +
									(notification.company_name ??
										notification.company_identifier)}
							</div>
							<div className="notifications-container-list-item-text">
								{notification.description}
								<div
									className="notifications-container-list-item-delete"
									onClick={(e) => {
										e.preventDefault();
										handleDeleteNotify(notification.id);
										e.stopPropagation();
									}}
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g fill="none" fillRule="evenodd">
											<path
												fill="none"
												d="M0 0h16v16H0z"
											/>
											<path
												d="M13.368 6.177L12.036 15.5h-8.19L2.494 6.045m3.362-4.474C5.857.98 6.405.5 7.082.5h1.836c.677 0 1.225.48 1.225 1.071M.5 2.107h15V4.25H.5zm5.821 4.286v6.964M9.68 6.393v6.964"
												stroke="#F85359"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</div>
				)}
			</CellMeasurer>
		);
	};

	render() {
		const { notifications } = this.props;

		return (
			<AutoSizer>
				{({ width, height }) => (
					<List
						ref={this.listRef}
						width={width}
						height={height}
						rowCount={notifications.length}
						rowHeight={this.cache.rowHeight}
						deferredMeasurementCache={this.cache}
						rowRenderer={this.renderRow}
					/>
				)}
			</AutoSizer>
		);
	}
}

export default NotificationsListVirtualized;
