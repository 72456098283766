// React Imports
import React from 'react';
// Components
import Button from 'components/button/button.jsx';

class InformationSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      text: this.props.text,
      confirmText: this.props.confirmText || 'OK',
      icon: this.props.icon,
    };
  }

  renderRegisterLinkButton = () => {

    if (this.props.register) {
      return (
        <Button to={'/registo'} email={this.props.email} className='link-container' type='linkNav'
          value='Ainda não tem conta? Registe-se!'/>
      );
    }
  }

  renderCTAContainer = () => {
    return (
      <Button className="cta-button-primary d-medium w-50"  type="button"
        value={this.state.confirmText} onClick={this.props.onClick} />
    );
  }

  renderIcon = () => {
    if (!this.state.icon) {
      return <React.Fragment />;
    }

    return (
      <div className="modal-icon-container d-flex align-center justify-content-center mb-4">
        <img src={this.state.icon} alt="modal-icon" />
      </div>
    );
  };

  render() {
    const { title, text, subText, confirmText } = this.state;
    const formattedText = text?.replace(/\n/g, '<br/>'); 
    return (
      <div className="w-100">
        {this.renderIcon()}
        <h2 className="m-0 p-0">{title}</h2>
        <p className="light mt-4" dangerouslySetInnerHTML={{ __html: formattedText || '' }}></p>
        <p className="light">{subText}</p>
        {this.renderCTAContainer(confirmText)}
      </div>
    );
  }
}

export default InformationSample;
