import React from 'react';
import Labels from '../../variables/labels';
import { withRouter } from 'react-router-dom'; // Import the withRouter HOC


const LinkRecoveryPassword = (props) => {

    const handleClickPassRecovery = () => {
        props.history.push({
            pathname: '/recuperar-password',
            state: { email: props.email || '' }
        });
      }
    return(
        <div className='link-container text-start mt-2'>
            <span onClick={() => handleClickPassRecovery()}>
            {Labels.login.passwordRecovery}
            </span>
        </div>
    );
}
export default withRouter(LinkRecoveryPassword);
// By using withRouter, the component will have access to the history object via the props.
// This approach is suitable for React Router v4