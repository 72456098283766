/**
 * @param {Object} styles - The styles object for customizing the React Select components.
 * @param {Object} state - The state object for React Select components.
 */
export const SelectComponentStyling = {
  singleValue: (styles) => ({
    ...styles,
  }),
  container: (provided) => ({
    ...provided,
    boxShadow: "none", // Remove the box shadow from the container
  }),
  control: (styles, state) => ({
    ...styles,
    border: "none",
    borderRadius: 8,
    boxShadow: "1px 0 8px 0 rgba(165, 173, 189, 0.4)",
    color: "#22272a",
    fontSize: 14,
    fontFamily: "SourceSansPro-Regular",
    cursor: "pointer",
    minHeight: "32px",
    maxHeight: "32px",
    order: 0,
    paddingBottom: "8px",
    "@media (min-width: 768px)": {
      ...styles["media (max-width: 768px)"],
      fontSize: 22,
    },
    backgroundColor: state.isDisabled ? "white" : "white",
    ":hover": {
      ...styles[":hover"],
      borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
    },
    ":focus": {
      ...styles[":focus"],
      borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
    },
    ":active": {
      ...styles[":active"],
      borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    maxHeight: "32px",
    padding: "2px 24px 2px 8px",
    fontFamily: "SourceSansPro-Regular",
    fontSize: 14,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: "SourceSansPro-Light",
    fontSize: "14px",
    color: "#22272a",
    textAlign: "left",
    width: "100%",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#31383f",
    color: "#22272a",
    borderRadius: "4px",
    fontSize: "14px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#22272a",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    color: "#22272a",
    fontSize: "16px",
    fontFamily: "SourceSansPro-Regular",
    textAlign: "left",
  }),
  indicatorsContainer: (styles) => ({
    ...styles
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    width: "16px",
    height: "16px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    width: "32px",
    height: "32px",
  }),
  menu: (styles) => ({
    ...styles,
    fontSize: 14,
    fontFamily: "SourceSansPro-Light",
    backgroundColor: "white",
    padding: "6px 8px",
    border: "none",
    borderRadius: "8px",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    boxShadow: "0 2px 15px #a5adbd",
    marginTop: "-1px",
    color: "#22272a",
    "@media (min-width: 768px)": {
      ...styles["media (max-width: 768px)"],
      fontSize: 16,
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px", // Set a maximum height
    overflowY: "auto", // Enable vertical scrolling
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: 14,
    fontFamily: "SourceSansPro-Regular",
    cursor: "pointer",
    height: "38px",
    borderBottom: "1px solid rgba(165, 173, 189, 0.3)",
    ":hover": {
      ...styles[":hover"],
      backgroundColor: "#f5f6fa",
    },
    ":active": {
      ...styles[":active"],
      backgroundColor: "#f5f6fa",
    },
    ":focus": {
      ...styles[":focus"],
      backgroundColor: "#f5f6fa",
    },
    backgroundColor: state.isSelected || state.isFocus ? "#f5f6fa" : "white",
    color: "#22272a",
  }),
};
