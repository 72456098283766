

const CookiesLabelsDetails = {
  cookiesMessage: 'Ao utilizar os serviços da Keymaster, concorda com a nossa Utilização de Cookies. Utilizamos cookies para fins que incluem personalização e análise de utilização.',
  learnMoreLink: 'Saiba Mais',
  learnMoreTitle: 'Preferências de Cookies',
  learnMoreMessage : {
    policy: {
      title: 'Política de Cookies',
      subTitle: `<b>Última atualização: 21 de Outubro de 2024</b>`,
      content:  `Esta Política de Cookies explica o que são Cookies e como os utilizamos. Deve ler esta política para compreender que tipo de cookies utilizamos, a informação que recolhemos através dos Cookies e como essa informação é utilizada.

      Os Cookies não contêm normalmente qualquer informação que identifique pessoalmente um utilizador, mas as informações pessoais que armazenamos sobre si podem estar associadas às informações armazenadas e obtidas a partir dos Cookies. Para mais informações sobre como utilizamos, armazenamos e mantemos os seus dados pessoais seguros, consulte a nossa Política de Privacidade.

      Não armazenamos informações pessoais sensíveis, como endereços postais, palavras-passe de contas, etc., nos Cookies que utilizamos.\n\n`
    },
    definitions: {
      title: 'Interpretação e Definições',
      subTitleA: 'Interpretação',
      contentA:   `As palavras cuja letra inicial está em maiúscula têm os significados definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.`,
      subTitleB: 'Definições',
      contentB:
      `Para os fins desta Política de Cookies:

      &bull; <b>Empresa</b> (referida como "a Empresa", "Nós" ou "Nosso" nesta Política de Cookies) refere-se a Keymaster®, Av. D. Pedro I, nº 275 R/C, 2750-786 Cascais, Portugal.

      &bull; <b>Cookies</b> são pequenos ficheiros que são colocados no seu computador, dispositivo móvel ou qualquer outro dispositivo por um website, contendo detalhes do seu histórico de navegação nesse website, entre outras utilizações.

      &bull; <b>Website</b> refere-se a keymaster®, acessível a partir de https://*.keymaster.pt</a>

      &bull; <b>Você</b> refere-se ao indivíduo que acede ou utiliza o Website, ou uma empresa, ou qualquer entidade legal em nome da qual tal indivíduo esteja a aceder ou a utilizar o Website, conforme aplicável.\n\n`
    },
    usage: {
      title: 'Utilização dos Cookies',
      subTitleA: 'Tipos de Cookies que Utilizamos',
      contentA: `Os Cookies podem ser "Persistentes" ou "De Sessão". Os Cookies Persistentes permanecem no seu computador pessoal ou dispositivo móvel quando fica offline, enquanto os Cookies de Sessão são apagados assim que fecha o seu navegador de internet.

      Utilizamos tanto Cookies de sessão como Cookies persistentes para os fins abaixo indicados:

      &bull; <b>Cookies de Funcionamento</b>

      &nbsp; Tipo: Cookies de Sessão e Persistentes

      &nbsp; Administrados por: Nós

      &nbsp;Propósito: Estes Cookies são essenciais para lhe fornecer os serviços disponíveis através do Website e para permitir que utilize algumas das suas funcionalidades. Ajudam a autenticar utilizadores e a evitar o uso fraudulento de contas de utilizador. Sem estes Cookies, os serviços que solicitou não podem ser fornecidos, e utilizamos estes Cookies apenas para lhe fornecer esses serviços.

      &bull; <b>Cookies Analíticos</b>

      &nbsp; Type: Cookies Persistentes

      &nbsp; Administrado por: Terceiros

      &nbsp; Propósito: Estes Cookies são utilizados para rastrear informações sobre o tráfego no Website e como os utilizadores o utilizam. As informações recolhidas através destes Cookies podem identificá-lo direta ou indiretamente como um visitante individual. Isto deve-se ao facto de a informação recolhida estar normalmente ligada a um identificador pseudónimo associado ao dispositivo que utiliza para aceder ao Website. Podemos também utilizar estes Cookies para testar novas páginas ou novas funcionalidades do Website, a fim de ver como os nossos utilizadores reagem.`,

//        &bull; <b>Cookies Policy / Notice Acceptance Cookies</b>

//        &nbsp; Type: Persistent Cookies

//        &nbsp; Administered by: Us

//        &nbsp; Purpose: These Cookies identify if users have accepted the use of cookies on the Website.

//        &bull; <b>Functionality Cookies</b>

//        &nbsp; Type: Persistent Cookies

//        &nbsp; Administered by: Us

//        &nbsp; Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
//

      subTitleB: 'As Suas Escolhas em Relação aos Cookies',
      contentB: `Se preferir evitar a utilização de Cookies no Website, deve primeiro desativar a utilização de Cookies no seu navegador e depois eliminar os Cookies guardados no seu navegador associados a este website. Pode utilizar esta opção para evitar a utilização de Cookies a qualquer momento.

      Se não aceitar os nossos Cookies, poderá sentir alguns inconvenientes na utilização do Website e algumas funcionalidades poderão não funcionar corretamente.
      
      Se desejar eliminar Cookies ou instruir o seu navegador para eliminar ou recusar Cookies, por favor visite as páginas de ajuda do seu navegador:
      
      &bull; Para o navegador Chrome, visite esta página da Google: <a href="https://support.google.com/accounts/answer/32050?hl=pt">https://support.google.com/accounts/answer/32050?hl=pt</a>

      &bull; Para o navegador Internet Explorer, visite esta página da Microsoft: <a href="http://support.microsoft.com/kb/278835"> http://support.microsoft.com/kb/278835 </a>

      &bull; Para o navegador Safari, visite esta página da Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"> https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac </a> 

      &bull; Para o navegador Firefox, visite esta página da Mozilla: <a href="https://support.mozilla.org/pt-PT/kb/limpar-cookies-e-dados-de-site-no-firefox"> https://support.mozilla.org/pt-PT/kb/limpar-cookies-e-dados-de-site-no-firefox</a>
      
      Para qualquer outro navegador, por favor visite as páginas oficiais do seu navegador.`,
      //subTitleC: 'More Information about Cookies',
      //contentC: 'You can learn more about cookies: What Are Cookies?.',
      subTitleD: 'Contacte-nos',
      contentD: `Se tiver alguma questão sobre esta Política de Cookies, pode contactar-nos:

      &bull; Por email: <a href="malito:suporte@keymaster.pt"> suporte@keymaster.pt </a>`
    },
  }
}

export const CookiesLabels = {
  cookiesMessage: CookiesLabelsDetails.cookiesMessage,
  learnMoreLink: CookiesLabelsDetails.learnMoreLink,
  learnMoreTitle: CookiesLabelsDetails.learnMoreTitle,
  page: CookiesLabelsDetails.learnMoreMessage,
};