// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
// Components Imports
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { recoverUserPassword } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';

class RecuperarPassword extends React.Component {

  constructor(props) {
    super(props);
    this.refModal = React.createRef();
    this.state = {
      email: props.location?.state?.email || '',
    };
//    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
    * Extrai o valor do campo do email
    *
    * @param {event} event - callback do evento OnChange()
  */
  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }
  /**
   *  Função OnClick do formulário para recuperação de password, que irá fazer 
   *  o pedido a API para a que seja enviado um email para recuperação da password, em caso de dados incorrectos 
   *  o utilizar será notificado com messagem atraves de um modal.
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */
  handleSubmit = (event) => {

    event.preventDefault();

    const USER_EMAIL = {
      email: this.state.email,
    }

    recoverUserPassword(USER_EMAIL).then(res => {
      if (res) {
        if (res.http_code) {
          if (res.http_code === 401) {
            return;
          } else if (res.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', res.message);
          } else if (res.http_code === 409) {
            this.renderInfoModal('Conta Inválida', res.message);
          } else if (res.http_code === 202) {
            this.props.history.push({ pathname: `/send-email-password`, state: { email: this.state.email } });
          } else if (res.http_code === 404) {
            if (res.code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, res.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          this.props.history.push({ pathname: `/send-email-password`, state: { email: this.state.email } });
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  handleClickLogin = () => {
    this.props.history.push({
      pathname: '/login',
      state: { email: this.state.email }
    });
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <div className='grey-box-container mt-5'>
        <h3 className='text-center w-100 pt-4'> {Labels.passwordRecovery.header}</h3>
        <div className="message-container mt-4"> {Labels.passwordRecovery.message}</div>
        <div className='box-form-container mt-4'>
          <form onSubmit={this.handleSubmit}>
            <InputField type='email' title='E-mail'
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              styleInput="input-medium-default"
              placeholder='exemplo@mail.com' value={this.state.email}
              onChange={this.handleChangeEmail} required={true} />
            <Button className='cta-button-primary d-medium w-100 mt-4' type='submit' value={Labels.passwordRecovery.submitButton} />
            <div className='link-container mt-4' onClick={() => this.handleClickLogin()}>{Labels.passwordRecovery.linkMessage}</div>
          </form>
        </div>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default withRouter(RecuperarPassword)