import axios from "axios";
import appConfig from "../../../../core/appConfig";
import { fetchHeadersWithAuth, fetchHeaders } from "../../../../api/routes/api.route";
import Cookies from 'universal-cookie';
import LocalData from "../../../../core/localData";
import { format, parseISO, isToday, isThisYear } from 'date-fns';
import { pt } from 'date-fns/locale';
import Helper from "../../../../core/helper/helper";

export default class ScheduleService {
  static cookies = new Cookies();
  static fetchHeadersCalLite(token) {
    if (process.env.NODE_ENV === 'development') {
      return {
        'x-domain': 'checkpoint',
        //'x-domain': 'comdepcomexp',
        'x-password': 'mfBYezEmywACY0eaIzSC',
        Accept: 'application/json', 'Content-Type': 'application/json',
      };
    } else {
      return {
        Accept: 'application/json', 'Content-Type': 'application/json',
      };
    }
  }
  static fetchHeadersWithToken(token) {
    if (process.env.NODE_ENV === 'development') {
      return {
        'x-domain': 'checkpoint',
        //'x-domain': 'comdepcomexp',
        'x-password': 'mfBYezEmywACY0eaIzSC',
        Accept: 'application/json', 'Content-Type': 'application/json',
        Clienttoken: `Bearer ${token}`,
      };
    } else {
      return {
        Accept: 'application/json', 'Content-Type': 'application/json',
        Clienttoken: `Bearer ${token}`,
      };
    }
  }
  static mockServices = () => {
    return [
      {
        id: '1',
        name: 'Travões',
        active: false,
      },
      {
        id: '2',
        name: 'Revisão Periódica',
        active: false,
      },
      {
        id: '3',
        name: 'Motor',
        active: false,
      },
      {
        id: '4',
        name: 'Pneus',
        active: false,
      },
      {
        id: '5',
        name: 'Óleos e lubrificantes',
        active: false,
      },
      {
        id: '6',
        name: 'Anticongelante',
        active: false,
      },
      {
        id: '7',
        name: 'inspeção (IPO)',
        active: false,
      },
      {
        id: '8',
        name: 'Ar Condicionado (AC)',
        active: false,
      },
    ];
  };

  static fetchUserDetails = async () => {
    if (LocalData.terms_accepted) {
      try {
        const response = (
          await axios.get(`${appConfig.BASE_URL}/user/client/info`, {
            headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")),
          })
        )?.data;

        const details = response?.data;
        /** @type {UserDetails} */
        const userDetails = {
          foId: details?.fo_id,
          id: details?.id,
          email: details?.email,
          termsAccepted: details?.terms_accepted,
          name: details?.name,
          userId: details?.user_id,
          email_validated: details?.email_validated,
        };
        LocalData.userDetails = userDetails;
      } catch (error) {
        console.error("Error fetchUserDetails:", error);
      }
    }
  };

  static fetchPendingAppointmentsCount = async () => {
    if (LocalData.terms_accepted) {
      try {
        const response = await axios.get(`${appConfig.BASE_URL}/appointments/count`, {
          headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")),
        });
        const total = response?.data.data.total;
        return total;
      } catch (error) {
        console.error("Error fetching pending appointments count:", error);
        return 0;
      }
    }
  };


  static fetchAppointmentFiles = async (id, workDraftID, isDraftApp = false) => {
    try {
      const url = isDraftApp 
      ? `${appConfig.BASE_URL}/appointments/${id}/${workDraftID}/draft/media`
      : `${appConfig.BASE_URL}/appointments/${id}/${workDraftID}/media`;
    
      const headers= Helper.isLiteVersion() ? fetchHeaders() : fetchHeadersWithAuth(this.cookies.get("sessionToken"));
      const response = await axios.get(url, {
        headers: headers,
      });
      if (response?.data) {
        return { data: response.data.data };
      }
      return {
        success: false,
        title: "Erro",
        body:
          "Lamentamos mas não foi possível consultar as fotos/ficheiros do seu agendamento. Tente mais tarde. Obrigado.",
      };
    } catch (res) {
      const error = res?.response?.data?.error;
      const code = res?.response?.status;
      return { 
        success: false, 
        code, 
        body: "Lamentamos mas não foi possível consultar as fotos/ficheiros do seu agendamento. Tente mais tarde. Obrigado.",
        ...error };
    }
  };

    /**
   * Informação guardada sobre o agendamento escolhido
   * @param {ScheduleData} scheduleData 
   */
    static postCreateDraftSchedule = async (scheduleData) => {

      const body = {
        //client_id: LocalData.userDetails?.id,
        //client_fo_id: LocalData.userDetails?.foId,
        //client_name: LocalData.userDetails?.name,
        item_fo_id: scheduleData?.itemDetails?.itemFoId,
        title: scheduleData?.itemDetails?.title,
        subtitle: scheduleData?.itemDetails?.subtitle,
      };
      try {
        const headers= Helper.isLiteVersion() ? fetchHeaders() : fetchHeadersWithAuth(this.cookies.get("sessionToken"));
        const response = await axios.post(`${appConfig.BASE_URL}/appointments/draft`, body, {
          headers: headers,
        //const response = await axios.post(`${appConfig.BASE_CAL_URL}/customer/appointment/draft`, body, {
        //  headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
        });
        if (response?.data?.data) {
          return response.data.data;
        }
        return {
          success: false,
          title: "Erro",
          body:
            "Lamentamos mas não foi possível criar o seu agendamento. Tente mais tarde. Obrigado.",
        };
      } catch (res) {
        const error = res?.response?.data?.error;
        const code = res?.response?.status;
        return {success: false, code, ...error };
      }
    };

  static fetchItemDetails = async (id) => {
    try {
      const response = await axios.get(`${appConfig.BASE_URL}/items/${id}`, {
        headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")),
      });

      const details = response?.data
      /**@type {ScheduleData['itemDetails']} */
      return {
        itemId: details?.id,
        itemFoId: details?.fo_id,
        photo: details?.photo,
        subtitle: details?.subtitle,
        title: details?.title,
        users: details?.users
      };
    } catch (error) {
      console.error("Error fetching item details:", error);
      return {};
    }
  };

  // Function to convert appointment from servicedetails format to calendarshare format
  static convertCardDataToCalendarShare(originalObj) {
    const mappedObj = {
      id: parseInt(originalObj.id),
      item_id: originalObj.data.item?.id,
      item_title: originalObj.data.item?.title,
      item_subtitle: originalObj.data.item?.subtitle,
      location: originalObj.establishment.name,
      symptoms: originalObj.data.symptoms ? originalObj.data.symptoms.map(symptom => ({
        id: symptom.id,
        name: symptom.name,
        obs_label: symptom.obs_label,
        obs_value: symptom.obs_value
      })) : [],
      obs: originalObj.data.clientObs || '',
      token: originalObj.data.token,
      //domain: "checkpoint.qa",
      shareCalendar_start_time: originalObj.data.shareCalendar_start_time,
      shareCalendar_end_time: originalObj.data.shareCalendar_end_time
    };
    return mappedObj;
  }

  // Function to convert the date from ISO to: showcase format (hoje 09:30 or 19 jan. 09:30)
  static formatShowcaseDate = (dateString) => {
    const date = parseISO(dateString);
    const formattedTime = format(date, "HH:mm");
    if (isToday(date)) {
        return `hoje ${formattedTime}`;
    } else {
        return `${format(date, "dd MMM.", { locale: pt })} ${formattedTime}`.toLowerCase();
    }
}

  // Function to convert the date from ISO to: "dd MMM." in pt
  static convertDateFromISOPendingAppointment = (dateString) => {
    const date = parseISO(dateString);
  
    const addNonBreakingSpace = (text) => {
      const firstSpaceIndex = text.indexOf(' ');
      if (firstSpaceIndex !== -1) {
        return text.slice(0, firstSpaceIndex) + '\u00A0' + text.slice(firstSpaceIndex + 1);
      }
      return text; // Return original text if there's no space
    }

    if (isToday(date)) {
      return "hoje";
    } else if (!isThisYear(date)) {
       // Format the date as 'dd MMM. yyyy' (Portuguese format)
      return addNonBreakingSpace(format(date, "dd MMM. yyyy", { locale: pt }).toLowerCase());
    } else {
       // Format the date as 'dd MMM.' (Portuguese format)
      return addNonBreakingSpace(format(date, "dd MMM.", { locale: pt }).toLowerCase());
    }
  }

  // Function to convert the time from ISO to: "HH:mm"
  static getTimeFromISO = (dateString) => {
    const date = parseISO(dateString);
    // Format the time as 'HH:mm'
    return format(date, "HH:mm");
  }

  // Function to convert the date from ISO to: "dd MMM yyyy" in pt
  static convertDateFromISOHistoryAppointment = (dateString) => {
    const date = parseISO(dateString);
    if (isToday(date)) {
      return "hoje";
    } else {
      // Format the date as '18 jan. 2024' (Portuguese format)
      return format(date, "dd MMM. yyyy", { locale: pt }).toLowerCase();
    }
  }
  // Function to convert the date from ISO to: "yyyyMMdd'T'HHmmss"
  static convertDateFromISOCalendarShare = (dateString) => {
    const date = parseISO(dateString);
    // Format the date and time as 'YYYYMMDDTHHMMSS'
    return format(date, "yyyyMMdd'T'HHmmss");
  }

  // Function to fetch pending and history appointments to Sidebar
  static fetchScheduleAppointments = async () => {
    if (LocalData.terms_accepted) {
      try {
        const response = await axios.get(`${appConfig.BASE_URL}/appointments`, {
          headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")),
        });
    
        if (response?.data?.type === "Success" && response?.data?.data) {
          const { pending, history } = response.data.data;
    
          // Process pending appointments
          const pendingAppointments = Array.isArray(pending) ? pending.map(item => ({
            ...item,
            date: item.start_date ? this.convertDateFromISOPendingAppointment(item.start_date) : null,
            old_date: item.old_start_date ? this.convertDateFromISOPendingAppointment(item.old_start_date) : null,
            time: item.start_date ? this.getTimeFromISO(item.start_date) : null,
            old_time: item.old_start_date ? this.getTimeFromISO(item.old_start_date) : null,
            shareCalendar_start_time: item.start_date ? this.convertDateFromISOCalendarShare(item.start_date) : null,
            shareCalendar_end_time: item.end_date ? this.convertDateFromISOCalendarShare(item.end_date) : null,
            active: false
          })) : [];
    
          // Process history appointments
          const historyAppointments = Array.isArray(history) ? history.map(item => ({
            ...item,
            date: item.start_date ? this.convertDateFromISOHistoryAppointment(item.start_date) : null,
            time: item.start_date ? this.getTimeFromISO(item.start_date) : null,
            active: false
          })) : [];
    
          return { pending: pendingAppointments, history: historyAppointments };
    
        } else {
          console.error("Invalid data structure in API response");
          return { pending: [], history: [] };
        }
        
      } catch (error) {
        console.error("Error fetching appointments:", error);
        return { pending: [], history: [] };
      }
    }
  };

  static fetchPendingAppointmentsCurrentCompany = async () => {
    try {
      const response = await axios.get(`${appConfig.BASE_CAL_URL}/customer/appointment`, {
        headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
      });
      if (response?.data?.data) {
        const { pending, history } = response.data.data;
    
        // Check if pending and history are arrays
        if (Array.isArray(pending) && Array.isArray(history)) {
          const pendingAppointments = pending.map(item => ({...item, active: false}));
          const historyAppointments = history.map(item => ({...item, active: false}));

          return { pending: pendingAppointments, history: historyAppointments };

        } else {
          console.error("Invalid array structure in API response");
          return [];
        }
      } else {
        console.error("Invalid data structure in API response");
        return [];
      }
      
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return [];
    }
  };

  static fetchScheduleServices = async () => {
    try {
      const headers= Helper.isLiteVersion()? this.fetchHeadersCalLite() : this.fetchHeadersWithToken(this.cookies.get("sessionToken"));
      const response = await axios.get(`${appConfig.BASE_CAL_URL}/customer/symptoms`, {
        headers: headers,
      });
  
      /** @type {ScheduleService[]} */
      const data = response?.data?.data;
      return data?.map(d => ({...d, active: false}));
    } catch (error) {
      console.error("Error fetching services:", error);
      return [];
    }
  };

  /**
   * 
   * @param {string[]} symptoms 
   * @returns 
   */
  static fetchSchedulerConfig = async (symptoms, timePreference, start, end, expertId) => {
    let queryString = symptoms?.map(s => `symptoms=${encodeURIComponent(s)}`).join("&");
    queryString = queryString + `&start=${start}&end=${end}`;
    queryString = queryString + `&preference=${timePreference? timePreference : 3}`;
    queryString = queryString + (expertId ? `&user=${expertId}` : '');
    const headers= Helper.isLiteVersion()? this.fetchHeadersCalLite() : this.fetchHeadersWithToken(this.cookies.get("sessionToken"));
    try {
      const response = await axios.get(`${appConfig.BASE_CAL_URL}/customer/config?${queryString}`, {
        headers: headers,
      });
  
      const data = response?.data?.data;

      /** @type {SchedulerConfig} */
      const mappedData = {
        blockers: data?.blockers,
        location: data?.location,
    };    
      return mappedData;
    } catch (error) {
      console.error("Error fetchSchedulerConfig:", error);
      return [];
    }
  };

  /**
   * Lista de Slots de tempo disponíveis para agendamento
   * @param {string[]} symptoms 
   * @param {string} date 
   * @param {number} timePreference 
   * @returns {string[]}
   */
  static fetchSchedulerSlots = async (symptoms, date, timePreference, expertId) => {
    let queryString = symptoms?.map(s => `symptoms=${s}`).join("&");
    queryString = queryString + `&date=${encodeURIComponent(date)}`;
    queryString = queryString + `&preference=${timePreference? timePreference : 3}`;
    queryString = queryString + (expertId ? `&user=${expertId}` : '');
    const headers= Helper.isLiteVersion()? this.fetchHeadersCalLite() : this.fetchHeadersWithToken(this.cookies.get("sessionToken"));
    try {
      const response = await axios.get(`${appConfig.BASE_CAL_URL}/customer/slots?${queryString}`, {
        headers: headers,
      });
      
      const responseSlots = response?.data; // Assuming this is the structure of your response
      const slots = {
          morning: responseSlots?.data?.morning?.map((time) => ({ value: time, active: false })),
          afternoon: responseSlots?.data?.afternoon?.map((time) => ({ value: time, active: false }))
      };
      
      return slots;
    } catch (error) {
      console.error("Error fetchSchedulerSlots:", error);
      return [];
    }
  };

  /**
   * Editar observações de um agendamento
   * @param {string} id 
   * @param {string} observations 
   */
  static updateAppointmentObs = async (id, observations) => {
    if (id.startsWith("S-")) {
      id = id.substring(2);
    }
    const body = { obs: observations };

    try {
      const response = await axios.put(`${appConfig.BASE_CAL_URL}/customer/appointment/${id}/obs`, body, {
        headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
      });

      if (response?.data?.data) {
        return { success: true };
      }
      return {
        success: false,
        title: "Atenção",
        body:
          "Lamentamos mas não foi editar as observações. Tente novamente ou contacte-nos por via alternativa. Obrigado.",
      };
    }catch (res) {
      const error = res?.response?.data?.error;
      const code = res?.response?.status;
      const message = "Lamentamos mas não foi editar as observações. Tente novamente ou contacte-nos por via alternativa. Obrigado.";
      return { success: false, code, message, ...error };
    }
  }

  /**
   * Informação guardada sobre o agendamento escolhido
   * @param {ScheduleData} scheduleData 
   */
  static createSchedule = async (scheduleData) => {
    let body = {
      scheduler_id: scheduleData?.schedulerId,
      //client_id: LocalData.userDetails?.id,
      //client_fo_id: LocalData.userDetails?.foId,
      //client_name: LocalData.userDetails?.name,
      symptoms: scheduleData?.selectedServices?.map(s => {
        return {
          id: s?.id,
          obs_label: s?.obs_label || undefined,
          obs_value: s?.obs_value || undefined
        };
      }),
      date: scheduleData?.selectedDate?.toLocaleDateString('pt-PT'),
      time: scheduleData?.selectedTime?.value,
      observations: scheduleData?.serviceObservations,
      user: scheduleData?.expertSelected?.id,
      specs: scheduleData?.itemDetails?.specs ? { ...scheduleData.itemDetails.specs } : undefined
    };
    const itemFoId = scheduleData?.itemDetails?.itemFoId;
    const isInternalId = itemFoId?.startsWith('int');
    if (!isInternalId) {
      if (itemFoId) {
        body = {
          ...body,
          item_fo_id: itemFoId,
        };
      } else {
        const { title, subtitle } = scheduleData?.itemDetails || {};
        body = {
          ...body,
          title,
          subtitle,
        };
      }
    }
    //remove `specs` if it is undefined
    if (!body.specs) {
        delete body.specs;
    }   

    if (scheduleData.client) {
      body = {
        ...body,
        client: scheduleData.client,
      };
    }
    try {
      const response = await axios.post(`${appConfig.BASE_URL}/appointments/update`, body, {
        headers: fetchHeadersWithAuth(this.cookies.get("sessionToken")),
      //const response = await axios.post(`${appConfig.BASE_CAL_URL}/customer/appointment/update`, body, {
      //  headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
      });

      if (response?.data?.data) {
        return {success: true};
      }
      return {
        success: false,
        title: "Erro",
        body:
          "Lamentamos mas não foi possível concluir o seu agendamento. Tente novamente ou contacte-nos por e-mail. Obrigado.",
      };
    } catch (res) {
      const error = res?.response?.data?.error;
      const code = res?.response?.status;
      return {success: false, 
              code,
              body: "Lamentamos mas não foi possível concluir o seu agendamento. Tente novamente ou contacte-nos por e-mail. Obrigado.",
               ...error };
    }
  };

    /**
   * Confirmar agendamento.
   * @param {string[]} id
   */
    static confirmAppointment = async (id) => {
      try {      
        const response = await axios.post(`${appConfig.BASE_CAL_URL}/customer/appointment/${id}/confirm`, {},{
          headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
        });
  
        if (response?.data?.data) {
          return {success: true};
        }
        return {
          success: false,
          title: "Atenção",
          body:
            "Lamentamos mas não foi possível confirmar o seu agendamento. Tente novamente. Obrigado.",
        };
      } catch (res) {
        const error = res?.response?.data?.error;
        const code = res?.response?.status;
        return {success: false, 
                code, 
                title: "Atenção",
                body: "Lamentamos mas não foi possível confirmar o seu agendamento. Tente novamente. Obrigado.",
                ...error 
              };
      }
    };

    /**
   * Cancelar agendamento.
   * @param {string[]} id
   */
    static deleteAppointment = async (id) => {
      try {
        const response = await axios.delete(`${appConfig.BASE_CAL_URL}/customer/appointment/${id}`, {
          headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
        });
  
        if (response?.data?.data) {
          return {success: true};
        }
  
        return {
          success: false,
          title: "Atenção",
          body:
            "Lamentamos mas não foi possível cancelar o seu agendamento. Tente novamente ou contacte-nos por e-mail. Obrigado",
        };
      } catch (res) {
        const error = res?.response?.data?.error;
        const code = res?.response?.status;
        return {success: false, code, ...error };
      }
    };

      /**
   * Procurar especialistas por slot
   * @param {ScheduleData} scheduleData 
   */
  static fetchExpertsBySlot = async (scheduleData) => {

    const body = {
      symptoms: scheduleData?.selectedServices?.map(s => s?.id), // Directly map to IDs
      date: scheduleData?.selectedDate?.toLocaleDateString('pt-PT'),
      time: scheduleData?.selectedTime?.value,
      timePreference: 3
    };

    try {
      const response = await axios.post(`${appConfig.BASE_CAL_URL}/customer/user/available`, body, {
        headers: this.fetchHeadersWithToken(this.cookies.get("sessionToken")),
      });
      if (response?.data?.data) {
        return response.data.data;
      }

      return {
        success: false,
        title: "Erro",
        body:
          "Lamentamos mas não foi possível procurar especialistas no slot escolhido. Tente novamente ou contacte-nos por e-mail. Obrigado",
      };
    } catch (res) {
      const error = res?.response?.data?.error;
      const code = res?.response?.status;
      return {success: false, code, ...error };
    }
  };

}
