 import React, { useState } from 'react';
import { HelperSchedule } from '../core/helpers/helperSchedule';
import { useEffect } from 'react';
import Helper from '../../../core/helper/helper';
 /**
   * @param {Object} props
   * @param {boolean} props.stepActive 
   * @param {ScheduleData} props.scheduleData 
   * @param {Funtion} props.onStepChanged
   * @param {Funtion} props.onSave
   * @returns 
   */
  const ScheduleCTA = (props) => {
    const ctaLabel = {
      continue: "Continuar",
      confirm: "Confirmar",
      saveContinue: "Guardar e Continuar",
      back: "Voltar atrás",
      registerConfirm: "Registar para Confirmar",
    };

    const [stepActive, setStepActive] = useState(props.stepActive);
    const [scheduleData, setScheduleData] = useState(props.scheduleData);
    const [ctaStyle, setCtaStyle] = useState(`${props.stepActive === 1 ? 'w-100' : 'w-50 ms-2'}`);
    const servicesAvailable = props.servicesAvailable;

    useEffect(() => {
      setStepActive(props.stepActive);
      setCtaStyle(`${props.stepActive === 1 ? 'w-100' : 'w-50 ms-2'}`);
    }, [props.stepActive]);

    useEffect(() => {
      setScheduleData(props.scheduleData);
    }, [props.scheduleData]);
   
    const handleClickBack = () => {
      const step = stepActive - 1;
      setStepActive(step);
      props.onStepChanged(step);
    }

    const handleClickContinue = () => {
      if (stepActive === 3) {
        props.onSave();
        return;
      }
      const step = stepActive + 1;
      setStepActive(step);
      props.onStepChanged(step);
    }

    const renderBack = () => {
      if (stepActive === 1) {
        return <React.Fragment />;
      }

      return (
        <div className={`cta-button-secondary align-center px-3 w-50 me-2`}
          onClick={handleClickBack} role="button">
          {ctaLabel.back}
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="cta-buttons-container mt-5 pb-4 d-inline-flex justify-content-center">
          {renderBack()}
          <div
            className={`cta-button-primary align-center px-3 ${ctaStyle} ${
              HelperSchedule.isContinueDisabled(servicesAvailable, scheduleData, stepActive) ? "disabled" : ""
            }`}
            role="button"
            onClick={handleClickContinue}
          >
            {stepActive === 1 ? ctaLabel.saveContinue : stepActive === 2 ? ctaLabel.continue : Helper.isLiteVersion() ? ctaLabel.registerConfirm : ctaLabel.confirm}
          </div>
        </div>
      </React.Fragment>
    );
  };

  export default ScheduleCTA;