// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
// Components Imports
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { requestSuport } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import Helper from '../../core/helper/helper';
import SelectList from '../../components/SelectComponent/selectList/select-list';
import LocalData from '../../core/localData';
import IAgreeBox from '../../components/iagree/iagreeBox';

class SuporteCliente extends React.Component {

  constructor(props){
    super(props);
    this.cookies = new Cookies();
//    ReactGA.pageview(window.location.pathname + window.location.search);
    this.refModal = React.createRef();
    this.refInput = React.createRef();
    this.state = {
      supportType: null,
    }
  }

  /**
    * Submissão da mesnagem de apoio ao cliente
  */
  handleSuportSubmit = (event) =>  {
    event.preventDefault();
    if (!this.state.supportType) {
      this.mandatoryTypeModal();
      return;
    }
    const MESSAGE = { message: this.refInput.current.value, type: this.state.supportType }
    requestSuport(this.cookies.get('sessionToken'), MESSAGE).then(value => {
      if(value) {
        if (value.code) {
          if (value.code === 400) {
            this.incorrectDataModal(value);
          } else {
            this.genericErrorModal();  
          }
        } else {
          this.successModal(value);
        }
      } else {
        this.genericErrorModal();   
      }
    });
  }

  handleSupportTypeChange = (e) => {
    this.setState({ supportType: e?.value });
  }

  renderSupportChoice = () => {
    const options = [
      {
        value: 'KEYMASTER',
        label: Labels.customerSupport.appSupportLabel(Helper.appName())
      },
      {
        value: 'COMPANY',
        label:Labels.customerSupport.companySupportLabel(Helper.companyName())
      },
    ];
    return (
      <React.Fragment>
        <p className="input-label mb-1">{ Labels.customerSupport.chooseSupportType }</p>
        <SelectList
          isClearable={false}
          placeholder={ Labels.customerSupport.chooseSupportPlaceholder }  
          onChange={(option) => this.handleSupportTypeChange(option)}
          options={options} />
      </React.Fragment>
    );
  }

  mandatoryTypeModal() {
    this.refModal.current.renderView(
      <Information
        title={Labels.requiredField}
        text={Labels.customerSupport.mandatoryTypeMessage}
        onClick={() => this.refModal.current.closeModal()} />);
    this.refModal.current.openModal();
  }

  successModal(value) {
    this.refModal.current.renderView(
      <Information
        title='Mensagem Enviada'
        text={value.message}
        onClick={() => this.props.history.push(`/`)} />);
    this.refModal.current.openModal();
  }

  incorrectDataModal(value) {
    this.refModal.current.renderView(
      <Information
        title='Dados Incorrectos'
        text={value.message}
        onClick={() => this.refModal.current.closeModal()} />);
    this.refModal.current.openModal();
  }

  genericErrorModal() {
    this.refModal.current.renderView(
      <Information
        title='Pedido Sem Sucesso'
        text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
        onClick={() => this.refModal.current.closeModal()} />);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <>
        <IAgreeBox url={window.location} />
        {LocalData.terms_accepted && (
          <div className="main-container px-3 pb-4">
            <form className="w-100" onSubmit={this.handleSuportSubmit}>
              <div className="grey-box-container w-100 mt-5 pb-4">
                <h2 className="text-center w-100 mt-3 mb-3"> {Labels.customerSupport.title} </h2>
                <div className="box-form-container mt-3">
                  {this.renderSupportChoice()}
                  <textarea
                    ref={this.refInput}
                    className="text-area-container-big mt-3"
                    placeholder={Labels.customerSupport.messagePlaceholder}
                    maxLength="2000"
                  />
                </div>
              </div>
              <Button
                className="cta-button-primary d-medium w-100 mt-4"
                style={{ maxWidth: "427px" }}
                type="submit"
                value={Labels.button.sendButton}
              />
            </form>
            <Modal ref={this.refModal} />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(SuporteCliente);