import React from 'react';
import { GENERAL } from '../../../assets/images/images';
import { withRouter } from 'react-router-dom';
import Helper from '../../../core/helper/helper';

const cLabels = {
  backHome: "Voltar à Página de Início",
  title: "Agendamento com êxito",
  message: "Para mais informação veja o separador “Agendamentos” da Página de Início."
}



class ScheduleConfirmation extends React.Component {

  redirectHome = () => {
    if (Helper.isLiteVersion()) {
      window.location.href = `${process.env.PUBLIC_URL}/services?iagree=true`;
    } else {
      this.props.history.push("/")
    }
  }
  
  render() {
    return (
    <div className='grey-box-container mt-5'>
      <h3 className='text-center w-100 mt-3'> {cLabels.title}</h3>
      <div className="message-container mt-4"> {cLabels.message}</div>
      <div className="check-icon-container mt-5 mb-2">
        <img src={GENERAL.checkGreen} alt=''/>
      </div>
      <div className='box-form-container mt-6'>
        <div className='link-container mt-4' onClick={() => this.redirectHome()}> {cLabels.backHome} </div>
      </div>
    </div>
  );
  }
}

export default withRouter(ScheduleConfirmation);
