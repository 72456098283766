import React, { useState } from "react";
import Button from "./button";
import ScheduleService from "../../views/schedule/core/services/schedule.service";
import { postShowcaseCalConfirm } from "../../api/routes/api.route";

const ConfirmAppointment = ({ appointmentId, onConfirmation, onRenderInfoModal, menubar, token_cal }) => {
    const [loading, setLoading] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [fadeOutMessage, setFadeOutMessage] = useState(false); // Track fade-out state

    const handleConfirmAppointment = () => {
        setLoading(true);
        try {
            if (token_cal) { // isLite
                postShowcaseCalConfirm(token_cal).then((response) => {
                    setLoading(false);
                    if (response && !response?.error) {
                        setShowThankYou(true);
                        // Trigger fade-out after showing thank-you message
                        setTimeout(() => {
                            setFadeOutMessage(true); // Start fade-out
                        }, 2000);
                        // Call onConfirmation after fade-out completes
                        setTimeout(() => {
                            setShowThankYou(false);
                            setFadeOutMessage(false);
                            onConfirmation && onConfirmation();
                        }, 2500); // Match this to the CSS transition duration + message display time
                    } else {
                        onRenderInfoModal && onRenderInfoModal(response?.error?.title || 'Erro', response?.error?.body || 'Ocorreu um erro ao confirmar o agendamento. Por favor, tente novamente.');
                    }
                });
            } else {
                ScheduleService.confirmAppointment(appointmentId).then((response) => {
                    setLoading(false);
                    if (response?.success) {
                        setShowThankYou(true);
                        setTimeout(() => {
                            setFadeOutMessage(true);
                        }, 2000);
                        setTimeout(() => {
                            setShowThankYou(false);
                            setFadeOutMessage(false);
                            onConfirmation && onConfirmation();
                        }, 2500); 
                    } else {
                        onRenderInfoModal && onRenderInfoModal(response?.title || 'Erro', response?.body || 'Ocorreu um erro ao confirmar o agendamento. Por favor, tente novamente.');
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            onRenderInfoModal && onRenderInfoModal('Erro', 'Ocorreu um erro ao confirmar o agendamento. Por favor, tente novamente.');
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: menubar ? "250px" : "349px" }}>
                {!showThankYou ? (
                    <Button
                        className={`${menubar ? "cta-button-secondary" : "cta-button-primary"} ${menubar ? "w-100" : "d-medium d-sm-large w-100"} px-4 flex-center`}
                        type="iconAndText"
                        value="Confirmar Agendamento"
                        loading={loading}
                        onClick={handleConfirmAppointment}
                    />
                ) : (
                    <p
                        style={{
                            opacity: fadeOutMessage ? 0 : 1, // Toggle opacity based on fadeOutMessage state
                            transition: "opacity 0.5s ease-in-out",
                            textAlign: "center",
                            fontSize: menubar ? "0.9rem" : "1.0rem",
                            margin: menubar ? "0.74rem 0" : "0.92rem 0",
                        }}
                    >
                        Obrigado pela confirmação!
                    </p>
                )}
            </div>
        </div>
    );
};

export default ConfirmAppointment;
