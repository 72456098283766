import { getMonth, getYear } from "date-fns";
import React from 'react';
import { GENERAL } from "../../../../assets/images/images";
import { DatePickerData } from "../../core/datePickerData";

const years = DatePickerData.datePickerYears('schedule');
const months = DatePickerData.datePickerMonths;
export const DatePickerCustomHeaderMobile = ({
  decreaseMonth,
  prevMonthButtonDisabled,
  date,
  changeYear,
  changeMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  customIncreaseMonthHandler, // Custom handler for increaseMonth
  customDecreaseMonthHandler, // Custom handler for decreaseMonth
  customChangeMonth,
  customChangeYear,
}) => (
  <div
    style={{
      margin: 0,
      display: "flex",
      justifyContent: "space-around",
    }}
  >
    <div
      className={`calendar-arrow-left ${prevMonthButtonDisabled ? 'disabled' : ''}`}
      onClick={() => customDecreaseMonthHandler(decreaseMonth) }
      disabled={prevMonthButtonDisabled}
      role="button"
    >
      <img src={GENERAL.arrowLeft} alt="" />
    </div>
    

    <select
      role="button"
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) => customChangeMonth(changeMonth, months.indexOf(value)) 
      }
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      role="button"
      value={getYear(date)}
      onChange={({ target: { value } }) => customChangeYear(changeYear, value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    {/* Next Month Arrow */}
    <div
      className={`calendar-arrow-right ${nextMonthButtonDisabled ? 'disabled': ''}`}
      onClick={() => customIncreaseMonthHandler(increaseMonth)}
      disabled={nextMonthButtonDisabled}
      role="button"
    >
      <img src={GENERAL.arrowRight} alt="" />
    </div>
  </div>
);

const formatDatePickerHeaderDate = (date) => {
  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
  let formateddate= date.toLocaleString("pt-PT", {
    month: "long",
    year: "numeric",
  });
  formateddate = capitalizeFirstLetter(formateddate);
  return formateddate;//.replace(" de ", " ");
}

export const DatePickerCustomHeader = ({
  decreaseMonth,
  customHeaderCount,
  increaseMonth,
  monthDate,
  // prevMonthButtonDisabled,
  // date,
  // changeYear,
  // changeMonth,
  // nextMonthButtonDisabled,
  customIncreaseMonthHandler, // Custom handler for increaseMonth
  customDecreaseMonthHandler, // Custom handler for decreaseMonth
  customChangeMonth,
  customChangeYear,
}) => (
  <div>
  <button
    aria-label="Previous Month"
    className={
      "react-datepicker__navigation react-datepicker__navigation--previous"
    }
    style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
    onClick={() => customDecreaseMonthHandler(decreaseMonth) }
  >
    {/* <span
      className={
        "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
      }
    >
    </span> */}
      <img src={GENERAL.arrowLeft} alt="" />
  </button>

  
  <span className="react-datepicker__current-month">
    {formatDatePickerHeaderDate(monthDate)}
  </span>
  <button
    aria-label="Next Month"
    className={
      "react-datepicker__navigation react-datepicker__navigation--next"
    }
    style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
    onClick={() => customIncreaseMonthHandler(increaseMonth)}
  >
    {/* <span
      className={
        "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
      }
    >
    </span> */}
      <img src={GENERAL.arrowRight} alt="" />
  </button>
</div>
);


