// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
// Components Imports
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { defineNewPasswordForClient } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import Helper from '../../core/helper/helper';

class DefinirPassword extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.state = {
      password: '',
      checkPassword: '',
    };
//    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
  * Extrai o valor do campo da password
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  /**
  * Extrai o valor do campo da confirmação da password
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangeCheckPassword = (event) => {
    this.setState({ checkPassword: event.target.value });
  }

  /**
    * Verifica se as password são iguais, em caso de sucesso será
    * returnado @true caso contrartio será returnado @false com uma
    * respectiva mensagem através de um modal
    *
    * @param {string} password - valor da password
    * @param {string} password - valor da password de confirmação
    */
  checkPasswordValidation = (password, passwordConfirm) => {
    if (password === passwordConfirm) { return true; }
    this.refModal.current.renderView(
      <Information
        title='Dados Incorrectos'
        text="As passwords inseridas são diferentes"
        onClick={() => this.refModal.current.closeModal()} />
    );
    this.refModal.current.openModal();
    return false;
  }

  /**
   *  Função OnClick do formulário de Registo, que irá fazer o pedido a API 
   *  para inicio do registo do novo cliente, em caso de dados incorrectos 
   *  ou dados já registados o utilizar será notificado com messagem atraves
   *  de um modal.
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */
  handleSubmit = (event) => {

    event.preventDefault();

    var checked = this.checkPasswordValidation(this.state.password, this.state.checkPassword);

    if (checked) {
      if (this.props.match.params.token) {

        const TOKEN = this.props.match.params.token;
        const NEW_PASSWORD = { password: this.state.password }

        defineNewPasswordForClient(NEW_PASSWORD, TOKEN).then(res => {
          if (res) {
            if (res.http_code) {
              if (res.http_code === 401) {
                return;
              } else if (res.http_code === 400) {
                if (res.error_http_code === 3) {
                  this.renderInfoModal(Labels.genericErrorTitle, res.message);
                } else {
                  this.renderInfoModal('Dados Incorrectos', res.message);
                }
              } else if (res.http_code === 404) {
                this.renderInfoModal('Token Inválido', res.message);
              } else if (res.http_code === 202) {
                this.renderModal(
                  <Information
                    title='Password Alterada'
                    text={res.message}
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/pre-signup`,
                        search: this.props.location.search
                      });
                    }} />);
              } else {
                this.renderGenericError();
              }
            } else {
              this.renderModal(<Information
                title='Password Alterada'
                text={res.message}
                onClick={() => this.props.history.push(`/`)} />);
              this.cookies.set('sessionToken', res.data?.token, Helper.cookiesConfig);
            }
          } else {
            this.renderGenericError();
          }
        });
      } else {
        this.renderInfoModal('Token Inválido', 'O token recebido não é válido');
      }
    }
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <div className='grey-box-container mt-5'>
        <h3 className='text-center w-100 mt-3'> Definir nova Password</h3>
        <div className='box-form-container mt-4'>
          <form onSubmit={this.handleSubmit}>
            <InputField
              styleInput="input-medium-default password"
              type='password' title='Password'
              forgotPassword={false} value={this.state.password}
              onChange={this.handleChangePassword}
              pattern={Helper.passwordRegex}
              required={true} />
            <InputField
              styleInput="input-medium-default password"
              type='password' title='Confirmar Password'
              forgotPassword={false}
              value={this.state.checkPassword}
              onChange={this.handleChangeCheckPassword}
              pattern={Helper.passwordRegex}
              required={true} />
            <Button className='cta-button-primary d-medium w-100 mt-4' type='submit' value='Submeter' />
          </form>
        </div>
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default withRouter(DefinirPassword)