import appConfig from "../../../../core/appConfig";
import Helper from "../../../../core/helper/helper";
import LocalData from "../../../../core/localData";
import { parse, format } from 'date-fns';
import { pt } from 'date-fns/locale';

export class HelperSchedule {
  static isSchedulePage = () => window.location.pathname.toString().includes("schedule");
  static isContinueDisabled = (servicesAvailable, data, step) => {
    return (
      (!data?.selectedServices?.length && !(data?.serviceObservations && !servicesAvailable) && step === 1) ||
      (!data?.selectedTime && step === 2) ||
      (step === 3 && data.selectedServices.some(service => service.users && service.users.length > 0) && !data.expertSelected )
    );
  };

  static updatedServicesList = (services, selectedServices) => {
    return services?.map((service) => {
      const foundSelectedService = selectedServices?.find(
        (selectedService) => selectedService.id === service.id
      );
      return {
        ...service, active: !!foundSelectedService,
        obs_value: foundSelectedService ? foundSelectedService.obs_value : service.obs_value
      };
    });
  };

  static disableOtherServicesIfExpertSelected = (services) => {
    // Determine if there is any service that is active that has experts
    const isActiveWithUsers = services.some(service => service.active && service.users.length > 0);
    if (isActiveWithUsers) {
      // If there is an active service with expert, disable all others services
      return services.map(service => ({
        ...service,
        disabled: !(service.active && service.users.length > 0)
      }));
    } else {
      // If no active service has experts, set disabled to false for all services
      return services.map(service => ({
        ...service,
        disabled: false
      }));
    }
  };

  static companyName = () => Helper.isDemo()? "Empresa Demo" : LocalData.configData.company_name;
  static itemLabel = () => this.getConfig().labels.item_label;
  static getConfig = () => LocalData.configData;
  static icons = () => {
    if (
      this.getConfig().icons &&
      this.getConfig().icons !== "" &&
      this.getConfig().icons !== "NULL"
    ) {
      return this.getConfig().icons;
    }
    return "DEFAULT";
  };
  static defaultImage = () => require(`assets/images/verticais/icons/${this.icons()}/equip.svg`);
  static urlItemImage = (url) =>
    url ? `${appConfig.BASE_URL}/photos/item/${url}` : this.defaultImage();

  static convertDate = (dateString) => {
    return parse(dateString, 'dd/MM/yyyy', new Date());
  }

  static getAppointmentStateBarsDescription(appointment) {
    if (appointment.is_cancelled && appointment.cancelled_by_client) { return "Agendamento cancelado pelo cliente"; }
    if (appointment.is_cancelled && !appointment.cancelled_by_client) { return "Agendamento cancelado pelo prestador"; }
    return appointment.is_history === true ? "Agendamento antigo" : "Serviço agendado";
  }

  //function to format date to the create schedule format
  static formatDateScheduler(dateString) {
    // Split the input string and rearrange it to "YYYY-MM-DD" format
    const parts = dateString.split('/');
    const formattedInput = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    // Format the date in "dd MMM yyyy" format using Portuguese locale
    return format(formattedInput, "dd MMM. yyyy", { locale: pt }).toLowerCase();
  }


  static appointmentEnriched = (appointment) => {
    let googleDescription = '';
    let icalPlainDescription = '';
    let servicesListHtml = '';

    if (appointment.symptoms && appointment.symptoms.length > 0) {
      const servicesList = appointment.symptoms
        .map(symptom => `- ${symptom.name}${symptom.obs_value ? ` (${symptom.obs_value} ${symptom.obs_label})` : ''}`)
        .join('\n');
      googleDescription += `<strong>Serviços solicitados:</strong>\n${servicesList}\n\n`;
      icalPlainDescription += `Serviços solicitados:\n${servicesList}\n\n`;
      servicesListHtml += servicesList.split('\n').map(item => `${item}`).join('<br>');
    }

    if (appointment.obs) {
      googleDescription += `<strong>Os seus comentários/observações:</strong>\n${appointment.obs}\n\n`;
      icalPlainDescription += `Os seus comentários/observações:\n${appointment.obs}\n\n`;
    }

    googleDescription += `Nota: alterações futuras a este agendamento não ficam aqui refletidas.\nAcompanhe os detalhes do seu agendamento em: <a href="${this.createShareLink(appointment)}">Agendamento_${appointment.location}</a>`;
    icalPlainDescription += `Nota: alterações futuras a este agendamento não ficam aqui refletidas.\nAcompanhe os detalhes do seu agendamento em: ${this.createShareLink(appointment)}`;

    const icalHTMLDescription = `
      ${servicesListHtml ? `<strong>Serviços solicitados:</strong><br>${servicesListHtml}<br><br>` : ''}
      ${(appointment.obs ? `<strong>Os seus comentários/observações:</strong><br>${appointment.obs.split('\n').join('<br>')}<br><br>` : '')}
      Nota: alterações futuras a este agendamento não ficam aqui refletidas.<br>
      Acompanhe os detalhes do seu agendamento em: 
      <a href="${this.createShareLink(appointment)}">Agendamento_${appointment.location}</a>
    `;

    const appointmentEnrichedData = {
      title: `Agendamento ${appointment.location}${appointment.item_title ? ' - ' + appointment.item_title : ''}`,
      location: appointment.location,
      startTime: appointment.shareCalendar_start_time,
      endTime: appointment.shareCalendar_end_time,
      googleDescription: googleDescription,
      icalPlainDescription: icalPlainDescription,
      icalHTMLDescription: icalHTMLDescription,
    }
    return appointmentEnrichedData;
  }

  static createShareLink = (appointment) => {
    const domain = appointment.domain ? `${appointment.domain}.keymaster.pt` : window.location.host;
    return `https://${domain}${process.env.PUBLIC_URL}/services-lite?token_cal=${appointment.token}&s=C&t=0`;
  };

  static createGoogleCalendarUrl = (appointment) => {
    const appointmentEnriched = this.appointmentEnriched(appointment);
    const { title, startTime, endTime, location, googleDescription } = appointmentEnriched;

    const url = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(title)}&dates=${encodeURIComponent(startTime)}/${encodeURIComponent(endTime)}&details=${encodeURIComponent(googleDescription)}&location=${encodeURIComponent(location)}`;
    window.open(url, '_blank');
  };

  static createICalAndShare = (appointment) => {
    const appointmentEnriched = this.appointmentEnriched(appointment);
    const { title, startTime, endTime, location, icalPlainDescription, icalHTMLDescription } = appointmentEnriched;

    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.createCalendarEvent.postMessage(
          {
            title: title,
            start: startTime,
            end: endTime,
            location: location,
            url: this.createShareLink(appointment),
            description: icalPlainDescription
          });
        return;
      } catch (error) {
        console.error(error);
        return;
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.createCalendarEvent(title, startTime, endTime, location, icalHTMLDescription);
        return;
      } catch (error) {
        console.error(error);
        return;
      }
    }

    const icalData = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${this.createShareLink(appointment)}`,
      `DTSTART:${startTime}`,
      `DTEND:${endTime}`,
      `SUMMARY:${title}`,
      `DESCRIPTION:${icalPlainDescription.replace(/\n/g, '\\n')}`,  //ics file needs \\n instead of \n
      `X-ALT-DESC;FMTTYPE=text/html:${icalHTMLDescription}`,
      `LOCATION:${location}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\r\n');

    const blob = new Blob([icalData], { type: 'text/calendar' });
    // Create a pretty filename
    const date = startTime.split('T')[0]; // Extract date from the startTime
    const time = startTime.split('T')[1].substring(0, 4); // Extract time from the startTime
    const filename = `calendar_${date}${time}.ics`;

    // Create an anchor element and trigger download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link); // Append to the body
    link.click(); // Simulate click to trigger download
    document.body.removeChild(link); // Clean up
  };

  static symptomsWithExperts = (services) => {
    const hasExperts = services.some(service => service.users && service.users.length > 0);
    return hasExperts;
  }
}
