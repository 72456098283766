import React from 'react';
import Helper from '../../../core/helper/helper';

function CheckOutInfo(props) {
  const { data: { checkoutInfo }} = props;

    const renderInfo = () => {
      return (
        <React.Fragment>
          {checkoutInfo}
        </React.Fragment>
      );
    }

  return (
    <>
      <div className='service-equipment-data-row'></div>
      <div className='service-equipment-data-row mx-0'>
        <img src={Helper.sidebarEquipmentIcon()} alt="" />
        <div className={'service-date-text'}>
          {renderInfo()}
        </div>
      </div>
    </>
  );
}

export default CheckOutInfo;