// eslint-disable-next-line no-unused-vars
import * as CompanyAPI from './services/company.service';
import { range } from "lodash"; 
import { getYear } from "date-fns";

export default class LocalData {
  static appVersion = 'v23.10.20';
  static cookiesConfig = { domain: process.env.NODE_ENV === 'development' ? 'localhost' : 'keymaster.pt', path: '/' };
  static configData = {
    company_id: null,
    labels: {
      item_label: 'Item',
    }
  };
  static userRegistered = true;
  static terms_accepted = false;

  // CONTENT
  //static equipmentsData = null;
  static servicesData = {};
  static servicesLoaded = false;
  static liteData = null;
  static notificationsData = {};
  static notificationsLoaded = false;

  /** @type {Company[]} */ 
  static companies = null;
  /** @type {Company[]} */ 
  static newCompanies = [];
  /** @type {CompaniesCount} */ 
  static companiesCount = null;
  /** @type {Category[]} */
  static categories = [];

  static errorCompanies = null;
  static errorNewCompanies = null;

  // USER
  static userData = null;
  /**@type {UserDetails} */
  static userDetails = null;

  static reset = () => {
    //this.equipmentsData = null;
    this.clearAllLiteData();
    this.servicesData = {}
    this.notificationsData = {}
    this.liteData = null;
    this.companies = [];
    this.newCompanies = [];
    this.errorCompanies = null;
    this.errorNewCompanies = null;
  }

  static checkIconClicked = false;
  static messageIconClicked = false;
  static approvalIconClicked = false;
  static internalIconClicked = false;

  static datePickerYears = (isBirth) => {
    const futureYears = isBirth ? 1 : 100;
    return range(1900, getYear(new Date()) + futureYears, 1)
  };
  static datePickerMonths = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  // Persisted LocalData:
  static _equipmentsData = LocalData.loadequipmentsData();
  static _equipmentsToken = LocalData.loadEquipmentsToken();
  static _equipmentsTokenCal = LocalData.loadEquipmentsTokenCal();

  static get equipmentsData() {
    return LocalData._equipmentsData;
  }
  static set equipmentsData(data) {
    LocalData._equipmentsData = data;
    LocalData.saveequipmentsData(data);
  }
  static get equipmentsToken() {
    return LocalData._equipmentsToken;
  }
  static set equipmentsToken(token) {
    LocalData._equipmentsToken = token;
    LocalData.saveEquipmentsToken(token);
  }
  static get equipmentsTokenCal() {
    return LocalData._equipmentsTokenCal;
  }
  static set equipmentsTokenCal(tokenCal) {
    LocalData._equipmentsTokenCal = tokenCal;
    LocalData.saveEquipmentsTokenCal(tokenCal);
  }
  static loadequipmentsData() {
    const data = sessionStorage.getItem('equipmentsData');
    return data ? JSON.parse(data) : [];
  }
  static saveequipmentsData(data) {
    sessionStorage.setItem('equipmentsData', JSON.stringify(data));
  }
  static loadEquipmentsToken() {
    return sessionStorage.getItem('equipmentsToken');
  }
  static saveEquipmentsToken(token) {
    sessionStorage.setItem('equipmentsToken', token);
  }
  static loadEquipmentsTokenCal() {
    return sessionStorage.getItem('equipmentsTokenCal');
  }
  static saveEquipmentsTokenCal(tokenCal) {
    sessionStorage.setItem('equipmentsTokenCal', tokenCal);
  }
  static clearAllLiteData() {
    LocalData._equipmentsData = [];
    LocalData._equipmentsToken = null;
    LocalData._equipmentsTokenCal = null;
    sessionStorage.removeItem('equipmentsData');
    sessionStorage.removeItem('equipmentsToken');
    sessionStorage.removeItem('equipmentsTokenCal');
  }
}